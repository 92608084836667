import { useNavigate } from "react-router-dom";
import "./Signup.css";
import { useState, useEffect } from "react";
import Homeheader from "./components/homeheader";
import axios from "axios";
import GoogleAuth from "./components/GoogleAuth";
import { TextField } from "@mui/material";
import SignupPass from "./components/SignupPass";
import { toast } from "react-toastify";
import Backdrops from "./components/Backdrop";

import styled from "styled-components";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Button from "@mui/material/Button";
import { useAppDispatch } from "./redux/hooks";
import { getUser, setToken } from "./redux/modules/userSlice";
import Checkbox from "@mui/material/Checkbox";
import { Helmet } from "react-helmet";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export function Signup() {
  const nav = useNavigate();
  const dispatch = useAppDispatch();

  const { linkedInLogin } = useLinkedIn({
    clientId: `${process.env.REACT_APP_LINKEDIN_URL}`,
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      setIsOpen(true);
      axios
        .post(`user/linkedin-login`, { code: code })
        .then((response) => {
          setIsOpen(false);
          dispatch(getUser(response.data.message.isVerified));
          localStorage.setItem(
            "linkedIn",
            response.data.message.is_linkdn_followed
          );
          localStorage.setItem("id", response.data.message.result_email.id);
          localStorage.setItem(
            "email",
            response.data.message.result_email.email
          );
          localStorage.setItem("name", response.data.message.result_email.name);
          const d = new Date();
          // d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
          d.setTime(d.getTime() + 1 * 60 * 60 * 1000);
          let expires = "expires=" + d.toUTCString();
          document.cookie = `_secure_ARJ_=${response.data.message.token};${expires};path=/`;
          // document.cookie = "_secure_ARJ_" + "=" + response.data.message.token + ";" + expires + ";path=/";

          if (response.data.message.comment === "Old User") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/upload-page";
          }
        })
        .catch((err) => {
          setIsOpen(false);
          toast.error("Please Try Again!", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        });
    },
    scope: "r_emailaddress r_liteprofile",
    onError: (error) => {
      console.log(error);
    },
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const regex = /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+$/;

  const regexPass = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const [error, setError] = useState([
    {
      field: false,
      error: "",
    },
    {
      field: false,
      error: "",
    },
    {
      field: false,
      error: "",
    },
    {
      field: false,
      error: "",
    },
  ]);
  const [, _er] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [subscriber, setSubscriber] = useState();

  const handlename = (event) => {
    const value = event.target.value;
    const numericRegex = /^[a-zA-Z\s]*$/;
    if (numericRegex.test(value)) {
      if (value.length < 60) {
        setName(value);
      }
    }
  };

  const handleLogin = () => {
    nav("/login");
  };

  const handleUploadpage = () => {
    setIsOpen(true);

    if (!name) {
      setIsOpen(false);
      const stateData = [...error];
      stateData[0].field = true;
      stateData[0].error = "Please Enter Your Name";
      stateData[2].error = "";
      stateData[1].error = "";
      stateData[1].field = false;
      stateData[2].field = false;

      setError(stateData);

      return;
    }
    if (!email) {
      setIsOpen(false);
      const stateData = [...error];
      stateData[0].field = false;
      stateData[0].error = "";
      stateData[2].error = "";
      stateData[2].field = false;
      stateData[1].field = true;
      stateData[1].error = "Please Enter Your Email";
      setError(stateData);
      return;
    }
    if (!regex.test(email)) {
      setIsOpen(false);
      // setError('Please Enter Valid Email Address');
      _er("");
      const stateData = [...error];
      stateData[0].field = false;
      stateData[0].error = "";
      stateData[2].field = false;
      stateData[2].error = "";
      stateData[1].field = true;
      stateData[1].error = "Please enter a valid email";
      setError(stateData);
      return;
    }

    if (email.length > 60) {
      setIsOpen(false);
      // setError('Please Enter Valid Email Address');
      _er("");
      const stateData = [...error];
      stateData[0].field = false;
      stateData[0].error = "";
      stateData[2].field = false;
      stateData[2].error = "";
      stateData[1].field = true;
      stateData[1].error = "Email length cannot exceed 60 characters";
      setError(stateData);
      return;
    }
    if (!password) {
      setIsOpen(false);
      // setError('Please Enter Password');
      const stateData = [...error];
      stateData[0].field = false;
      stateData[0].error = "";
      stateData[1].field = false;
      stateData[1].error = "";
      stateData[2].field = true;
      stateData[2].error = "Please Enter Your Password";
      setError(stateData);
      return;
    }
    if (!regexPass.test(password)) {
      setIsOpen(false);
      // setError('Please Enter Password');
      const stateData = [...error];
      stateData[0].field = false;
      stateData[0].error = "";
      stateData[1].field = false;
      stateData[1].error = "";
      stateData[2].field = true;
      stateData[2].error =
        "Use 8 or more characters with a mix of uppercase and lowercase letters, numbers & symbols. (ex : 22aAA22@)";
      setError(stateData);
      return;
    } else {
      setError([
        {
          field: false,
          error: "",
        },
        {
          field: false,
          error: "",
        },
        {
          field: false,
          error: "",
        },
      ]);
      axios
        .post(
          `user/portal/signup`,
          {
            name: name,
            email: email.toLowerCase(),
            password: password,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          setIsOpen(false);
          localStorage.setItem("name", response.data.message.name);
          localStorage.setItem("email", response.data.message.email);
          localStorage.setItem("id", response.data.message.id);
          localStorage.setItem(
            "linkedIn",
            response.data.message.is_linkdn_followed
          );
          dispatch(setToken(response.data.message.token));
          const d = new Date();
          // d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
          d.setTime(d.getTime() + 1 * 60 * 60 * 1000);
          let expires = "expires=" + d.toUTCString();
          document.cookie = `_secure_ARJ_=${response.data.message.token};${expires};path=/`;
          nav("/upload-page");
        })
        .catch((err) => {
          setIsOpen(false);
          toast.error(err.response.data.error, {
            hideProgressBar: true,
            closeOnClick: true,
          });
        });
    }

    if (subscriber) {
      try {
        axios.post(`subscribe/user`, { email: email }).then((response) => {
          console.log(response);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleUpdateEmail = (event) => {
    const value = event.target.value;
    if (value.length >= 0) {
      setEmail(value);
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      handleUploadpage();
    }
  };

  const handleCheckboxSubscriber = (event) => {
    setSubscriber(event.target.checked);
  };

  return (
    <>
      <Helmet>
        <title>
          Sign Up for Jobeze | Create Your Account to Start Job Search
        </title>
        <meta
          name="title"
          content="Sign Up for Jobeze | Create Your Account to Start Job Search"
        />
        <meta
          name="description"
          content="Join Jobeze today and start your job search in the USA. Create your free account to access thousands of job listings, apply online, and connect with top employers."
        />
      </Helmet>
      <div>
        {isOpen && <Backdrops />}
        <Homeheader />
        <div
          className="d-flex justify-content-center align-items-center fx"
          style={{ height: "85vh" }}
        >
          <div className="m-auto mx-2 mt-5">
            <div
              className="py-3"
              style={{
                boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.16)",
                borderRadius: "8px",
              }}
            >
              <div className="text-center fw-bold fs-4">
                <p>Sign Up</p>
              </div>
              <div className=" w-75 m-auto">
                <div className="mt-2">
                  <TextField
                    fullWidth
                    onKeyDown={onEnterPress}
                    id="name"
                    label="Full Name"
                    variant="outlined"
                    name="name"
                    onChange={handlename}
                    value={name}
                    error={error[0].field ? true : false}
                  />
                  {error[0].error && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {error[0].error}
                    </span>
                  )}
                </div>
                <div className="mt-3">
                  <TextField
                    fullWidth
                    onKeyDown={onEnterPress}
                    id="email"
                    label="Email"
                    variant="outlined"
                    name="email"
                    onChange={handleUpdateEmail}
                    error={error[1].field ? true : false}
                  />
                  {error[1].error && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {error[1].error}
                    </span>
                  )}
                </div>
                <div className="mt-3">
                  <SignupPass
                    password={password}
                    setPassword={setPassword}
                    passval={error[2].field}
                    kd={onEnterPress}
                  />
                  {error[2].error && (
                    <div
                      style={{ color: "red", fontSize: "12px", width: "292px" }}
                    >
                      {error[2].error}
                    </div>
                  )}
                </div>
                <div className="fw-bold py-2 d-flex gap-2 align-items-center ">
                  <div className="">
                    <Checkbox
                      checked={subscriber}
                      onChange={handleCheckboxSubscriber}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ p: 0, m: 0 }}
                    />
                  </div>
                  <div
                    className="text-secondary"
                    style={{ fontSize: "12.5px", wordBreak: "break-word" }}
                  >
                    I agree to recieve job updates & recommendations
                  </div>
                </div>

                <div className="py-2">
                  <Button
                    onClick={handleUploadpage}
                    variant="contained"
                    className=" fw-bold w-100"
                  >
                    Sign Up
                  </Button>
                </div>
                <div className="fw-bold py-2" style={{ fontSize: "12px" }}>
                  <span>
                    I accept Jobeze's{" "}
                    <a className="text-decoration-none" href="/terms-condition">
                      T&Cs
                    </a>
                    ,{" "}
                    <a className="text-decoration-none" href="/privacy-policy">
                      Privacy Policy
                    </a>
                    , and communication settings.
                  </span>
                </div>
                <div className="d-flex align-items-center overflow-hidden my-2">
                  <img
                    src="/Vector3.png"
                    width="45%"
                    alt="error"
                    className=""
                  />
                  <span className="px-2 fw-bold">Or</span>
                  <img
                    src="/Vector3.png"
                    width="50%"
                    alt="error"
                    className=""
                  />
                </div>
                <div className="text-center fw-bold">Sign up with</div>
                <div className="d-flex ">
                  <div className="position-relative border m-auto rounded-1">
                    {/* <button className=" border-0 bg-transparent"> */}
                    <span
                      className="position-absolute fw-bold"
                      style={{ left: "12%", top: "19%" }}
                    >
                      <img
                        src="/g.png"
                        alt="Job search logo element"
                        title="Jobeze G - Part of Your Job Search Journey"
                        width="34%"
                        className="img-fluid px-2"
                      />
                      Google
                    </span>
                    <div>
                      <GoogleAuth />
                    </div>
                    {/* </button> */}
                  </div>
                  <div className="">
                    <Wrapper>
                      <div
                        className="border rounded-1 p-2"
                        onClick={linkedInLogin}
                        style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                      >
                        <LinkedInIcon sx={{ color: "#0077b5" }} />
                        <span className="px-2 fw-bold">LinkedIn</span>
                      </div>
                    </Wrapper>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 align-items-center">
              <div className="px-3 fw-bold">Already have an account?</div>
              <div>
                <Button
                  onClick={handleLogin}
                  variant="contained"
                  className="fw-bold px-4"
                >
                  Log In
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
