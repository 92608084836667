import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import "./Uploadpage.css";
import ResendBackdrop from "../ResendBackdrop";
import { styled } from '@mui/material/styles';
import { isResumeVerified } from "../redux/modules/userSlice";
import { useAppDispatch } from "../redux/hooks";

const linkedInRegex = /https:\/\/(?:www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+/;
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
export const Uploadpage = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [fileData, setFileData] = useState("");
  const [LinkData, setLinkData] = useState(localStorage.getItem("Lid") || "");
  const [filename, setFileName] = useState();

  const token = document.cookie;

  useEffect(() => {

    setFileName(
      fileData?.name
        ? `${fileData.name.split(".")[0].substring(0, 10)}.${fileData.name.split(".")[1]}`
        : ""
    );
    const storedFile = localStorage.getItem("file");
    setFileData(storedFile)

    const storedLinkData = localStorage.getItem("Lid");
    if (storedLinkData) {
      setLinkData(storedLinkData);
    }
  }, []);

  useEffect(() => {
    setFileName(
      fileData?.name
        ? `${fileData.name.split(".")[0].substring(0, 10)}.${fileData.name.split(".")[1]}`
        : ""
    );
  }, [fileData]);

  const handleGetData = (event) => {
    setLinkData(event.target.value);
    localStorage.setItem("Lid", event.target.value);
  };

  // const handleskills = () => {
  //   const formData = new FormData();
  //   formData.append("resume", fileData);
  //   formData.append("candidate_id", localStorage.getItem("id"));
  //   formData.append("linkdein", LinkData);
  //   formData.append("token", token);
  //   localStorage.setItem(
  //     "file",
  //     fileData?.name
  //       ? fileData?.name?.split(".")[0]?.substring(0, 10) +
  //       "." +
  //       fileData?.name?.split(".")[1]
  //       : localStorage.getItem("file")
  //         ? localStorage.getItem("file")
  //         : ""
  //   );

  //   setIsOpen(true);

  //   if (!fileData) {
  //     setIsOpen(false);
  //     toast.error("Please upload resume", { hideProgressBar: true, closeOnClick: true });
  //     return;
  //   }

  //   if (LinkData !== "" && !linkedInRegex.test(LinkData)) {
  //     setIsOpen(false);
  //     toast.error("Please Enter Valid Linkedin URL", { hideProgressBar: true, closeOnClick: true });
  //     return;
  //   }

  //   if (fileData.type && !["text/plain", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(fileData.type)) {
  //     setIsOpen(false);
  //     toast.error("Please upload resume in Pdf, Txt or Docx format only", { hideProgressBar: true, closeOnClick: true });
  //     return;
  //   }

  //   axios.post("user-resume-upload", formData, { headers: { authorization: token } })
  //     .then((response) => {
  //       setIsOpen(false);
  //       dispatch(isResumeVerified(1))
  //       toast.success("Resume uploaded successfully", { hideProgressBar: true, closeContext: true });
  //       nav("/skill-page");
  //     });
  // };
  const handleskills = () => {
    if (!fileData) {
      setIsOpen(false);
      toast.error("Please upload resume", { hideProgressBar: true, closeOnClick: true });
      return;
    }

    if (LinkData !== "" && !linkedInRegex.test(LinkData)) {
      setIsOpen(false);
      toast.error("Please Enter Valid Linkedin URL", { hideProgressBar: true, closeOnClick: true });
      return;
    }

    if (!["text/plain", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(fileData.type)) {
      setIsOpen(false);
      toast.error("Please upload resume in Pdf, Txt or Docx format only", { hideProgressBar: true, closeOnClick: true });
      return;
    }

    const formData = new FormData();
    formData.append("resume", fileData);
    formData.append("candidate_id", localStorage.getItem("id"));
    formData.append("linkdein", LinkData);
    formData.append("token", token);

    localStorage.setItem("file", fileData.name.split(".")[0].substring(0, 10) + "." + fileData.name.split(".")[1]);

    setIsOpen(true);

    axios.post("user-resume-upload", formData, { headers: { authorization: token } })
      .then((response) => {
        setIsOpen(false);
        dispatch(isResumeVerified(1));
        toast.success("Resume uploaded successfully", { hideProgressBar: true, closeOnClick: true });
        nav("/skill-page");
      });
  };

  const handleUploadFile = (file) => {
    // Ensure file is defined
    if (!file) {
      return;
    }

    const file_extension = file?.name?.split(".").pop().toLowerCase();
    const file_size = file?.size ? file.size / (1024 * 1024) : 0; // Size in MB, default to 0 if file is invalid

    // Ensure file extension and size are valid
    if (["pdf", "docx", "txt"].includes(file_extension)) {
      if (file_size > 6) {
        toast.error("File size exceeds 6MB limit.", { hideProgressBar: true, closeOnClick: true });
        setFileData(null);
        setFileName("");
      } else {
        setFileData(file);
        setFileName(file.name);
      }
    } else {
      toast.error("Please upload resume in .pdf, .txt, or .docx format only", { hideProgressBar: true, closeOnClick: true });
      setFileData(null);
      setFileName("");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    if (files && files.length) {
      handleUploadFile(files[0]);
    } else {
      toast.error("No files dropped", { hideProgressBar: true, closeOnClick: true });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    handleUploadFile(file);
  };


  return (
    <>
      <div className="container-fluid border-bottom p-3 text-center ">
        <img
          className="img-fluid"
          src="/Logo.svg"
          alt='Jobeze logo - Job search platform' title='Jobeze - AI-Powered Job Search Platform'
        />
      </div>
      {isOpen && <ResendBackdrop />}
      <div className="upload-parent-div">
        <div className="fx">
          <div className="Uploadpage bg-white ">
            <div className="section-upload rounded-3" style={{ border: "rgba(0, 0, 0, 0.16) 1px solid" }} >
              <p className="heading1-upload fw-bold">Upload Resume<span className="text-danger fw-bold"> *</span></p>
              <p className="heading2-upload fw-bold text-muted ">Upload your latest resume here</p>
              <div className="borderInputMain text-center p-4 "
                onDrop={handleDrop}
                onDragOver={handleDragOver}>
                {/* <label className="labelBox">Choose File</label> */}
                {/* <input
                    type="file"
                    className="input1-upload22"
                    onChange={handleuploadFile}
                    accept=".doc,.docx,.pdf,.txt"
                  /> */}
                <Button component="label" className="fw-bold text-dark " style={{ color: " rgba(0, 0, 0, 0.60))", fontSize: "14px", fontWeight: "400", textTransform: "unset" }} variant="text">
                  Drag and drop file here
                  <VisuallyHiddenInput onChange={handleFileInputChange} type="file" accept=".docx, .pdf, .txt" />
                </Button>
                <div className="labelH text-capitalize pt-2 fw-bold text-muted ">
                  {filename || ""}
                </div>
              </div>
              <p className="fx py-2 text-muted fw-bold" style={{ fontSize: "12px", letterSpacing: "0.12ch" }}>
                Please upload resume in Pdf, Txt or Docx format only
              </p>
              <div className="fx fw-bold pt-3">
                <span>Add your LinkedIn Profile</span>
              </div>
              <TextField
                id="outlined-basic"
                value={LinkData}
                size="small"
                label="LinkedIn Profile URL"
                variant="outlined"
                sx={{ width: "100%", marginTop: "1.5%" }}
                name="LinkData"
                onChange={handleGetData}
              />
              <div className="pt-3">
                <Button
                  variant="contained"
                  fullWidth
                  className="fw-bold"
                  sx={{ letterSpacing: '2px' }}
                  onClick={handleskills}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* html layout for mobile design*/}
      <div className="phone-upload-page">
        <p className="heading1-upload fw-bold">Upload Resume<span className="text-danger fw-bold"> *</span></p>
        <p className="heading2-upload fw-bold text-muted ">Upload your latest resume here</p>
        <div className="borderInputMain py-3">
          <div className="borderInput ps-3 " style={{ cursor: "pointer" }}>
            <Button component="label" className="fw-bold text-dark " style={{ background: "lightgray", textTransform: "unset" }} variant="text">
              Choose file
              <VisuallyHiddenInput onChange={handleFileInputChange} type="file" accept=".docx, .pdf, .txt" />
            </Button>
            <div className="labelH text-capitalize fs-6 fw-bold text-muted">
              {filename}
            </div>
          </div>
        </div>
        <p className="fx px-3 py-2 text-muted fw-bold" style={{ fontSize: "12px", letterSpacing: "0.12ch" }}>
          Please upload resume in Pdf, Txt or Docx format only
        </p>
        <div className="fx px-3 fw-bold py-3">
          <span>Add your LinkedIn Profile</span>
        </div>
        <TextField
          id="outlined-basic"
          value={LinkData}
          label="LinkedIn Profile Url"
          variant="outlined"
          sx={{ width: "92%", marginLeft: '16px', marginRight: '16px' }}
          name="LinkData"
          onChange={handleGetData}
        />
        <div className="px-3 my-5 text-end ">
          <Button
            variant="contained"

            className="fw-bold"
            onClick={handleskills}
          >
            Next
          </Button>
        </div>

        {/* html layout for mobile design*/}
      </div>
      {/* Include FooterZ component here if needed */}
    </>
  );
};
