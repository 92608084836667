import React from "react";
import axios from "axios";
import { Box, Typography, Slide, Button, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const LinkedinPopup = ({ onClose, open }) => {
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  const handleLinkedinFollow = async () => {
    try {
      const response = await axios.patch("/follow-linkdn", null, {
        headers: { authorization: document.cookie },
      });
      handleClose();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-paper": {
          position: "absolute",
          top: { xs: "10%", md: "7%" }, 
          right: { xs: "5%", md: "0" }, 
          maxWidth: "100%", 
        },
      }}
    >
      <Box sx={{ pt: 2.5, pb: 3, px: { xs: 2, sm: 3.75 }, width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
            flexDirection: "row", 
          }}
        >
          <img
            src="../linkdin_light.svg"
            alt="Connect with Jobeze on LinkedIn"
            title="Jobeze LinkedIn - Professional Job Listings"
            style={{ maxWidth: "100%", height: "auto" }} 
          />
          <CloseIcon onClick={handleClose} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "24px" }, 
              color: "#1F1F1F",
              fontWeight: "700",
            }}
          >
            Unlock Career Opportunities
          </Typography>
          <Typography
            sx={{
              mt: 1.25,
              fontSize: { xs: "10px", sm: "12px" }, 
              fontWeight: "600",
              textTransform: "capitalize",
              color: "rgba(0, 0, 0, 0.45)",
            }}
          >
            Stay ahead with job alerts and expert advice with jobeze!
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "8px",
              flexDirection: { xs: "column", sm: "row" }, 
              mt: 1.75,
            }}
          >
            <Button
              href="https://www.linkedin.com/company/jobeze/?isFollowingPage=true"
              target="_blank"
              rel="noreferrer"
              variant="contained"
              sx={{
                fontWeight: "700",
                color: "white !important",
                width: { xs: "100%", sm: "auto" },
              }}
              onClick={handleLinkedinFollow}
            >
              Let's Connect
            </Button>
            <Button
              onClick={handleClose}
              variant="text"
              sx={{
                fontWeight: "600",
                color: "rgba(0, 0, 0, 0.38)",
                width: { xs: "100%", sm: "auto" }, 
              }}
            >
              No thanks
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default LinkedinPopup;
