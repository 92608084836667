import React, { useState, useRef, useEffect } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Checkbox } from "@mui/material";
import { Label } from "@mui/icons-material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import axios from "axios";
import { toast } from "react-toastify";
import { selectUser } from "../redux/modules/userSlice";
import { useAppSelector } from "../redux/hooks";
import { useMediaQuery } from '@mui/material';

// const baseURL = process.env.REACT_APP_BASEURL;

function CardOfRecommended(props) {
  const {
    isSaved,
    city,
    date_created,
    description,
    duration,
    joborder_id,
    name,
    openings,
    openings_available,
    total_recruited,
    state,
    title,
    type,
  } = props?.data;
  const isDashboard = props.isDashboard || false;
  const beforeDate = new Date(`${date_created}`);
  const today = new Date();
  const diffTime = Math.abs(today - beforeDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const [save, setsave] = useState(isSaved ? true : false);
  const { isVerified } = useAppSelector(selectUser);

  // useEffect(() => {
  //  setsave(isSaved ? true : false);

  // })

  // var postedMessages = "";
  // if (diffDays === 1) {
  //   postedMessages = "Posted 1 day ago";
  // }
  // else if (diffDays > 1 && diffDays <= 30) {
  //   postedMessages = "Posted " + diffDays + " days ago";
  // }
  // else if (diffDays > 30) {
  //   postedMessages = "Re-posted " + (diffDays % 30) + (diffDays % 30 === 1 ? " day ago" : " days ago");
  // }
  // else {
  //   postedMessages = "Posted " + diffDays + " days ago";
  // }

  var postedMessages = "";
  if (diffDays === 1) {
    postedMessages = "Posted 1 day ago";
  } else if (diffDays === 0) {
    postedMessages = "Posted today";
  } else if (diffDays > 1 && diffDays <= 30) {
    postedMessages = "Posted " + diffDays + " days ago";
  } else if (diffDays > 30) {
    if (diffDays % 30 === 0) {
      postedMessages = "Posted today";
    } else {
      postedMessages =
        "Re-posted " +
        (diffDays % 30) +
        (diffDays % 30 === 1 ? " day ago" : " days ago");
    }
  } else {
    postedMessages = "Posted " + diffDays + " days ago";
  }


  const handlesave = async () => {
    if (!document.cookie.split("_secure_ARJ_")[1]) {
      setsave(false);
      toast.warning("Please login to save", {
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    }

    else if (document.cookie.split("_secure_ARJ_")[1]) {
      setsave(!save);

      if (save === false) {
        const response = await axios.post(`user/save-job`, {
          job_id: joborder_id,
          token: document.cookie,
        });

        toast.success("Job saved Successfully", {
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
      if (save === true) {
        const response = await axios.post(`user/unsave-job`, {
          job_id: joborder_id,
          token: document.cookie,
        });
        toast.warning("Job unsaved successfully", {
          hideProgressBar: true,
          closeOnClick: true,
        });

      }


    }
  };

  const isSmallScreen = useMediaQuery('(max-width: 625px)');

  return (
    <div className="border rounded fx hoverstates" >
      <div className="d-flex justify-content-between px-3 py-3" style={{ cursor: "pointer", overflow: "hidden" }} onClick={() => props.handleClick(props.data)}>
        <div className="d-flex flex-column justify-content-around overflow-hidden" style={{height:"150px"}}  >
          <div className="fw-bold text-capitalize text-truncate " title={title}>
            {/* <img src={props.data.logo} width={"30px"} height={"30px"} style={{ borderRadius: "50%" }} alt="" /> */}
            {title}
          </div>
          <div className="fw-bold text-muted" style={{ fontSize: "14px" }}>
            {name}
          </div>
          <div className="text-secondary fw-bold" style={{ fontSize: "13px" }}>
            {city}, {state}
          </div>
          <div className="d-flex py-1 text-start fw-bold" style={{ fontSize: "12px" }}>
            <AccountCircleIcon sx={{ fontSize: "18px" }} />
            {total_recruited > 1 ? (
              <span className="text-secondary px-2">
                {" "}
                {total_recruited} Candidates Applied Recently{" "}
              </span>
            ) : (
              <span className="text-secondary px-2">
                {" "}
                {total_recruited} Candidate Applied Recently{" "}
              </span>
            )}
          </div>
          <div className="text-secondary fw-bold" style={{ fontSize: "12px" }}>
            {postedMessages}
          </div>
        </div>
        {/* <div className="col-1 p-0 m-0">
          <Checkbox
            sx={{ p: 0, m: 0 }}
            // {...Label}
            checked={save}
            // defaultChecked={save}
            icon={<BookmarkBorderIcon />}
            checkedIcon={<BookmarkIcon />}
            onClick={handlesave}
          />
        </div> */}
      </div>
    </div>
  );
}

export default CardOfRecommended;
