import React from "react";
import "./Sectionpage.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Avatar,
  Box,
  Chip,
  Collapse,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FooterZ from "./FooterZ";
import Button from "@mui/material/Button";
import Homeheader from "./homeheader";
import Shimmercard from "./Shimmercard";
import CardOfRecommended from "./cardOfRecommended.js";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import AppliedCardUI from "./appliedCard.js";
import Recommendedrawer from "./Recommendeddrawer.js";
import ResendBackdrop from "../ResendBackdrop.js";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { getProfile } from "../redux/modules/userSlice.js";
import { useAppDispatch } from "../redux/hooks.js";
import { Helmet } from "react-helmet";
import { displayName } from "react-quill";

export function Dashboard() {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [searchBasedData, setSearchBasedData] = useState([]);
  const [jobOrder_id, setJobOrderId] = useState();
  const [percentage, setPercentage] = useState(0);
  const [totalPage, setTotalPage] = useState();
  // const [page, setPage] = useState(1);
  // const [checkData, setCheckData] = useState([]);
  const [name, setName] = useState("");
  // const [isOpen, setIsOpen] = useState(true);
  // const [errorData, setErrorData] = useState(false);
  const [src, setSrc] = useState("");
  // const [loading, setloading] = useState(true); //1 
  // const [loadingApplied, setLoadingApplied] = useState(true); //2
  const [skills, setSkills] = useState([]);
  const [subscriber, setSubscriber] = useState(0);
  //const [updateLoader, setUpdateLoader] = useState(false); //3
  const [updateResponse, setUpdateResponse] = useState(null);
  const [updateSkilledDrawer, setUpdateSkilledDrawer] = useState(false);
  //const [isLoading, setIsLoading] = useState(true); //4
  const [date_modified, setDate_modified] = useState("");

  const [Loader, setLoader] = useState({
    loading: true,
    loadingApplied: true,
    updateLoader: false,
    isLoading: true,
  });

  const loaders = (key, value)=>{
    setLoader((prev)=>({...prev, [key]: value}))
  }

  const [cookies, setCookie] = useCookies([]);

  const handleBackP = () => {
    nav("/profile-section");
  };

  const shrimmerLoading = async () => {
    // let page = 1
    try {
      // setloading(true);
      window.scrollTo(0, 0);
      const response = await axios.get(
        `recommend-jobs/${localStorage.getItem("id")}/page-no/1`,
        // `recommend-jobs/14211/page-no/1`,
        // { token: document.cookie }
        {
          headers: {
            authorization: document.cookie,
            "If-None-Match": ' W/"0-0 ',
            Pragma: "no-cache",
            "Cache-Control": "no-cache",
          },
        }
      );
      // page =  page + 1;
      setData(response?.data?.message.data.slice(0, 9));
      setJobOrderId(response?.data?.message?.data.joborder_id);
      // setIsOpen(false);
      setTotalPage(response?.data?.message?.total);
      setTimeout(() => {
        //setloading(false);
        loaders("loading",false);
      }, 1000);
    } catch (error) {
      //setloading(false);
      loaders("loading",false);
      // setIsOpen(false);
      // setErrorData(true);
    }
  };

  const [shouldFetchData, setShouldFetchData] = useState(false);
  const waitForToken = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 3000);
    });
  };

  useEffect(() => {
    if (cookies._secure_ARJ_ || cookies._secure_ref) {
      const checkRefreshToken = async () => {
        const tokenReady = await waitForToken();
        if (tokenReady) {
          setShouldFetchData(true);
        }
      };
      checkRefreshToken();
    }
  }, [cookies]);

  useEffect(() => {
    if (shouldFetchData) {
      const fetchData = async () => {
        await ProfileData();
        AppliedCard();
      };
      fetchData();
    }
  }, [shouldFetchData]);

  useEffect(() => {
    fetchlastsearchJobs();
  }, []);

  const fetchlastsearchJobs = async () => {
    //setIsLoading(true);
    loaders("isLoading", true);
    try {
      const response = await axios.get(`/last-search-pattern-jobs`, {
        headers: { authorization: document.cookie },
      });

      // Retrieve the job IDs from local storage
      const existingJobIds = JSON.parse(localStorage.getItem("jobIds")) || [];

      // Filter out jobs based on the job IDs in local storage
      const filteredJobs = response.data.message.data.jobs.filter(
        (job) => !existingJobIds.includes(job.joborder_id)
      );

      // Update the state with filtered jobs
      setSearchBasedData((prev) => ({
        ...prev,
        jobs: filteredJobs,
        lastSearchedKeyword : response.data.message.data.lastSearchedKeyword,
        lastSearchedLocation : response.data.message.data.lastSearchedLocation
      }));

      //setIsLoading(false);
      loaders("isLoading", false);
    } catch (err) {
      console.error("Error fetching data:", err);
      //setIsLoading(false);
      loaders("isLoading", true);
    }
  };


  useEffect(() => {
    let attempt = 1; // Set the initial attempt value
    if (!updateSkilledDrawer) {
      //setloading(true);
      loaders("loading",true);
    }
    const UpdateAIrcmdJob = async () => {
      try {
        const response = await axios.get("/recommendation-update-status", {
          headers: { authorization: document.cookie },
        });
        const responseData = await response.data.message;
        if (updateSkilledDrawer) {
          //setUpdateLoader(true);
          loaders("updateLoader", true);
        }
        setUpdateResponse(responseData);
        if (responseData[0]?.update_status !== 0) {
          setTimeout(async () => {
            try {
              if (updateSkilledDrawer) {
                //setUpdateLoader(true);
                loaders("updateLoader", true);
              }
              window.scrollTo(0, 0);
              const response = await axios.get(
                `recommend-jobs/${localStorage.getItem("id")}/page-no/1`,
                {
                  headers: {
                    authorization: document.cookie,
                    "If-None-Match": ' W/"0-0 ',
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }
              );
              setData(response?.data?.message.data.slice(0, 9));
              setJobOrderId(response?.data?.message?.data.joborder_id);
              setTotalPage(response?.data?.message?.total);
              setUpdateSkilledDrawer(false);
              //setloading(false);
              loaders("loading",false);
            } catch (error) {
              //setUpdateLoader(false);
              loaders("updateLoader", false);
              //setloading(false);
              loaders("loading",false);
              // setIsOpen(false);
              // setErrorData(true);
            }
          }, 2000);
          setTimeout(() => {
            //setUpdateLoader(false);
            loaders("updateLoader", false);
          }, 4000);
          clearInterval(intervalId);
        } else {
          //setUpdateLoader(true);
          loaders("updateLoader", true);
        }
        // setTimeout(() => {
        //   setUpdateLoader(false);
        // }, 4000);
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch data initially
    //----this line

    // }
    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(UpdateAIrcmdJob, 1000);

    // Clean up the interval
    return () => clearInterval(intervalId);
  }, [updateSkilledDrawer]);

  const handleClick = (item) => {
    // if (isSmallScreen) {
    // user/applied/all-job/page-no/1?shortlisted=false
    nav(`/jobDetail/${item.joborder_id}`);
    // }
    // else {
    // nav("/recommendedJobs", { state: { job_id: jobOrder_id } });
    // }
  };

  // const handleSearch = () => {
  //   setSearchClicked(true);
  //   // if (checkData.length === 0) return;

  //   const paramss = new URLSearchParams();
  //   if (skill) paramss.append('search', skill);
  //   paramss.append('location', location ? location : 'World Wide');
  //   // paramss.append('jobRole', checkData);
  //   // nav(`/jobsearch/allsearchdata${paramss ? '?' + paramss : ''}`, { state: { pageData: getPageNumber, category: checkData, isDashboard: true } })
  // }

  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  // const isMediumScreen = useMediaQuery('(max-width: 1200px)');

  const [openProfileAlert, setOpenProfileAlert] = useState(true);
  const [AppliedData, setAppliedData] = useState([]);
  // const [rowsPerPage, setRowsPerPage] = useState(page);
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const AppliedCard = () => {
    //setLoadingApplied(true);
    loaders("loadingApplied", true)
    axios
      .get(`user/applied/all-job/page-no/1?shortlisted=false`, {
        // token: document.cookie,
        headers: { authorization: document.cookie },
      })
      .then((response) => {
        // Set the applied data
        setAppliedData(...AppliedData, response.data.message.data.slice(0, 3));
        setTimeout(() => {
          //setLoadingApplied(false);
          loaders("loadingApplied", false);
        }, 1500);
        // setIsOpen(false);
      })
      .catch((err) => {
        // Close the modal
        // setIsOpen(false);
        //setLoadingApplied(false);
        loaders("loadingApplied", false)
      });
  };
  const [onclickdrawer, setonclickdrawer] = useState(false);

  const handledrawer = () => {
    setonclickdrawer(true);
  };
  const handleCloseDrawer = async () => {
    setonclickdrawer(false);
    await ProfileData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    // setIsOpen(true);
    // setloading(true);
    // await shrimmerLoading();
    // setIsOpen(false);
    // setloading(false);
  };

  const ProfileData = async () => {
    try {
      const profileResponse = await axios.get(`user/dashboard/details`, {
        headers: { authorization: document.cookie },
      });
      // console.log(document.cookie,"token");
      setSkills(profileResponse?.data?.message?.details?.skill);
      setPercentage(profileResponse?.data?.message?.complete_profile);
      setName(profileResponse?.data?.message?.details?.name);
      setDate_modified(profileResponse.data.message.details.date_modified);
      setSubscriber(profileResponse?.data?.message?.details?.isSubscribe);
      localStorage.setItem(
        "name",
        profileResponse?.data?.message?.details?.name
      );
      localStorage.setItem(
        "email",
        profileResponse?.data?.message?.details?.email
      );
      setSrc(profileResponse?.data?.message?.details?.profile_path);
      dispatch(
        getProfile(profileResponse?.data?.message?.details?.profile_path)
      );
    } catch (error) {
      // setErrorData(true);
      // setIsOpen(false);
      // setloading(false);
    }
  };

  const handleSubscribe = async () => {
    if (subscriber === 0) {
      try {
        const response = await axios.post(`subscribe/user`, {
          email: localStorage.getItem("email"),
        });

        toast.success("Subscribed Successfully");
        setSubscriber(1);
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  };

  // const  handlenotInteresetedJob = (jobId) => {
  //   // Retrieve the current job IDs from local storage
  //   const existingJobIds = JSON.parse(localStorage.getItem("jobIds")) || [];
  //   const data = apiData.jobs.filter(item=>(
  //     item.joborder_id !== jobId
  //   ))
  //   setApiData(data);
  //   // Check if the jobId is already in the array
  //   if (!existingJobIds.includes(jobId)) {
  //     // Add the new jobId
  //     const updatedJobIds = [...existingJobIds, jobId];

  //     // Limit to the last 4 job IDs
  //     const limitedJobIds = updatedJobIds;

  //     // Store the updated array in local storage
  //     localStorage.setItem("jobIds", JSON.stringify(limitedJobIds));
  //   }
  // };
  const handleNotInterestedJob = (jobId) => {
    // Retrieve the current job IDs from local storage
    const existingJobIds = JSON.parse(localStorage.getItem("jobIds")) || [];

    // Filter out the clicked job
    const filteredJobs = searchBasedData.jobs.filter(
      (job) => job.joborder_id !== jobId
    );

    // Update the state with the filtered jobs
    setSearchBasedData((prev) => ({ ...prev, jobs: filteredJobs }));

    // Add the jobId to local storage if not already present
    if (!existingJobIds.includes(jobId)) {
      const updatedJobIds = [...existingJobIds, jobId];

      // Store updated array in local storage
      localStorage.setItem("jobIds", JSON.stringify(updatedJobIds));
    }
  };

  const shimmerStyles = {
    background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
    backgroundSize: "200% 100%",
    animation: "shimmer 1.5s infinite linear",
    borderRadius: "8px",
  };

  return (
    <>
      <Helmet>
        <title>Jobeze Dashboard – Manage Your Job Applications</title>
        <meta
          name="title"
          content="Jobeze Dashboard – Manage Your Job Applications"
        />
        <meta
          name="description"
          content="Access your Jobeze dashboard to manage job applications, track your progress, and utilize our AI job assistant for efficient job search automation."
        />
      </Helmet>
      {/* {isOpen && <ResendBackdrop />} */}
      <Homeheader src={src} />
      <Collapse in={openProfileAlert} sx={{ width: "100%" }}>
        {percentage === "100" ? null : (
          <Alert
            severity="info"
            sx={{ px: { xs: 1, md: 10 } }}
            onClose={() => {
              setOpenProfileAlert(false);
            }}
          >
            Completing your profile gives you higher chances of getting noticed
            by recruiters.
          </Alert>
        )}
      </Collapse>
      {/* <div className="fx" style={{}}> */}
      {/* <Container maxWidth="xxl" className="p-0"> */}
      <Box
        sx={{
          flexGrow: 1,
          background: "#FAFAFA",
          padding: isSmallScreen ? "" : "40px 48px 40px 48px",
        }}
      >
        {isSmallScreen && (
          <Box
            className="rounded-2 bg-white mb-3 h-100 d-flex flex-column justify-content-evenly align-items-center py-3 px-2"
            sx={{ gap: isSmallScreen ? 1.5 : 1.3 }}
          >
            <Typography
              variant="h1"
              sx={{ fontSize: "24px" }}
              textAlign={"center"}
              className="fw-bold"
              color="primary"
            >
              My Profile
            </Typography>
            {src ? (
              <img
                style={{ width: "175px", height: "175px" }}
                alt="Profile"
                src={src}
                loading="lazy"
                referrerPolicy="no-referrer"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                className="rounded-circle"
              />
            ) : (
              <Avatar
                variant="circle"
                sx={{
                  width: "175px",
                  height: "175px",
                  fontSize: "45px",
                  fontWeight: "500",
                  textTransform: "uppercase",
                }}
              >
                {name.split("")[0]}
              </Avatar>
            )}
            <Box className="w-100 px-3">
              <Typography
                textAlign={"center"}
                variant="h5"
                className="fw-bold text-truncate overflow-hidden text-capitalize w-100"
                gutterBottom
              >
                {localStorage.getItem("name")}
              </Typography>
              <Typography
                textAlign={"center"}
                variant="body2"
                color="text.secondary"
                className="text-truncate overflow-hidden fw-bold w-100"
                title={localStorage.getItem("email")}
              >
                {localStorage.getItem("email")}
              </Typography>
            </Box>
            <Box textAlign={"center"} sx={{ width: "50%" }}>
              <LinearProgress variant="buffer" value={Number(percentage)} />
              <Typography
                variant="body2"
                color={"text.secondary"}
                className="fw-bold py-2"
              >
                {percentage}% Completed
              </Typography>
            </Box>
            <Button
              onClick={handleBackP}
              sx={{ textDecoration: "none", color: "#006CB7" }}
              variant="text"
              className=" fw-bold text-capitalize"
            >
              Edit Profile
            </Button>
          </Box>
        )}
        <Grid
          container
          sx={{
            paddingLeft: "0px !important",
            paddingTop: "0px !important",
            gap: "24px",
            flexWrap: { xs: "wrap", md: "nowrap" },
          }}
        >
          {/* Left Section: 70% */}
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              paddingLeft: "0px !important",
              paddingTop: "0px !important",
              gap: "24px",
            }}
          >
            {/* <Box className="rounded-3 px-3 py-3 bg-white"> */}
            <Box
              sx={{
                gap: "24px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {Loader.updateLoader ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height: "100%",
                    marginBottom: { xs: "45px", md: "20px" },
                  }}
                >
                  <Typography
                    className="position-relative"
                    sx={{ height: "150px" }}
                  >
                    <Typography
                      className="sampleLoading"
                      alt="Loading spinner for job search results"
                      title="Loading Job Search Results on Jobeze"
                    ></Typography>
                    <Typography
                      textAlign="center"
                      gutterBottom
                      className="fw-bold fx"
                      variant="h5"
                    >
                      Please wait while we update your jobs . . .
                    </Typography>
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    padding: {
                      xs: "12px 12px 16px 12px",
                      md: "24px 24px 32px 24px",
                    },
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography
                    variant="body1"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className="fw-bold"
                    gutterBottom
                  >
                    <Typography variant="h5" className="fw-bold">
                      Recommended Jobs
                    </Typography>
                    {data.length > 0 ? (
                      <Button
                        variant="text"
                        className="fw-bold"
                        onClick={() =>
                          nav("/preffered-jobs?job=recommendedJobs")
                        }
                        sx={{
                          textDecoration: "none",
                          textTransform: "unset",
                        }}
                      >
                        See all
                      </Button>
                    ) : null}
                  </Typography>
                  <Box>
                    {Loader.loading ? (
                      <Box className="row row-cols-xl-3">
                        {[...Array(9)].map((_, index) => (
                          <Box key={index} className="py-2">
                            <Shimmercard />
                          </Box>
                        ))}
                      </Box>
                    ) : data.length > 0 ? (
                      <Box className="row row-cols-xl-3">
                        {data.map((item, index) => (
                          <Box key={index} className="py-2">
                            <CardOfRecommended
                              data={item}
                              selected={{}}
                              handleClick={handleClick}
                            />
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ height: "45vh" }}
                      >
                        <Box textAlign="center">
                          <Typography gutterBottom textAlign="center">
                            <img
                              className="img-fluid"
                              width={90}
                              src="./recommendedbriefcase.svg"
                              alt="Recommended job opportunities on Jobeze"
                              title="Recommended Jobs for You"
                            />
                          </Typography>
                          <Typography
                            variant="h5"
                            className="fw-bold"
                            gutterBottom
                          >
                            No recommended jobs
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body1"
                            color="text.secondary"
                          >
                            Add more skills to your profile to get job
                            recommendations
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              {/* Recent Application */}
              <Box
                sx={{
                  padding: {
                    xs: "12px 12px 16px 12px",
                    md: "24px 24px 32px 24px",
                    backgroundColor: "#fff",
                    borderRadius:'8px',
                  },
                }}
              >
                <Typography
                  variant="body1"
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  className="fw-bold"
                  gutterBottom
                >
                  <Typography variant="h5" className="fw-bold">
                    Recently Applied Jobs{" "}
                  </Typography>
                  {AppliedData.length > 0 ? (
                    <Button
                      variant="text"
                      className="fw-bold"
                      onClick={() => nav("/applied-jobs")}
                      sx={{
                        textDecoration: "none",
                        textTransform: "unset",
                      }}
                    >
                      See all
                    </Button>
                  ) : (
                    <></>
                  )}
                </Typography>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={2}
                  flexWrap={"wrap"}
                  py={1.5}
                >
                  {Loader.loadingApplied ? (
                    // Show shimmer effect while loading data
                    <Box className="col-12">
                      <Box className="row row-cols-xl-3 mb-1">
                        {[...Array(3)].map((_, index) => (
                          <Box className="" key={index}>
                            <Shimmercard />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ) : AppliedData.length > 0 ? (
                    // Display AppliedCardUI if there is data available
                    <Box className="col-12">
                      <Box
                        className="row row-cols-xl-3"
                        style={{ transition: "opacity 1s" }}
                      >
                        {AppliedData.map((value, index) => (
                          <Box
                            className="mb-3"
                            key={index}
                            style={{
                              opacity: value.is_deleted === 1 ? 0.6 : 1,
                            }}
                          >
                            <AppliedCardUI
                              title={
                                <>
                                  {value.title}
                                  {value.is_deleted === 1 && (
                                    <Chip
                                      label="This Job is no longer available"
                                      color="error"
                                      style={{
                                        marginLeft: "20px",
                                        borderRadius: "4px",
                                      }}
                                    />
                                  )}
                                </>
                              }
                              name={value.name}
                              image={value.logo}
                              jobid={value.joborder_id}
                              date={value.applied_date}
                              ApplyStatus={value.isEmployerApplied}
                              is_deleted={value.is_deleted}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box textAlign={"center"}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          textAlign={"center"}
                        >
                          <img
                            className="img-fluid"
                            width={90}
                            src="./applicationEmoji.svg"
                            alt="Simple job application process with Jobeze"
                            title="Easy Job Application Process"
                          />
                        </Typography>
                        <Typography
                          variant="h5"
                          className="fw-bold"
                          gutterBottom
                        >
                          No applications
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="body1"
                          color={"text.secondary"}
                        >
                          <Typography className="fw-bold">
                            Apply Now to see New Jobs
                          </Typography>
                          {/* <Button variant="outlined" className="basic-1-button my-3 fw-bold" sx={{ textTransform: "unset" }} onClick={() => nav("/dashboard")} >Search New Jobs</Button> */}
                        </Typography>
                      </Box>
                    </Box>
                    // Show message for no applications if there is no data
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Right Section: 30% */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{ paddingLeft: "0px !important", paddingTop: "0px !important" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              {!isSmallScreen ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px",
                    borderRadius: "8px",
                    backgroundColor: "white",
                  }}
                >
                  {/* Left Section: Avatar/ Image and Details */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isSmallScreen ? "column" : "row",
                      alignItems: "center",
                      gap: "24px",
                    }}
                  >
                    {/* Avatar or Image */}
                    {src ? (
                      <img
                        src={src}
                        alt="Profile"
                        loading="lazy"
                        referrerPolicy="no-referrer"
                        className="rounded-circle"
                        style={{
                          width: "75px",
                          height: "75px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Avatar
                        variant="circular"
                        sx={{
                          width: "75px",
                          height: "75px",
                          fontSize: "32px",
                          fontWeight: "500",
                          textTransform: "uppercase",
                          backgroundColor: "#3f51b5", // Background color for Avatar
                          color: "white",
                        }}
                      >
                        {name[0]}
                      </Avatar>
                    )}

                    {/* Name and Role */}
                    <Box>
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "20px",
                          fonStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "32px",
                          letterSpacing: "0.25px",
                        }}
                      >
                        {name}
                      </Typography>

                      <Button
                        onClick={handleBackP}
                        variant="text"
                        sx={{
                          textTransform: "capitalize",
                          textDecoration: "none",
                          fontWeight: "bold",
                          padding: 0,
                          margin: 0,
                          "&:hover, &:focus": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            outline: "none",
                          },
                        }}
                      >
                        Edit Profile
                      </Button>
                    </Box>
                  </Box>

                  {/* Divider */}
                  <Box
                    sx={{
                      width: "2px",
                      height: "60px",
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                    }}
                  ></Box>

                  {/* Right Section: Progress */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {/* Circular Progress with Percentage */}
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        variant="determinate"
                        value={Number(percentage)}
                        size={40}
                        thickness={3}
                        sx={{
                          color: "#3f51b5",
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {`${percentage}%`}
                      </Typography>
                    </Box>

                    {/* Completed Text */}
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        marginTop: "8px",
                        color: "rgba(0, 0, 0, 0.87)",
                        fontSize: "12px",
                      }}
                    >
                      COMPLETED
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <></>
              )}

              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: 2,
                  //boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                  width: "100%",
                  padding: "16px 16px 24px 16px",
                }}
              >
                {/* Header: Skills and Edit Icon */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "text.primary" }}
                  >
                    Skills
                  </Typography>
                  <IconButton aria-label="edit" onClick={handledrawer}>
                    <EditIcon
                      sx={{ cursor: "pointer", color: "text.secondary" }}
                    />
                  </IconButton>
                </Box>

                {/* Subheading: Add More Skills */}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginBottom: 2, fontWeight: "bold", fontSize: "12px" }}
                >
                  {skills.length > 0
                    ? "Add more skills to get matching jobs"
                    : "Add at least 5 skills to get job recommendations"}
                </Typography>

                {/* Skills List */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  {skills.map((value, index) => (
                    <Chip
                      label={value.label}
                      key={index}
                      sx={{
                        display: "flex",
                        padding: "4px",
                        alignItems: "center",
                        fontWeight: "bold",
                        lineheight: "18px",
                        fontSize: "13px",
                        borderRadius: "100px",
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                      }}
                    />
                  ))}
                </Box>

                {/* Recommendedrawer */}
                {onclickdrawer && (
                  <Recommendedrawer
                    setopen={setonclickdrawer}
                    setClose={handleCloseDrawer}
                    updateSkilledDrawer={updateSkilledDrawer}
                    setUpdateSkilledDrawer={setUpdateSkilledDrawer}
                  />
                )}
              </Box>

              <Box
                sx={{
                  padding: "16px 16px 24px 16px",
                  borderRadius: 2,
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                {/* Header: Title and See All */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between", // Align title and See All to the ends
                    alignItems: "center",
                    marginBottom: 2, // Adds some space below the header
                  }}
                >
                  {/* Based on software */}
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Based on your last Search :{" "}
                    {searchBasedData.lastSearchedKeyword}
                  </Typography>

                  {searchBasedData?.jobs?.length > 0 ? (
                    <Button
                      variant="text"
                      className="fw-bold"
                      onClick={() =>
                        nav(
                          `/jobsearch/allsearchdata?search=${
                            searchBasedData.lastSearchedKeyword || ""
                          }&location=${
                            searchBasedData.lastSearchedLocation || "World Wide"
                          }`
                        )
                      }
                      sx={{
                        textDecoration: "none",
                        textTransform: "unset",
                      }}
                    >
                      See all
                    </Button>
                  ) : (
                    <></>
                  )}
                </Box>

                {/* Job Cards */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {Loader.isLoading
                    ? // Shimmer placeholder while data is loading
                      Array(4)
                        .fill(null)
                        .map((_, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                              gap: 2,
                              padding: "16px 16px 8px 16px",
                              border: "1px solid rgba(0, 0, 0, 0.12)",
                              borderRadius: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "16px",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  ...shimmerStyles,
                                  width: "50px",
                                  height: "50px",
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  flexGrow: 1,
                                  gap: 1,
                                }}
                              >
                                <Box
                                  sx={{
                                    ...shimmerStyles,
                                    height: "20px",
                                    width: "60%",
                                  }}
                                />
                                <Box
                                  sx={{
                                    ...shimmerStyles,
                                    height: "16px",
                                    width: "40%",
                                  }}
                                />
                                <Box
                                  sx={{
                                    ...shimmerStyles,
                                    height: "12px",
                                    width: "50%",
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        ))
                    : searchBasedData?.jobs?.slice(0, 4).map((job, index) => (
                      <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        gap: 2,
                        padding: "16px 16px 8px 16px",
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        borderRadius: 2,
                        transition: "transform 0.2s ease, box-shadow 0.2s ease",
                        "&:hover": {
                          transform: "translateY(-1px)",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        },
                      }}
                    >
                     
                    
                          {/* Company Image */}
                          <Link
                            to={`/jobDetail/${job.joborder_id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "16px",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                component="img"
                                src={job.logo}
                                alt={`${job.name} Logo`}
                                sx={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "8px",
                                  objectFit: "cover",
                                }}
                              />
                              {/* Company Details */}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  flexGrow: 1,
                                  gap: 0.5,
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "black",
                                    fontWeight: "700",
                                    fontSize: "16px",
                                    letterSpacing: "0.5px",
                                    lineHeight: "24px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {job.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.80)",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.25px",
                                  }}
                                >
                                  {job.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.64)",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    lineHeight: "20px",
                                    letterSpacing: "0.4px",
                                  }}
                                >
                                  {new Date(
                                    job.date_created
                                  ).toLocaleDateString("en-US", {
                                    month: "short",
                                    day: "2-digit",
                                    year: "numeric",
                                  })}
                                </Typography>
                              </Box>
                            </Box>
                          </Link>

                          {/* Close Icon */}
                          <IconButton
                            aria-label="delete"
                            sx={{
                              alignSelf: "flex-start",
                              color: "text.secondary",
                            }}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the Link from being triggered
                              handleNotInterestedJob(job.joborder_id);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      ))}
                </Box>
              </Box>

              {subscriber === 0 && (
                <Box pt={1.8} mb={2} px={2} className="rounded-3 bg-white">
                  <Box
                    variant="body1"
                    className="fw-bold"
                    display={"flex"}
                    gap={2}
                  >
                    <Typography variant="body1" gutterBottom>
                      <img src="./NotifyIcon.svg" alt="Notify" />
                    </Typography>
                    <Typography variant="body2">
                      <Typography
                        variant="body1"
                        className="fw-bold"
                        gutterBottom
                      >
                        Get relevant jobs
                      </Typography>
                      <Typography
                        variant="body2"
                        className="fw-semibold"
                        color={"text.secondary"}
                      >
                        Subscribe to get relevant jobs in your inbox
                      </Typography>
                    </Typography>
                  </Box>
                  <Box
                    sx={{ mt: 2 }}
                    pb={2}
                    display={"flex"}
                    justifyContent={"end"}
                  >
                    <Button
                      variant="text"
                      className="fw-bold"
                      onClick={handleSubscribe}
                    >
                      Subscribe
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* </Container> */}
      {/* </div> */}
      <FooterZ />
    </>
  );
}
