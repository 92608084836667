import React from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import Homeheader from "../components/homeheader";
import FooterN from "../components/FooterN";
import FooterZ from "../components/FooterZ";
import { Helmet } from "react-helmet";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Vision Icon
import TrackChangesIcon from "@mui/icons-material/TrackChanges"; // Mission Icon
import { useNavigate } from "react-router-dom";
import "./About.css";
const values = [
  {
    icon: "Handshake-icon.svg",
    title: "Integrity",
    description:
      "We uphold the highest ethical standards, ensuring transparency and trust in all interactions.",
  },
  {
    icon: "UserIcons.png",
    title: "Simplicity",
    description:
      "We prioritize an intuitive, user-friendly experience to make job hunting straightforward and stress-free.",
  },
  {
    icon: "innovation-Icons.svg",
    title: "Innovation",
    description:
      "We embrace latest AI technology to enhance the job search and hiring experience for everyone.",
  },
  {
    icon: "collaboration-icon.svg",
    title: "Collaboration",
    description:
      "We foster connections through communication, empowering job seekers and guiding employers decisions.",
  },
  {
    icon: "efficiency-Icons.svg",
    title: "Efficiency",
    description:
      "We streamline hiring and applications with automation, letting candidates and employers focus on what matters.",
  },
];
const techCards = [
  {
    icon: "accurate-job-maching.svg",
    title: "Accurate Job Matching",
    description:
      "Algorithms connect candidates with the best opportunities, improving placement success.",
  },
  {
    icon: "Auto-Applying.svg",
    title: "Auto Applying",
    description: "Candidates can apply to multiple jobs with one click.",
  },
  {
    icon: "cover-letter.svg",
    title: "Personalized Cover Letters",
    description:
      "Automatically generated cover letters enhance candidate profiles.",
  },
  {
    icon: "hiring-efficiency.svg",
    title: "Hiring Efficiency",
    description:
      "Automation reduces manual screening for quicker engagement with top candidates.",
  },
  {
    icon: "enhanced-experience.svg",
    title: "Enhanced Experience",
    description:
      "Our technology provides a smooth, personalized journey for candidates.",
  },
];

const AboutUs = () => {
  const token = document.cookie.split("_secure_ARJ_=")[1];
  const navigate = useNavigate();
  const getStyles = () => {
    if (window.innerWidth < 600) {
      // Adjust threshold as needed
      return {
        borderRadius: "0px",
        maxHeight: "250px",
      };
    } else {
      return {
        borderRadius: "200px 200px 200px 30px",
        maxHeight: "417px", // or adjust this if needed
      };
    }
  };
  const styles = getStyles();

  return (
    <>
      <Helmet>
        <title>
          Professional Job Seeker Platform | Trusted Job Portal | Jobeze
        </title>
        <meta
          name="title"
          content="Professional Job Seeker Platform | Trusted Job Portal | Jobeze"
        />
        <meta
          name="description"
          content="Welcome to Jobeze! Learn how we aim to transform the job search experience for seekers and employers alike with our advanced online job search engine."
        />
      </Helmet>

      <div className="fx">
        <div className="sticky-top bg-white">
          <Homeheader />
        </div>
        <main>
          {/* Who We Are Section */}
          <Box
            sx={{
              padding: { xs: "20px 16px", md: "80px 100px" },
              alignItems: "center",
              backgroundColor: "#f9f9f9",
              backgroundImage: "url(about-us-bg.jpg)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <Grid
              maxWidth={"700px"}
              xs={12}
              md={6}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "40px",
              }}
            >
              <Box
                sx={{
                  width: "fit-content",
                }}
              >
                <Typography
                  variant="h5"
                  fontSize={{ xs: "16px", md: "18px" }}
                  mb={"20px"}
                  padding={"8px 16px"}
                  color="primary"
                  textAlign="center"
                  border={"1px solid #006CB7"}
                  borderRadius={"24px"}
                  width="100%"
                >
                  About us
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "36px", md: "48px", lg: "48px" },
                  textTransform: "capitalize",
                  fontWeight: "500",
                  lineHeight: "50px",
                }}
                gutterBottom
              >
                Who We Are
              </Typography>
              <Box sx={{ mr: 2 }} />
              <Typography
                paragraph
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#666",
                }}
              >
                At Jobeze, we automate job searches, matching, and applications
                with our AI-driven platform, connecting employers with top
                talent while helping job seekers find the best opportunities
                effortlessly. Our AI agent personalizes and simplifies the
                job-hunting process, saving time and streamlining success for
                both businesses and candidates.
              </Typography>
            </Grid>
          </Box>

          {/* Vision and Mission Section */}
          <Box
            sx={{
              padding: { xs: " 20px 16px", md: "80px 64px" },
              backgroundColor: "#fff",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={4}
              alignItems="center"
              sx={{
                gap: { xs: "20px", md: "80px" },
                flexWrap: { xs: "wrap", md: "nowrap" },
              }}
            >
              <Grid item xs={12} md={6}>
                <img
                  src="\aboutus-img2.jpg"
                  alt="Vision and Mission"
                  style={{
                    width: "100%",
                    height: "auto",
                    aspectRatio: "492/417",
                    objectFit: "cover",
                    ...styles,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {/* Our Vision Section */}
                <Box
                  mb={"20px"}
                  display="flex"
                  alignItems="center"
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                >
                  <Box
                    className="Our-section-icon"
                    sx={{
                      backgroundColor: "#006CB7",
                      borderRadius: "10px",
                      padding: "8px",
                      marginRight: {
                        xs: "0px",
                        sm: "0px",
                        md: "16px",
                        lg: "16px",
                        xl: "16px",
                      },
                      display: "flex",
                      width: "fit-content",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <VisibilityIcon
                      sx={{ width: 48, height: 48, color: "white" }}
                    />{" "}
                    {/* Vision Icon */}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "28px", sm: "28px", md: "48px" },
                      textTransform: "capitalize",
                      marginTop: {
                        xs: "20px",
                        sm: "20px",
                        md: "0px",
                        lg: "0px",
                        xl: "0px",
                      },
                      fontWeight: "500",
                      lineHeight: { xs: "40px", md: "50px" },
                    }}
                  >
                    Our Vision
                  </Typography>
                </Box>

                <Box
                  className="Our-section-text"
                  sx={{
                    marginBottom: "60px",
                  }}
                >
                  <Typography
                    paragraph
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.5px",
                      color: "#666",
                      textAlign: { xs: "center", md: "left" }, // Responsive alignment
                    }}
                  >
                    To be the leading AI-driven job platform that automates the
                    job search, matching, and application process, enabling job
                    seekers to connect with top opportunities while empowering
                    businesses to hire top talent efficiently.
                  </Typography>
                </Box>

                {/* Our Mission Section */}
                <Box
                  mb={"20px"}
                  display="flex"
                  alignItems="center"
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                >
                  <Box
                    className="Our-section-icon"
                    sx={{
                      backgroundColor: "#006CB7",
                      borderRadius: "10px",
                      padding: "8px",
                      marginRight: {
                        xs: "0px",
                        sm: "0px",
                        md: "16px",
                        lg: "16px",
                        xl: "16px",
                      },
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TrackChangesIcon
                      sx={{ width: 48, height: 48, color: "white" }}
                    />{" "}
                    {/* Mission Icon */}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "28px", sm: "28px", md: "48px" },
                      textTransform: "capitalize",
                      marginTop: {
                        xs: "20px",
                        sm: "20px",
                        md: "0px",
                        lg: "0px",
                        xl: "0px",
                      },
                      fontWeight: "500",
                      lineHeight: { xs: "40px", md: "50px" },
                    }}
                  >
                    Our Mission
                  </Typography>
                </Box>

                <Box className="Our-section-text">
                  <Typography
                    paragraph
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.5px",
                      color: "#666",
                      textAlign: { xs: "center", md: "left" }, // Responsive alignment
                    }}
                  >
                    Our mission is to empower businesses by sourcing the right
                    talent while helping job seekers effortlessly find top
                    positions. Our AI agent personalizes and simplifies the job
                    search process, enabling candidates to match with and
                    auto-apply for the best opportunities while connecting
                    businesses with top talent.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Technology Section */}
          <Box
            sx={{
              display: "flex",
              padding: { xs: "20px 16px", md: "20px 64px 80px 64px" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Technology Badge */}
            <Box display="flex" justifyContent="center" mb={"20px"}>
              <Typography
                variant="h6"
                color="primary"
                padding={"8px 16px"}
                border={"1px solid #006CB7"}
                borderRadius={"24px"}
                textAlign={"center"}
                fontSize={"18px"}
              >
                Technology
              </Typography>
            </Box>

            {/* Main Heading */}
            <Typography
              sx={{
                fontSize: {
                  xs: "28px",
                  sm: "28px",
                  md: "48px",
                  lg: "48px",
                  xl: "48px",
                },
                textTransform: "capitalize",
                fontWeight: "500",
                mb: "20px",
              }}
              textAlign="center"
            >
              Revolutionizing Recruitment With Smart Automation
            </Typography>

            {/* Subheading/Description */}
            <Typography
              paragraph
              variant="body2"
              color="#666"
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              letterSpacing={"0.5px"}
              textAlign="center"
              sx={{ mb: "60px" }}
            >
              Our cutting-edge technology transforms the job search experience,
              helping candidates find the perfect fit faster and more
              efficiently.
            </Typography>

            {/* Grid for the technology cards */}
            <Grid container spacing={4} justifyContent="center">
              {techCards.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      // width: "421.33px",
                      width: "100%",
                      padding: "30px 30px 32px 30px",
                      height: "100%",
                      // height: "274px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      borderRadius: "20px",
                      backgroundImage: `url(abstract-shape.svg)`,
                      backgroundSize: "cover",
                      alignItems: "center",
                      border: " 1px solid rgba(0, 0, 0, 0.10)",
                      boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <Box
                      component="img"
                      src={card.icon}
                      alt={`${card.title} icon`}
                      sx={{ width: "100px", height: "100px", mr: 2 }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        fontSize={{
                          xs: "24px",
                          sm: "24px",
                          md: "28px",
                          lg: "28px",
                          xl: "28px",
                        }}
                        fontWeight={500}
                        gutterBottom
                      >
                        {card.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="#666"
                        fontSize={"16px"}
                        fontWeight={400}
                        lineHeight={"24px"}
                        letterSpacing={"0.5px"}
                      >
                        {card.description}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Company Culture Section */}
          <Box
            sx={{
              display: "flex",
              padding: { xs: "80px 20px", md: "80px 64px" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              backgroundColor: "#F2F9FD",
            }}
          >
            <Box display="flex" justifyContent="center" mb={"20px"}>
              <Typography
                variant="h6"
                color="primary"
                padding={"8px 16px"}
                border={"1px solid #006CB7"}
                borderRadius={"24px"}
                textAlign={"center"}
                fontSize={"18px"}
              >
                Our Values
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "28px",
                  sm: "28px",
                  md: "48px",
                  lg: "48px",
                  xl: "48px",
                },
                mb: "20px",
                textTransform: "capitalize",
                fontWeight: "400",
                lineHeight: "50px",
                textAlign: "center",
              }}
            >
              Our Company Culture
            </Typography>
            <Typography
              paragraph
              color="#666"
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              letterSpacing={"0.5px"}
              textAlign="center"
              mb={"40px"}
              sx={{ maxWidth: "110ch" }}
            >
              At Jobeze, our core values are the foundation of our mission to
              revolutionize recruitment. We strive to create a trustworthy,
              tech-driven platform that connects job seekers and employers
              seamlessly.
            </Typography>

            {/* Grid Layout for Cards */}
            <Grid container spacing={4} justifyContent="center">
              {/* Loop through the values array and create a card for each */}
              {values.map((value, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  display="flex"
                  justifyContent="center"
                >
                  <Card
                    sx={{
                      display: "flex",
                      // width: "421.33px",
                      width: "100%",
                      height: "100%",
                      padding: "24px 40px 30px 40px",
                      flexDirection: "column",
                      alignItems: "center",
                      borderRadius: "20px",
                      backgroundColor: "white",
                      border: "2px solid rgba(63, 149, 234, 0.26)",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent
                      sx={{
                        padding: "0px 0px 0px 0px !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      {/* Display the Icon */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "80px",
                          height: "80px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "100px",
                          backgroundColor: "#006CB7", // background color
                        }}
                      >
                        <Box
                          component="img"
                          src={value.icon}
                          alt={`${value.title} icon`}
                          sx={{ width: 44, height: 44 }}
                        />
                      </Box>

                      <Typography
                        variant="h6"
                        fontSize={{
                          xs: "24px",
                          sm: "24px",
                          md: "28px",
                          lg: "28px",
                          xl: "28px",
                        }}
                        fontWeight="500"
                        mt={2}
                      >
                        {value.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="#666"
                        fontSize={"16px"}
                        fontWeight={400}
                        lineHeight={"24px"}
                        letterSpacing={"0.5px"}
                      >
                        {value.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* contact us section */}
          <Box
            sx={{
              padding: { xs: "40px 20px", md: "80px 64px" },
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: { xs: "32px", md: "64px" },
                flexDirection: "column",
                alignItems: "flex-start",
                borderRadius: "20px",
                border: "1px solid rgba(0, 108, 183, 0.40)",
                backgroundColor: "#FFF",
                width: "100%",
                margin: "0 auto",
              }}
            >
              <Typography
                variant="h5"
                fontSize={{ xs: "16px", md: "18px" }}
                mb={"20px"}
                padding={"8px 16px"}
                color="primary"
                textAlign="center"
                border={"1px solid #006CB7"}
                borderRadius={"24px"}
              >
                Connect with us
              </Typography>

              <Typography
                variant="h2"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: {
                    xs: "28px",
                    sm: "28px",
                    md: "48px",
                    lg: "48px",
                    xl: "48px",
                  },
                  fontWeight: 500,
                  lineHeight: { xs: "32px", sm: "40px", md: "50px" },
                  textTransform: "capitalize",
                  marginBottom: "12px",
                  textAlign: "left",
                }}
              >
                Get in Touch
              </Typography>

              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#666",
                  fontWeight: 400,
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  textAlign: "left",
                  whiteSpace: "normal",
                }}
                paragraph
              >
                Whether you’re a business looking for talent or a job seeker
                ready to advance your career, we’re here to help. Please reach
                out to us at support@jobeze.com.
              </Typography>

              <Button
                onClick={() => navigate("/contact-us")}
                variant="contained"
                sx={{
                  backgroundColor: "#006CB7",
                  mt: "30px",
                  fontSize: { xs: "14px", md: "16px" },
                  padding: "8px",
                  width: "fit-content",
                }}
              >
                Reach us now
              </Button>
            </Box>
          </Box>
        </main>

        {token ? <FooterZ /> : <FooterN />}
      </div>
    </>
  );
};

export default AboutUs;
