import React, { useEffect, useRef, useState } from "react";
import {
  List,
  ListItem,
  Divider,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@mui/material";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import dayjs from "dayjs";

function UserNotifications({
  notifications,
  setNotifications,
  handleClose,
  setNotificationCount,
}) {
  const [expanded, setExpanded] = useState({
    panel1: true,
    panel2: false,
    panel3: false,
  });
  const boxRef = useRef(null);

  const handleChange = (panel) => (event, isExpanded) => {
    const newExpanded = {
      panel1: false,
      panel2: false,
      panel3: false,
    };

    if (isExpanded) {
      newExpanded[panel] = true;
    } 
    if (boxRef.current) {
      boxRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    setExpanded(newExpanded);
  };
  const filterNotifications = () => {
    const now = dayjs();
    const today = [];
    const past30Days = [];
    const past90Days = [];

    notifications.forEach((notification) => {
      const notificationDate = dayjs(notification.notificationDate);
      const daysDiff = now.diff(notificationDate, "day");

      if (daysDiff === 0) {
        today.push(notification);
      } else if (daysDiff <= 30) {
        past30Days.push(notification);
      } else if (daysDiff <= 90) {
        past90Days.push(notification);
      }
    });

    return { today, past30Days, past90Days };
  };

  useEffect(() => {
    const notificationToday = filterNotifications(1);
    const notificationPastMonth = filterNotifications(30, 1, true);
    const notificationPast90Days = filterNotifications(90, 30, true);

    if (notificationToday.length > 0) {
      setExpanded({ panel1: true, panel2: false, panel3: false });
    } else if (notificationPastMonth.length > 0) {
      setExpanded({ panel1: false, panel2: true, panel3: false });
    } else if (notificationPast90Days.length > 0) {
      setExpanded({ panel1: false, panel2: false, panel3: true });
    }
  }, [notifications]);
  

  const handleMarkAllAsRead = async () => {
    const notificationIds = notifications
      .filter((notification) => notification.isViewed === 0)
      .map((notification) => notification.notificationId);

    if (notificationIds.length === 0) return;

    const payload = { markAllAsRead: true };
    const response = await axios.post(`/user/mark-as-read`, payload, {
      headers: { authorization: document.cookie },
    });

    if (response.status === 200) {
      const updatedNotifications = notifications.map((notification) => ({
        ...notification,
        isViewed: 1,
      }));
      setNotifications(updatedNotifications);
      setNotificationCount(0);
      handleClose();
    }
  };

  const handleMarkAsRead = async (notificationId) => {
    const notification = notifications.find(
      (n) => n.notificationId === notificationId
    );

    if (!notification || notification.isViewed === 1) {
      return;
    }

    const payload = { notificationId: notificationId };
    const response = await axios.post(`/user/mark-as-read`, payload, {
      headers: { authorization: document.cookie },
    });

    if (response.status === 200) {
      const updatedNotifications = notifications.map((notification) =>
        notification.notificationId === notificationId
          ? { ...notification, isViewed: 1 }
          : notification
      );
      setNotifications(updatedNotifications);

      setNotificationCount((prevCount) => prevCount - 1);
    }
  };

  const handleRemoveNotification = async (notificationId) => {
    const payload = { notificationId: notificationId };
    const response = await axios.post(`/user/delete-notification`, payload, {
      headers: { authorization: document.cookie },
    });

    if (response.status === 200) {
      const updatedNotifications = notifications.map((notification) =>
        notification.notificationId === notificationId
          ? { ...notification, isDeleted: 1 }
          : notification
      );
      setNotifications(updatedNotifications);
      const notification = notifications.find(
        (n) => n.notificationId === notificationId
      );
      if (notification?.isViewed === 0) {
        setNotificationCount((prevCount) => prevCount - 1);
      }
    }
  };

  // const filterNotifications = (days, excludeDays = 0, excludeToday = false) => {
  //   const now = dayjs();

  //   return notifications.filter((notification) => {
  //     const notificationDate = dayjs(notification.notificationDate);
  //     const isInRange = now.diff(notificationDate, "day") <= days;
  //     const isOutOfExcludeRange =
  //       excludeDays === 0 || now.diff(notificationDate, "day") > excludeDays;
  //     const isNotToday =
  //       excludeToday &&
  //       !(
  //         notificationDate.isAfter(now.startOf("day")) &&
  //         notificationDate.isBefore(now.endOf("day"))
  //       );

  //     return isInRange && isOutOfExcludeRange && (!excludeToday || isNotToday);
  //   });
  // };
  const renderNotificationList = (filteredNotifications) => {
    const visibleNotifications = filteredNotifications.filter(
      (notification) => notification.isDeleted !== 1
    );

    if (visibleNotifications.length === 0) {
      return (
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ textAlign: "center", margin: "8px" }}
        >
          No new notifications
        </Typography>
      );
    }

    return (
      <List dense sx={{ padding: 0 }}>
        {visibleNotifications.map((notification) => (
          <React.Fragment key={notification.notificationId}>
            <ListItem
              sx={{
                paddingY: "8px",
                display: "flex",
                alignItems: "center",

                backgroundColor: notification.isViewed === 0 ? "#E5F6FD" : "",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    color: "black",
                    textDecoration: "none",
                  }}
                  gutterBottom
                >
                  {notification.notificationText.includes("applied") ? (
                    <Link
                      href="/applied-jobs"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "black",

                        "&:visited": {
                          color: "black",
                        },
                      }}
                    >
                      {notification.notificationText}
                    </Link>
                  ) : notification.notificationText.includes("shortlisted") ? (
                    <Link
                      href="/preffered-jobs?job=shortlistedJobs"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "black",
                        "&:visited": {
                          color: "black",
                        },
                      }}
                    >
                      {notification.notificationText}
                    </Link>
                  ) : (
                    notification.notificationText
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", color: "rgba(33, 37, 41, 0.75" }}
                >
                  {dayjs(notification.notificationDate).format("MMM DD YYYY")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  marginLeft: "16px",
                }}
              >
                {notification.isViewed === 0 && (
                  <Tooltip title="New Notification" arrow>
                    <FiberManualRecordIcon
                      sx={{ color: "blue", fontSize: "10px" }}
                    />
                  </Tooltip>
                )}

                {notification.isViewed === 0 && (
                  <Tooltip title="Mark as Read" arrow>
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleMarkAsRead(notification.notificationId)
                      }
                    >
                      <DoneIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title="Remove Notification" arrow>
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleRemoveNotification(notification.notificationId)
                    }
                    color="error"
                  >
                    <RemoveCircleIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </ListItem>
            <Divider sx={{ backgroundColor: "black" }} />
          </React.Fragment>
        ))}
      </List>
    );
  };
  const { today, past30Days, past90Days } = filterNotifications();
  return (
    <Box
      sx={{
        width: {
          xs: "auto",
          md: "400px",
        },
        height: "auto",
        maxHeight: "600px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#4b6bf4",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 16px",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Notifications
        </Typography>
        <Tooltip title="Mark all as read" arrow>
          <IconButton
            onClick={handleMarkAllAsRead}
            sx={{
              color: "white",
            }}
          >
            <DoneAllIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box
        className="jbscroll"
        ref={boxRef}
        sx={{
          height: "auto !important",
          overflowY: "auto",
          maxHeight: notifications.length === 0 ? "100%" : "56vh ",
        }}
      >
        <Accordion
          expanded={expanded.panel1}
          onChange={handleChange("panel1")}
          sx={{ margin: "0px !important" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#EBEBEB" }}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              Past 24 Hours
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {/* {renderNotificationList(filterNotifications(1))} */}
            {renderNotificationList(today)}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded.panel2}
          onChange={handleChange("panel2")}
          sx={{ margin: "0px !important" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#EBEBEB" }}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              Past One Month
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {/* {renderNotificationList(filterNotifications(30, 1, true))} */}
            {renderNotificationList(past30Days)}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded.panel3}
          onChange={handleChange("panel3")}
          sx={{ margin: "0px !important" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#EBEBEB" }}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              Past 90 Days
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {/* {renderNotificationList(filterNotifications(90, 30, true))} */}
            {renderNotificationList(past90Days)}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

export default UserNotifications;
