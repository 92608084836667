import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "./Shareable.css";
// import { ShareSocial } from 'react-share-social'
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import XIcon from "@mui/icons-material/X";
import { styled } from "@mui/material/styles";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import CloseIcon from "@mui/icons-material/Close";

function Shareable({ isOpen, setOpen, url, handleViewFullpage, mobileview }) {
  function handlecopy() {
    handleViewFullpage();
  }
  // function facebook() {
  //     const newWindow = window.open(url, '_blank');
  //     newWindow.focus();
  // }
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(1),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const isSmallScreen = useMediaQuery("(max-width: 625px)");

  return (
    <>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Share Job
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box className="d-flex flex-column gap-4 p-3 py-2">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              onClick={() => setOpen(false)}
            >
              <Box className={isSmallScreen ? "" : "iconbtn"}>
                <FacebookShareButton url={url}>
                  <FacebookIcon
                    size={40}
                    round
                    sx={{ width: "40px", height: "40px" }}
                  />
                  <Typography variant="body2" sx={{ xs: "10px", md: "14px" }}>
                    facebook
                  </Typography>
                </FacebookShareButton>
              </Box>

              <Box className={isSmallScreen ? "" : "iconbtn"}>
                <WhatsappShareButton url={url}>
                  <WhatsappIcon
                    size={40}
                    round
                    sx={{ width: "40px", height: "40px" }}
                  />
                  <Typography variant="body2" sx={{ xs: "10px", md: "14px" }}>
                    Whatsapp
                  </Typography>
                </WhatsappShareButton>
              </Box>

              <Box className={isSmallScreen ? "" : "iconbtn"}>
                <TwitterShareButton url={url}>
                  <XIcon
                    size={40}
                    round
                    sx={{ width: "40px", height: "40px" }}
                  />
                  <Typography variant="body2" sx={{ xs: "10px", md: "14px" }}>
                    Twitter
                  </Typography>
                </TwitterShareButton>
              </Box>

              <Box className={isSmallScreen ? "" : "iconbtn"}>
                <LinkedinShareButton url={url}>
                  <LinkedinIcon
                    size={40}
                    round
                    sx={{ width: "40px", height: "40px" }}
                  />
                  <Typography variant="body2" sx={{ xs: "10px", md: "14px" }}>
                    Linkedin
                  </Typography>
                </LinkedinShareButton>
              </Box>

              <Box className={isSmallScreen ? "" : "iconbtn"}>
                <EmailShareButton url={url}>
                  <EmailIcon
                    size={40}
                    round
                    sx={{ width: "40px", height: "40px" }}
                  />
                  <Typography variant="body2" sx={{ xs: "10px", md: "14px" }}>
                    Email
                  </Typography>
                </EmailShareButton>
              </Box>
            </Box>
            <Box py={1.5} display={"flex"} justifyContent={"space-evenly"}>
              <TextField
                className="w-75 text-dark"
                sx={{}}
                size="small"
                disabled
                variant="outlined"
                InputProps={{
                  disableUnderline: true,
                }}
                defaultValue={url}
                id="outlined-disabled"
              />
              <Button
                variant="contained"
                size="small"
                className="px-3 fw-bold"
                startIcon={<ContentCopyIcon />}
                onClick={handlecopy}
              >
                Link
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
export default Shareable;
