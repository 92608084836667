import "./App.css";
import { Routes, Route, useSearchParams, useLocation } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import Homepage from "./screens/homepage";
import Jobsearchpage from "./screens/jobsearchpage";
import { BrowserRouter } from "react-router-dom";
import { Forgetpage } from "./components/Forgetpage";
import ProtectedRoute from "./ProtectedRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorPage from "./components/Error";
import { VerificationPage } from "./components/VerificationPage";
import Aboutus from "./screens/Aboutus";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsCondition from "./screens/TermsCondition";
import SecurityC from "./screens/SecurityC";
import BewareFruad from "./screens/BewareFruad";
import GDPR from "./screens/GDPR";
import Contactus from "./screens/Contactus";
import Viewjobs from "./components/Viewjobs";
import SiteMaphtml from "./screens/Sitemaphtml";
// import Hotjar from '@hotjar/browser';
import Unsubscribe from "./screens/unsubscribe";
import JobDetails from "./screens/jobDetails";
// import CookieConsent from "react-cookie-consent";
import Cookies from "./components/Cookies";
import ForgetPassword from "./components/ForgetPassword";
import MobileSearch from "./screens/MobileSearch";
import Blogs from "./screens/Blogs";
import BlogDetail from "./screens/BlogDetail";
// import AiFullPage from './screens/aIrecommendedFullPage';
// import MobileSearch from './screens/mobilesearch';
function App() {
  // useEffect(() => {

  //   if (process.env.REACT_APP_ENVIRONMENT == "PROD") {
  //     const siteId = 3527615;
  //     const hotjarVersion = 6;
  //     Hotjar.init(siteId, hotjarVersion);
  //   }
  // }, [])

  const location = useLocation();
  const containerRef = useRef();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
    console.clear();
    console.error = () => {}; // Override to do nothing
    console.warn = () => {}; // Override to do nothing
    console.log = () => {}; // Override to do nothing
    console.info = () => {}; // Override to do nothing
  }, [location.pathname, location.search]);

  return (
    <div className="">
      {/* <BrowserRouter> */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/jobsearch/:title" element={<Jobsearchpage />} />
          <Route
            path="/user/jobeze-verification/change-password/:id/:id"
            element={<Forgetpage />}
          />
          <Route
            path="/user/jobeze-verification/verify-account/:id"
            element={<VerificationPage />}
          />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<BlogDetail />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/security-cheaker" element={<SecurityC />} />
          <Route path="/bewarefruad" element={<BewareFruad />} />
          <Route path="/gdpr" element={<GDPR />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/sitemap-html" element={<SiteMaphtml />} />
          <Route path="/jobDetail/:id" element={<JobDetails />} />
          <Route path="newsletter/unsubscribe" element={<Unsubscribe />} />
          <Route path="/page404" element={<ErrorPage />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/mobile-search" element={<MobileSearch />} />
          {/* <Route path='/recommendedJobs' element={<AiFullPage />} /> */}
        </Routes>
        <ProtectedRoute />
      {/* </BrowserRouter> */}
      <ToastContainer autoClose={2000} position="top-right" theme="colored" />
      <Cookies debug={true} />
    </div>
  );
}

export default App;
