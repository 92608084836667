import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import Homeheader from "../components/homeheader";
import FooterZ from "../components/FooterZ";
import FooterN from "../components/FooterN";
import axios from "axios";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Helmet } from "react-helmet";
const BlogDetail = () => {
  const token = document.cookie.split("_secure_ARJ_=")[1];
  const navigate = useNavigate();
  const { id } = useParams();
  const currentBlogId = id;
  const [popularBlog, setPopularBlog] = useState([]);
  const [blog, setBlog] = useState([]);

  const getBlog = async (currentBlogId) => {
    try {
      const response = await axios.get(`/blog/${currentBlogId}`);
      setBlog(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // Popular Blog: send payload with `popular` set to true in the same API
  const fetchPopularBlogs = async () => {
    try {
      const response = await axios.get(`/blogs/1?popular=true`);
      const blog_result = response.data.message;
      setPopularBlog(response.data.message.blog_result);
      const foundBlog = blog_result.find(
        (b) => b.blog_id === parseInt(currentBlogId)
      ); // Find the blog by ID
      if (!foundBlog) {
        navigate("/page404");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPopularBlogs();
    getBlog(currentBlogId);
  }, []);

  const filteredBlog = popularBlog.filter(
    (blog) => blog.blog_id !== parseInt(currentBlogId)
  );
  const sanitizeAndStyleHTML = (htmlContent, altTags) => {
    if (!blog && blog.length === 0) {
      return;
    }
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Split the altTags string by commas into an array
    const altTagsArray = altTags?.split(",").slice(1);

    // Get all img elements in the tempDiv
    const images = tempDiv.getElementsByTagName("img");

    // Loop through each img element and set styles and alt attribute
    for (let i = 0; i < images.length; i++) {
      const img = images[i];

      // Set styling
      img.style.maxWidth = "100%";
      img.style.height = "auto";
      img.style.objectFit = "cover";
      img.style.borderRadius = "12px";

      // Set alt attribute from the altTags array if available
      if (altTagsArray[i]) {
        img.alt = altTagsArray[i].trim(); // Trim to remove any extra whitespace
      } else {
        img.alt = "Jobeze - AI-powered job assistant"; // Default alt text if no altTag is provided
      }
    }

    return tempDiv.innerHTML;
  };
  const styledContent = sanitizeAndStyleHTML(blog.content, blog.alt_text);

  return (
    <div className="fx">
      <Helmet>
        <title>{blog?.meta_title}</title>
        <meta name="title" content={blog?.meta_title} />
        <meta name="description" content={blog?.meta_description} />
      </Helmet>
      <div className="sticky-top bg-white">
        <Homeheader />
      </div>
      {!blog || blog.length < 1 ? (
        <CircularProgress sx={{ position: "fixed", left: "50%" }} />
      ) : (
        <main>
          <Box
            sx={{
              padding: { xs: "16px", md: " 32px 100px" },
              alignItems: "center",
              backgroundColor: "#f9f9f9",
              backgroundImage: "url(/blogs-static-img.jpg)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              maxWidth={"700px"}
              xs={12}
              md={6}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: { xs: "32px", sm: "40px" },
              }}
            >
              <Box
                sx={{
                  width: "fit-content",
                }}
              >
                <Typography
                  variant="h5"
                  fontSize={{ xs: "16px", md: "18px" }}
                  mb={"20px"}
                  padding={"8px 16px"}
                  color="primary"
                  textAlign="center"
                  border={"1px solid #006CB7"}
                  borderRadius={"24px"}
                  width="100%"
                >
                  Blogs Details
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "24px", md: "28px", lg: "28px" },
                  textTransform: "capitalize",
                  fontWeight: "600",
                  lineHeight: { xs: "36px", sm: "50px" },
                }}
              >
                {blog.title}
              </Typography>
            </Grid>
          </Box>

          <Box
            sx={{
              padding: {
                sm: "40px 120px",
                xs: "44px 16px 40px 16px",
              },
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                // display: "flex",
                gap: { xs: "44px", sm: "64px" },
                flexWrap: "nowrap",
                marginLeft: { xs: "0px" },
                width: { xs: "100%" },
                padding: { xs: "0px" },
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Grid
                item
                xs={12}
                md={8}
                sx={{ padding: { xs: "0px !important" } }}
              >
                <Card sx={{ boxShadow: "none" }}>
                  {/* <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "24px", md: "28px", lg: "28px" },
                      fontWeight: "700",
                    }}
                  >
                    {blog.title}
                  </Typography> */}
                  <CardMedia
                    component="img"
                    height="400"
                    image={blog.featured_image}
                    alt={blog?.alt_text?.split(",", 1)}
                    sx={{
                      borderRadius: "12px",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <CardContent sx={{ padding: { xs: "0px !important" } }}>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{
                        marginTop: "8px",
                        fontWeight: "400",
                        lineHeight: { xs: "28px", sm: "32px" },
                        textAlign: "justify",
                      }}
                    >
                      {blog.description}
                    </Typography>
                    <div
                      style={{ textAlign: "-moz-initial" }}
                      dangerouslySetInnerHTML={{ __html: styledContent }}
                    />
                  </CardContent>
                </Card>
              </Grid>

              {/* Popular Blog */}
              {filteredBlog.length > 0 && filteredBlog ? (
                <Grid
                  // item
                  maxHeight={"fit-content"}
                  xs={12}
                  md={4}
                  sx={{
                    padding: {
                      xs: "16px",
                      sm: "25px 30px 30px 30px",
                    },
                    boxShadow: "0px 8px 10px 0px rgba(105, 93, 93, 0.15)",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Popular Blog
                  </Typography>
                  <Divider
                    sx={{ marginBottom: "24px", backgroundColor: "#000" }}
                  />{" "}
                  {/* Darker divider */}
                  {filteredBlog.map((popularBlog) => (
                    <Card
                      key={popularBlog.blog_id}
                      sx={{
                        display: "flex", // To align image and text side by side
                        marginBottom: "16px",
                        boxShadow: "none",
                      }}
                    >
                      <CardMedia
                        component="img"
                        sx={{
                          width: 80,
                          height: 80,
                          borderRadius: { xs: "10px", sm: "22px" },
                          marginRight: "16px",
                        }}
                        image={popularBlog.featured_image} // Blog image displayed here
                        alt={popularBlog?.alt_text?.split(",", 1)}
                      />
                      <CardContent sx={{ padding: "0px" }}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "24px",
                          }}
                        >
                          {popularBlog.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          onClick={() => {
                            navigate(`/blogs/${popularBlog.blog_id}`);
                            getBlog(popularBlog.blog_id);
                          }} // Navigate to blog detail page
                          sx={{ color: "blue", marginTop: "8px", cursor: "pointer" }}
                        >
                          KNOW MORE
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        </main>
      )}
      {token ? <FooterZ /> : <FooterN />}
    </div>
  );
};

export default BlogDetail;
