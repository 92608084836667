import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
// import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
// import HeaderAvatar from "./HeaderAvatar";
import { Badge, IconButton, Tooltip, Popover, Drawer } from "@mui/material";
import HeadersearchBar from "./HeadersearchBar";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
// import { deepOrange, deepPurple } from '@mui/material/colors';
import { useCookies } from "react-cookie";
import { styled } from "@mui/material/styles";
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/hooks";
import UserNotifications from "./Notifications";
import { NotificationsNoneOutlined } from "@mui/icons-material";

function Homeheader({
  skill,
  setSkill,
  location,
  setLocation,
  handleSearch,
  name,
}) {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const imageProfile = useSelector((state) => state.user.profile);
  // const [src, setSrc] = useState(imageProfile || "");
  const [cookies, setCookie] = useCookies([]);
  const handleprofile = () => {
    nav("/profile-section");
  };

  // const handleapplication = () => {
  //   nav("/applicationsection");
  // };

  const handleLogout = () => {
    localStorage.clear();
    const d = new Date();
    let expires = "expires=" + d.toUTCString();
    document.cookie = "_secure_ARJ_" + "=" + "" + ";" + expires + ";path=/";
    document.cookie = "_secure_ref" + "=" + "" + ";" + expires + ";path=/";
    document.cookie = "cookiesAccepted" + "=" + "" + ";" + expires + ";path=/";
    window.location.href = "/";
  };

  //HomeHeader Data
  const handleHome = () => {
    if (cookies._secure_ARJ_) {
      nav("/dashboard");
    } else {
      nav("/");
    }
  };

  const handleLogin = () => {
    nav("/login");
  };
  const handleSignup = () => {
    nav("/signup");
  };
  const locations = useLocation();

  // useEffect(() => {
  //   const navLinks = document.querySelectorAll(".myjobs-link, .nav-link");
  //   console.log("All links", navLinks.length);
  
  //   const myJobsPath = [
  //     "/applied-jobs",
  //     "/preffered-jobs",
  //   ];
  
  //   navLinks.forEach((navLink) => {
  //     const href = navLink.getAttribute("href");
  
  //     if (
  //       href === locations.pathname ||
  //       myJobsPath.includes(locations.pathname)
  //     ) {
  //       console.log("Active link: ", href);
  //       console.log("Current pathname: ", locations.pathname);
  //       navLink.classList.add("active");
  //       window.scrollTo(0, 0); // Optionally scroll to top
  //     } else {
  //       navLink.classList.remove("active");
  //     }
  //   });
  
  //   setExpanded(false);
  // }, [locations]);

  // useEffect(() => {
  //   const navLinks = document.querySelectorAll(".nav-jobs, .nav-link, .nav-blogs");
  //   console.log("All links", navLinks);
  
  //   const myJobsPath = [
  //     "/applied-jobs",
  //     "/preffered-jobs",
  //     "/jobsearch/allsearchdata",
  //   ];
  //   const myBlogsBase = "blogs";
  //   console.log(locations.pathname,locations.pathname.includes(myBlogsBase),"111111111111111111")
  //   navLinks.forEach((navLink) => {
  //     const href = navLink.getAttribute("href");
  
  //     if (navLink.classList.contains("nav-jobs")) {
  //       // Handle nav-jobs specific logic
  //       if (myJobsPath.includes(locations.pathname)) {
  //         console.log("Active nav-link nav-jobs: ", href);
  //         navLink.classList.add("active");
  //         window.scrollTo(0, 0); // Optionally scroll to top
  //       } else {
  //         navLink.classList.remove("active");
  //       }
  //     } else if (navLink.classList.contains("nav-link")) {
  //       // Handle nav-link specific logic
  //       if (href === locations.pathname) {
  //         console.log("Active nav-link: ", href);
  //         navLink.classList.add("active");
  //         window.scrollTo(0, 0); // Optionally scroll to top
  //       } else {
  //         navLink.classList.remove("active");
  //       }
  //     } else if (navLink.classList.contains("nav-blogs")) {
  //       // Handle nav-blogs specific logic
  //       if (locations.pathname.includes(myBlogsBase)) {
  //         console.log("Active nav-blogs for blogs: ", locations.pathname);
  //         navLink.classList.add("active");
  //         window.scrollTo(0, 0); // Optionally scroll to top
  //       } else {
  //         navLink.classList.remove("active");
  //       }
  //     }
  //   });
  
  //   setExpanded(false);
  // }, [locations]);

  useEffect(() => {
    const navLinks = document.querySelectorAll(".nav-jobs, .nav-link, .nav-blogs");
    console.log("All links", navLinks);
  
    const myJobsPath = [
      "/applied-jobs",
      "/preffered-jobs",
      // "/jobsearch/allsearchdata",
    ];
    const myBlogsBase = "blogs";
    
    navLinks.forEach((navLink) => {
      const href = navLink.getAttribute("href");
  
      // Remove 'active' from all links initially to avoid conflicts
      navLink.classList.remove("active");
  
      if (navLink.classList.contains("nav-jobs")) {
        // Handle nav-jobs specific logic
        if (myJobsPath.includes(locations.pathname)) {
          console.log("Active nav-jobs: ", href);
          navLink.classList.add("active");
          window.scrollTo(0, 0); // Optionally scroll to top
        }
      } else if (navLink.classList.contains("nav-link")) {
        // Handle nav-link specific logic
        if (href === locations.pathname) {
          console.log("Active nav-link: ", href);
          navLink.classList.add("active");
          window.scrollTo(0, 0); // Optionally scroll to top
        }
      } else if (navLink.classList.contains("nav-blogs")) {
        // Handle nav-blogs specific logic
        if (locations.pathname.includes(myBlogsBase)) {
          console.log("Active nav-blogs: ", locations.pathname);
          navLink.classList.add("active");
          window.scrollTo(0, 0); // Optionally scroll to top
        }
      }
    });
  
    setExpanded(false);
  }, [locations]);
  
  
  
    

  // const fetchData = async () => {
  //   try {
  //     let cardUrl = "";
  //     if (cookies._secure_ARJ_) {
  //       cardUrl = `user/dashboard/page-no/1`;
  //     } else {
  //       cardUrl = `search/page-no/1`;
  //     }
  //     const response = await axios.post(
  //       cardUrl,
  //       { token: document.cookie, category: [] }
  //     );
  //     setSrc(response?.data?.message?.profile_path);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const [isHovered, setIsHovered] = React.useState(false);

  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const notificationApi = async () => {
    try {
      const response = await axios.get(`/user/notifications`, {
        headers: { authorization: document.cookie },
      });
      setNotifications(response?.data?.message?.data?.notifications);
      setNotificationCount(response.data.message.data.newNotifications);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (cookies._secure_ARJ_ || cookies._secure_ref) {
      notificationApi();
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "checkbox-labels-popover" : undefined;

  const productEngineers = [
    "Embedded Engineer",
    "Network Engineer",
    "Test Engineer",
  ];

  const digitalEngineering = [
    "Artificial Intelligence",
    "Cyber Security",
    "Data Analyst",
    "Data Engineer",
    // 'Machine Learning',
    // 'QA Engineer',
    // 'Security Engineer',
    // 'Full Stack Developer',
    // 'Program Manager'
  ];

  const management = ["Business Analyst", "Product Manager", "Project Manager"];

  const nonTech = ["Administrator", "HR", "Mechanical Engineer", "Technician"];

  const handleSearchMobile = () => {
    nav("/mobile-search");
  };

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    // padding: theme.spacing(2),
    // borderTop: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: `1px solid ${theme.palette.divider}`,
  }));
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    "&:before": {
      display: "none",
    },
  }));
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon />} {...props} />
  ))(({ theme }) => ({
    padding: 0,
    flexDirection: "row",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(180deg)",
      fontSize: "600",
    },
  }));

  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const isMediumScreen = useMediaQuery("(max-width: 1200px)");

  return (
    <div
      className="d-flex align-items-center sticky-top border-bottom bg-white"
      style={{
        boxShadow:
          window.location.pathname === "/jobsearch/allsearchdata" ||
          window.location.pathname === "/profile-section" ||
          window.location.pathname === "/view-jobs" ||
          window.location.pathname === "/applied-jobs"
            ? ""
            : "0px 4px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      {isSmallScreen && (
        <>
          <div className="container-lg">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid p-0">
                {/* <div className="d-flex justify-content-lg-between align-items-center navingBar ">
                  <div className="col-9">
                    <img
                      className="img-fluid"
                      onClick={handleHome}
                      width=""
                      src="/Logo.svg"
                      alt="Jobeze logo - Job search platform"
                      title="Jobeze - AI-Powered Job Search Platform"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div><NotificationsNoneOutlined/></div>
                  {window.location.pathname === "/" ? (
                    <></>
                  ) : (
                    <>
                      <span
                        onClick={handleSearchMobile}
                        className="SearchiconMobile  "
                        style={{ cursor: "pointer" }}
                      >
                        <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.56)" }} />
                      </span>
                    </>
                  )}

                  <div className="col-lg text-center">
                    <div
                      className="navbar-toggler border-0 p-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarText"
                      aria-controls="navbarText"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon border-0 "></span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="d-flex justify-content-lg-between align-items-center navingBar">
                <div className={cookies._secure_ARJ_ || cookies._secure_ref ? "col-9" : "col-11"}>
                    <img
                      className="img-fluid"
                      onClick={handleHome}
                      width=""
                      src="/Logo.svg"
                      alt="Jobeze logo - Job search platform"
                      title="Jobeze - AI-Powered Job Search Platform"
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                  {cookies._secure_ARJ_ || cookies._secure_ref ? (
                    <div>
                      <Tooltip title="Notifications" arrow>
                        <IconButton onClick={handleClick}>
                          <Badge color="error" badgeContent={notificationCount}>
                            <NotificationsNoneOutlined
                              sx={{ color: "rgba(0, 0, 0, 0.56)" }}
                            />
                          </Badge>
                        </IconButton>
                      </Tooltip>

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        sx={{ mt: 2 }}
                      >
                        <UserNotifications
                          notifications={notifications}
                          setNotifications={setNotifications}
                          setNotificationCount={setNotificationCount}
                        />
                      </Popover>
                    </div>
                  ) : null}

                  {window.location.pathname === "/" ? null : (
                    <span
                      onClick={handleSearchMobile}
                      className="SearchiconMobile"
                      style={{ cursor: "pointer" }}
                    >
                      <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.56)" }} />
                    </span>
                  )}

                  <div className="col-lg text-center">
                    <div
                      className="navbar-toggler border-0 p-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarText"
                      aria-controls="navbarText"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon border-0"></span>
                    </div>
                  </div>
                </div> */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <img
                      className="img-fluid"
                      onClick={handleHome}
                      width=""
                      src="/Logo.svg"
                      alt="Jobeze logo - Job search platform"
                      title="Jobeze - AI-Powered Job Search Platform"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Tooltip title="Notifications" arrow>
                      <IconButton onClick={handleClick}>
                        <Badge color="error" badgeContent={notificationCount}>
                          <NotificationsNoneOutlined
                            sx={{ color: "rgba(0, 0, 0, 0.56)" }}
                          />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      sx={{ mt: 2 }}
                    >
                      <UserNotifications
                      handleClose={handleClose}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        setNotificationCount={setNotificationCount}
                      />
                    </Popover>
                    {window.location.pathname === "/" ? (
                      <></>
                    ) : (
                      <div
                        onClick={handleSearchMobile}
                        className="SearchiconMobile  "
                        style={{ cursor: "pointer" }}
                      >
                        <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.56)" }} />
                      </div>
                    )}{" "}
                    <div
                      className="navbar-toggler border-0 p-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarText"
                      aria-controls="navbarText"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon border-0"></span>
                    </div>
                  </div>
                </div>

                {window.location.pathname.includes("/login") ||
                window.location.pathname.includes("/signup") ? (
                  ""
                ) : (
                  <div className="HeaderSearchbar">
                    <HeadersearchBar />
                  </div>
                )}

                <div
                  className="collapse navbar-collapse d-lg-flex justify-content-lg-end align-items-lg-center"
                  id="navbarText"
                >
                  <ul className="d-flex justify-content-end navbar-nav mb-lg-0 header1-block stroke fill gap-1">
                    <li className="nav-item">
                      <Link
                        style={{ color: "black" }}
                        className="nav-link "
                        to={
                          cookies._secure_ARJ_ || cookies._secure_ref
                            ? "/dashboard"
                            : "/"
                        }
                      >
                        {cookies._secure_ARJ_ || cookies._secure_ref
                          ? "Dashboard"
                          : "Home"}
                      </Link>
                    </li>
                    <li className="nav-item hoveringeffect">
                      <a style={{ color: "black" }} className="nav-link">
                        Jobs
                      </a>
                      {!(cookies._secure_ARJ_ || cookies._secure_ref) ? (
                        <ul
                          className={`submenu list-group rounded-3 border fx `}
                          style={{
                            marginLeft:
                              cookies._secure_ARJ_ || cookies._secure_ref
                                ? "-700px"
                                : "-600px",
                            fontSize: "14px",
                          }}
                        >
                          <div className="d-flex m-auto py-3 px-2">
                            <div className="px-2">
                              <div className="py-2 px-3">
                                <b>Product Engineering</b>
                              </div>
                              <div className="menubarjobHover">
                                <ul className="list-group colorscc">
                                  {productEngineers.map((e, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "400",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                            <div className=" px-2">
                              <div className="py-2 px-3">
                                <b>Digital Engineering</b>
                              </div>
                              <div className="menubarjobHover">
                                <ul className="list-group colorscc">
                                  {digitalEngineering.map((e, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "400",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="py-2 px-3">
                                <b>Management</b>
                              </div>
                              <div className="menubarjobHover">
                                <ul className="list-group colorscc">
                                  {management.map((e, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "400",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                            <div className=" px-2">
                              <div className="py-2 px-3">
                                <b>Non-Tech</b>
                              </div>
                              <div className="menubarjobHover">
                                <ul className="list-group colorscc">
                                  {nonTech.map((e, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "400",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </ul>
                      ) : (
                        <ul
                          className={`submenuLogin list-group rounded-0 border fx `}
                          style={{
                            marginLeft: "-25px",
                            fontSize: "14px",
                          }}
                        >
                          <div className="d-flex  m-auto p-2">
                            <div className="px-2 ">
                              <div className=" py-2 px-3">
                                <Link
                                  to="/applied-jobs"
                                  style={{
                                    textDecoration: "none",
                                    color: "unset",
                                  }}
                                >
                                  <b>Applications</b>
                                </Link>
                              </div>
                              <div className=" py-2 px-3">
                                <Link
                                  to="/preffered-jobs?job=recommendedJobs"
                                  style={{
                                    textDecoration: "none",
                                    color: "unset",
                                  }}
                                >
                                  <b>Recommended Jobs</b>
                                </Link>
                              </div>
                              <div className=" py-2 px-3">
                                <Link
                                  to="/preffered-jobs?job=savedJobs"
                                  style={{
                                    textDecoration: "none",
                                    color: "unset",
                                  }}
                                >
                                  <b>Saved Jobs</b>
                                </Link>
                              </div>
                              <div className=" py-2 px-3">
                                <Link
                                  to="/preffered-jobs?job=shortlistedJobs"
                                  style={{
                                    textDecoration: "none",
                                    color: "unset",
                                  }}
                                >
                                  <b>Shortlisted Jobs</b>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </ul>
                      )}
                    </li>
                    {/* Mobilemenu start */}
                    {cookies._secure_ARJ_ || cookies._secure_ref ? (
                      <>
                        <li className="nav-items jobmobilemenu py-2 ">
                          <Link
                            to="/applied-jobs"
                            style={{ textDecoration: "none", color: "unset" }}
                          >
                            <b>Applications</b>
                          </Link>
                        </li>
                        <li className="nav-items jobmobilemenu py-2 ">
                          <Link
                            to="/preffered-jobs?job=recommendedJobs"
                            style={{ textDecoration: "none", color: "unset" }}
                          >
                            <b>Recommended Jobs</b>
                          </Link>
                        </li>
                        <li className="nav-items jobmobilemenu py-2 ">
                          <Link
                            to="/preffered-jobs?job=savedJobs"
                            style={{ textDecoration: "none", color: "unset" }}
                          >
                            <b>Saved Jobs</b>
                          </Link>
                        </li>
                        <li className="nav-items jobmobilemenu py-2 ">
                          <Link
                            to="/preffered-jobs?job=shortlistedJobs"
                            style={{ textDecoration: "none", color: "unset" }}
                          >
                            <b>Shortlisted Jobs</b>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="nav-item jobmobilemenu ">
                          <a
                            style={{ color: "black" }}
                            className="nav-link p-0"
                          >
                            <Accordion
                              expanded={expanded === "panel1"}
                              onChange={handleChange("panel1")}
                            >
                              <AccordionSummary
                                className="p-0"
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography fontWeight="600">
                                  Product Engineering Jobs
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="p-0 ">
                                <Typography
                                  fontWeight="600"
                                  className="pt-2 border-top"
                                >
                                  {productEngineers.map((e, index) => {
                                    return (
                                      <li className="pb-3" key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "600",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </a>
                        </li>
                        <li className="nav-item jobmobilemenu ">
                          <a
                            style={{ color: "black" }}
                            className="nav-link p-0"
                          >
                            <Accordion
                              expanded={expanded === "panel2"}
                              onChange={handleChange("panel2")}
                            >
                              <AccordionSummary
                                className="p-0"
                                aria-controls="panel2d-content"
                                id="panel2d-header"
                              >
                                <Typography fontWeight="600">
                                  Digital Engineering Jobs
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="p-0 ">
                                <Typography
                                  fontWeight="600"
                                  className="pt-2 border-top"
                                >
                                  {digitalEngineering.map((e, index) => {
                                    return (
                                      <li className="pb-3" key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "600",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </a>
                        </li>
                        <li className="nav-item jobmobilemenu ">
                          <a
                            style={{ color: "black" }}
                            className="nav-link p-0"
                          >
                            <Accordion
                              expanded={expanded === "panel3"}
                              onChange={handleChange("panel3")}
                            >
                              <AccordionSummary
                                className="p-0"
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                              >
                                <Typography fontWeight="600">
                                  Management Jobs
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="p-0 ">
                                <Typography
                                  fontWeight="600"
                                  className="pt-2 border-top"
                                >
                                  {management.map((e, index) => {
                                    return (
                                      <li className="pb-3" key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "600",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </a>
                        </li>
                        <li className="nav-item jobmobilemenu ">
                          <a
                            style={{ color: "black" }}
                            className="nav-link p-0"
                          >
                            <Accordion
                              expanded={expanded === "panel4"}
                              onChange={handleChange("panel4")}
                            >
                              <AccordionSummary
                                className="p-0"
                                aria-controls="panel4d-content"
                                id="panel4d-header"
                              >
                                <Typography fontWeight="600">
                                  Non-Tech Jobs
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="p-0 ">
                                <Typography
                                  fontWeight="600"
                                  className="pt-2 border-top"
                                >
                                  {nonTech.map((e, index) => {
                                    return (
                                      <li className="pb-3" key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "600",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </a>
                        </li>
                      </>
                    )}

                    {/* end mobile menu */}

                    <li className="nav-item">
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to="/about-us"
                      >
                        About
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to="/blogs"
                      >
                        Blogs
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to="/contact-us"
                      >
                        Contact
                      </Link>
                    </li>
                    <li className="nav-item">
                      {cookies._secure_ARJ_ || cookies._secure_ref ? (
                        <>
                          <div className="profile-dropdown profilemenudisplay">
                            <div className="dropdown fx">
                              <div
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className="align-items-center d-flex justify-content-lg-end "
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                <div
                                  className="pe-0"
                                  style={{ width: "45px", height: "45px" }}
                                >
                                  {imageProfile ? (
                                    <img
                                      style={{ width: "100%", height: "100%" }}
                                      className="img-fluid rounded-circle"
                                      src={imageProfile}
                                      alt=""
                                    />
                                  ) : (
                                    <Avatar
                                      className="text-capitalize"
                                      sx={{ bgcolor: "#006CB7" }}
                                    >
                                      {
                                        localStorage
                                          .getItem("name")
                                          ?.split("")[0]
                                      }
                                    </Avatar>
                                  )}
                                </div>
                                <div className="px-2">
                                  <img
                                    className="img-fluid"
                                    src="/dropdown.png"
                                    alt="Dropdown menu for job search options"
                                    title="Explore Job Search Options"
                                  />
                                </div>
                              </div>
                              <ul
                                className="dropdown-menu showRight shadow-lg ul-dropdown"
                                style={{ zIndex: "2" }}
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    onClick={handleprofile}
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "600",
                                    }}
                                  >
                                    View Profile
                                  </a>
                                </li>
                                <li>
                                  <hr className="dropdown-divider" />
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item logouthover"
                                    onClick={handleLogout}
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Logout
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="py-2 mobileprofilemenudisplay">
                            <a
                              className="dropdown-item"
                              onClick={handleprofile}
                              style={{ cursor: "pointer", fontWeight: "600" }}
                            >
                              View Profile
                            </a>
                          </div>
                          <div className="py-2 col-4 mobileprofilemenudisplay">
                            <Button
                              variant="contained"
                              className="dropdown-item logouthover"
                              onClick={handleLogout}
                              style={{
                                cursor: "pointer",
                                fontWeight: "600",
                                color: "white",
                              }}
                            >
                              Logout
                            </Button>
                          </div>
                        </>
                      ) : (
                        <span className="">
                          <Button
                            className="col-lg me-2 btm1 fw-bold px-3"
                            onClick={handleLogin}
                            variant="outlined"
                          >
                            LOGIN
                          </Button>
                          <Button
                            className="col-lg btm2 fw-bold px-3 "
                            onClick={handleSignup}
                            variant="contained"
                          >
                            SIGN UP
                          </Button>
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </>
      )}

      {isMediumScreen && !isSmallScreen && (
        <>
          <div className="col-12">
            <nav className="navbar navbar-expand-xl navbar-light justify-content-between">
              <div className="w-100 d-flex mx-3 p-0">
                {/* <div className="col-12 d-flex justify-content-between align-items-center navingBar ">
                  <div className="col-10">
                    <img
                      className="img-fluid"
                      onClick={handleHome}
                      width=""
                      src="/Logo.svg"
                      alt="Jobeze logo - Job search platform"
                      title="Jobeze - AI-Powered Job Search Platform"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {cookies._secure_ARJ_ || cookies._secure_ref ? (
                    <div>
                      <Tooltip title="Notifications" arrow>
                        <IconButton onClick={handleClick}>
                          <Badge color="error" badgeContent={notificationCount}>
                            <NotificationsNoneOutlined
                              sx={{ color: "rgba(0, 0, 0, 0.56)" }}
                            />
                          </Badge>
                        </IconButton>
                      </Tooltip>

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        sx={{ mt: 2 }}
                      >
                        <UserNotifications
                          notifications={notifications}
                          setNotifications={setNotifications}
                          setNotificationCount={setNotificationCount}
                        />
                      </Popover>
                    </div>
                  ) : null}

                  {window.location.pathname === "/" ? (
                    <></>
                  ) : (
                    <>
                      <span
                        onClick={handleSearchMobile}
                        className="SearchiconMobile  "
                        style={{ cursor: "pointer" }}
                      >
                        <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.56)" }} />
                      </span>
                    </>
                  )}

                  <div className="col-1 text-center">
                    <div
                      className="navbar-toggler border-0 p-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarText"
                      aria-controls="navbarText"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon border-0 "></span>
                    </div>
                  </div>
                </div> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <img
                      className="img-fluid"
                      onClick={handleHome}
                      width=""
                      src="/Logo.svg"
                      alt="Jobeze logo - Job search platform"
                      title="Jobeze - AI-Powered Job Search Platform"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Tooltip title="Notifications" arrow>
                      <IconButton onClick={handleClick}>
                        <Badge color="error" badgeContent={notificationCount}>
                          <NotificationsNoneOutlined
                            sx={{ color: "rgba(0, 0, 0, 0.56)" }}
                          />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      sx={{ mt: 2 }}
                    >
                      <UserNotifications
                        handleClose={handleClose}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        setNotificationCount={setNotificationCount}
                      />
                    </Popover>
                    {window.location.pathname === "/" ? (
                      <></>
                    ) : (
                      <div
                        onClick={handleSearchMobile}
                        className="SearchiconMobile  "
                        style={{ cursor: "pointer" }}
                      >
                        <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.56)" }} />
                      </div>
                    )}{" "}
                    <div
                      className="navbar-toggler border-0 p-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarText"
                      aria-controls="navbarText"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon border-0"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse px-3 navbar-collapse " id="navbarText">
                <ul className="d-flex justify-content-end navbar-nav mb-lg-0 header1-block stroke fill gap-1">
                  <li className="nav-item">
                    <Link
                      style={{ color: "black" }}
                      className="nav-link "
                      to={
                        cookies._secure_ARJ_ || cookies._secure_ref
                          ? "/dashboard"
                          : "/"
                      }
                    >
                      {cookies._secure_ARJ_ || cookies._secure_ref
                        ? "Dashboard"
                        : "Home"}
                    </Link>
                  </li>
                  {cookies._secure_ARJ_ || cookies._secure_ref ? (
                    <>
                      <li className="nav-items py-2 ">
                        <Link
                          to="/applied-jobs"
                          style={{ textDecoration: "none", color: "unset" }}
                        >
                          <b>Applications</b>
                        </Link>
                      </li>
                      <li className="nav-items py-2 ">
                        <Link
                          to="/preffered-jobs?job=recommendedJobs"
                          style={{ textDecoration: "none", color: "unset" }}
                        >
                          <b>Recommended Jobs</b>
                        </Link>
                      </li>
                      <li className="nav-items py-2 ">
                        <Link
                          to="/preffered-jobs?job=savedJobs"
                          style={{ textDecoration: "none", color: "unset" }}
                        >
                          <b>Saved Jobs</b>
                        </Link>
                      </li>
                      <li className="nav-items py-2 ">
                        <Link
                          to="/preffered-jobs?job=shortlistedJobs"
                          style={{ textDecoration: "none", color: "unset" }}
                        >
                          <b>Shortlisted Jobs</b>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item ">
                        <a style={{ color: "black" }} className="nav-link p-0">
                          <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                          >
                            <AccordionSummary
                              className="p-0"
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography fontWeight="600">
                                Product Engineering Jobs
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="p-0 ">
                              <Typography
                                fontWeight="600"
                                className="pt-2 border-top"
                              >
                                {productEngineers.map((e, index) => {
                                  return (
                                    <li className="pb-3" key={index}>
                                      <Link
                                        to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                        style={{
                                          textDecoration: "none",
                                          color: "unset",
                                          fontWeight: "600",
                                          padding: "0",
                                          margin: "0",
                                        }}
                                      >
                                        {e}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </a>
                      </li>
                      <li className="nav-item ">
                        <a style={{ color: "black" }} className="nav-link p-0">
                          <Accordion
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2")}
                          >
                            <AccordionSummary
                              className="p-0"
                              aria-controls="panel2d-content"
                              id="panel2d-header"
                            >
                              <Typography fontWeight="600">
                                Digital Engineering Jobs
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="p-0 ">
                              <Typography
                                fontWeight="600"
                                className="pt-2 border-top"
                              >
                                {digitalEngineering.map((e, index) => {
                                  return (
                                    <li className="pb-3" key={index}>
                                      <Link
                                        to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                        style={{
                                          textDecoration: "none",
                                          color: "unset",
                                          fontWeight: "600",
                                          padding: "0",
                                          margin: "0",
                                        }}
                                      >
                                        {e}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </a>
                      </li>
                      <li className="nav-item ">
                        <a style={{ color: "black" }} className="nav-link p-0">
                          <Accordion
                            expanded={expanded === "panel3"}
                            onChange={handleChange("panel3")}
                          >
                            <AccordionSummary
                              className="p-0"
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                            >
                              <Typography fontWeight="600">
                                Management Jobs
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="p-0 ">
                              <Typography
                                fontWeight="600"
                                className="pt-2 border-top"
                              >
                                {management.map((e, index) => {
                                  return (
                                    <li className="pb-3" key={index}>
                                      <Link
                                        to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                        style={{
                                          textDecoration: "none",
                                          color: "unset",
                                          fontWeight: "600",
                                          padding: "0",
                                          margin: "0",
                                        }}
                                      >
                                        {e}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </a>
                      </li>
                      <li className="nav-item ">
                        <a style={{ color: "black" }} className="nav-link p-0">
                          <Accordion
                            expanded={expanded === "panel4"}
                            onChange={handleChange("panel4")}
                          >
                            <AccordionSummary
                              className="p-0"
                              aria-controls="panel4d-content"
                              id="panel4d-header"
                            >
                              <Typography fontWeight="600">
                                Non-Tech Jobs
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="p-0 ">
                              <Typography
                                fontWeight="600"
                                className="pt-2 border-top"
                              >
                                {nonTech.map((e, index) => {
                                  return (
                                    <li className="pb-3" key={index}>
                                      <Link
                                        to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                        style={{
                                          textDecoration: "none",
                                          color: "unset",
                                          fontWeight: "600",
                                          padding: "0",
                                          margin: "0",
                                        }}
                                      >
                                        {e}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </a>
                      </li>
                    </>
                  )}

                  {/* end mobile menu */}

                  <li className="nav-item">
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to="/about-us"
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to="/blogs"
                    >
                      Blogs
                    </Link>
                  </li>
                  <li className="nav-item me-3">
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to="/contact-us"
                    >
                      Contact
                    </Link>
                  </li>
                  <li className="nav-item">
                    {cookies._secure_ARJ_ || cookies._secure_ref ? (
                      <>
                        <div className="profile-dropdown profilemenudisplay">
                          <div className="fx">
                            <ul
                              className="dropdown-menu showRight shadow-lg ul-dropdown"
                              style={{ zIndex: "2" }}
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={handleprofile}
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "600",
                                  }}
                                >
                                  View Profile
                                </a>
                              </li>
                              <li>
                                <hr className="dropdown-divider" />
                              </li>
                              <li>
                                <a
                                  className="dropdown-item logouthover"
                                  onClick={handleLogout}
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "600",
                                  }}
                                >
                                  Logout
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="py-2">
                          <a
                            className="dropdown-item"
                            onClick={handleprofile}
                            style={{ cursor: "pointer", fontWeight: "600" }}
                          >
                            View Profile
                          </a>
                        </div>
                        <div className="py-2 col-2">
                          <Button
                            variant="contained"
                            className="dropdown-item logouthover"
                            onClick={handleLogout}
                            style={{
                              cursor: "pointer",
                              fontWeight: "600",
                              color: "white",
                            }}
                          >
                            Logout
                          </Button>
                        </div>
                      </>
                    ) : (
                      <span className="">
                        <Button
                          className="col-lg me-2 btm1 fw-bold px-3"
                          onClick={handleLogin}
                          variant="outlined"
                        >
                          LOGIN
                        </Button>
                        <Button
                          className="col-lg btm2 fw-bold px-3 "
                          onClick={handleSignup}
                          variant="contained"
                        >
                          SIGN UP
                        </Button>
                      </span>
                    )}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </>
      )}
      {!isMediumScreen && !isSmallScreen && (
        <>
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid p-0">
                <div className="d-flex justify-content-lg-between align-items-center navingBar ">
                  <div className="col-11">
                    <img
                      className="img-fluid"
                      onClick={handleHome}
                      width=""
                      src="/Logo.svg"
                      alt="Jobeze"
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                  {window.location.pathname === "/" ? (
                    <></>
                  ) : (
                    <>
                      <span
                        onClick={handleSearchMobile}
                        className="SearchiconMobile  "
                        style={{ cursor: "pointer" }}
                      >
                        <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.56)" }} />
                      </span>
                    </>
                  )}

                  <div className="col-lg text-md-end">
                    <div
                      className="navbar-toggler border-0 p-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarText"
                      aria-controls="navbarText"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon border-0 "></span>
                    </div>
                  </div>
                </div>

                {window.location.pathname.includes("/login") ||
                window.location.pathname.includes("/signup") ? (
                  ""
                ) : (
                  <div className="HeaderSearchbar">
                    <HeadersearchBar />
                  </div>
                )}

                <div
                  className="collapse navbar-collapse d-lg-flex "
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                  // id="navbarText"
                >
                  <ul className="d-flex justify-content-end navbar-nav mb-lg-0 header1-block stroke fill gap-1">
                    <li className="nav-item">
                      <Link
                        style={{ color: "black" }}
                        className="nav-link "
                        to={
                          cookies._secure_ARJ_ || cookies._secure_ref
                            ? "/dashboard"
                            : "/"
                        }
                      >
                        {cookies._secure_ARJ_ || cookies._secure_ref
                          ? "Dashboard"
                          : "Home"}
                      </Link>
                    </li>
                    <li className="nav-item hoveringeffect">
                      <a style={{ color: "black", padding:'8px' }} className=" nav-jobs" >
                        {cookies._secure_ARJ_ || cookies._secure_ref
                          ? "My Jobs"
                          : "Jobs"}
                      </a>
                      {!(cookies._secure_ARJ_ || cookies._secure_ref) ? (
                        <ul
                          className={`submenu list-group rounded-3 border fx `}
                          style={{
                            marginLeft:
                              cookies._secure_ARJ_ || cookies._secure_ref
                                ? "-700px"
                                : "-600px",
                            fontSize: "14px",
                          }}
                        >
                          <div className="d-flex m-auto py-3 px-2">
                            <div className="px-2">
                              <div className="py-2 px-3">
                                <b>Product Engineering</b>
                              </div>
                              <div className="menubarjobHover">
                                <ul className="list-group colorscc">
                                  {productEngineers.map((e, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "400",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                            <div className=" px-2">
                              <div className="py-2 px-3">
                                <b>Digital Engineering</b>
                              </div>
                              <div className="menubarjobHover">
                                <ul className="list-group colorscc">
                                  {digitalEngineering.map((e, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "400",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="py-2 px-3">
                                <b>Management</b>
                              </div>
                              <div className="menubarjobHover">
                                <ul className="list-group colorscc">
                                  {management.map((e, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "400",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                            <div className=" px-2">
                              <div className="py-2 px-3">
                                <b>Non-Tech</b>
                              </div>
                              <div className="menubarjobHover">
                                <ul className="list-group colorscc">
                                  {nonTech.map((e, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "400",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </ul>
                      ) : (
                        <ul
                          className={`submenuLogin list-group rounded-0 border fx `}
                          style={{
                            marginLeft: "-25px",
                            fontSize: "14px",
                          }}
                        >
                          <div className="d-flex m-auto">
                            <div className="">
                              <div className=" py-2 px-3">
                                <Link
                                  to="/applied-jobs"
                                  style={{
                                    textDecoration: "none",
                                    color: "unset",
                                  }}
                                >
                                  <b>Applications</b>
                                </Link>
                              </div>
                              <div className=" py-2 px-3">
                                <Link
                                  to={`/preffered-jobs?job=recommendedJobs`}
                                  style={{
                                    textDecoration: "none",
                                    color: "unset",
                                  }}
                                >
                                  <b>Recommended Jobs</b>
                                </Link>
                              </div>
                              <div className=" py-2 px-3">
                                <Link
                                  to={`/preffered-jobs?job=savedJobs`}
                                  style={{
                                    textDecoration: "none",
                                    color: "unset",
                                  }}
                                >
                                  <b>Saved Jobs</b>
                                </Link>
                              </div>
                              <div className=" py-2 px-3">
                                <Link
                                  to={`/preffered-jobs?job=shortlistedJobs`}
                                  style={{
                                    textDecoration: "none",
                                    color: "unset",
                                  }}
                                >
                                  <b>Shortlisted Jobs</b>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </ul>
                      )}
                    </li>
                    {/* Mobilemenu start */}
                    {cookies._secure_ARJ_ || cookies._secure_ref ? (
                      <>
                        <li className="nav-items jobmobilemenu py-2 ">
                          <Link
                            to="/applied-jobs"
                            style={{ textDecoration: "none", color: "unset" }}
                          >
                            <b>Applications</b>
                          </Link>
                        </li>
                        <li className="nav-items jobmobilemenu py-2 ">
                          <Link
                            to="/preffered-jobs?job=recommendedJobs"
                            style={{ textDecoration: "none", color: "unset" }}
                          >
                            <b>Recommended Jobs</b>
                          </Link>
                        </li>
                        <li className="nav-items jobmobilemenu py-2 ">
                          <Link
                            to="/preffered-jobs?job=savedJobs"
                            style={{ textDecoration: "none", color: "unset" }}
                          >
                            <b>Saved Jobs</b>
                          </Link>
                        </li>
                        <li className="nav-items jobmobilemenu py-2 ">
                          <Link
                            to="/preffered-jobs?job=shortlistedJobs"
                            style={{ textDecoration: "none", color: "unset" }}
                          >
                            <b>Shortlisted Jobs</b>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="nav-item jobmobilemenu ">
                          <a
                            style={{ color: "black" }}
                            className="nav-link p-0"
                          >
                            <Accordion
                              expanded={expanded === "panel1"}
                              onChange={handleChange("panel1")}
                            >
                              <AccordionSummary
                                className="p-0"
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography fontWeight="600">
                                  Product Engineering Jobs
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="p-0 ">
                                <Typography
                                  fontWeight="600"
                                  className="pt-2 border-top"
                                >
                                  {productEngineers.map((e, index) => {
                                    return (
                                      <li className="pb-3" key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "600",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </a>
                        </li>
                        <li className="nav-item jobmobilemenu ">
                          <a
                            style={{ color: "black" }}
                            className="nav-link p-0"
                          >
                            <Accordion
                              expanded={expanded === "panel2"}
                              onChange={handleChange("panel2")}
                            >
                              <AccordionSummary
                                className="p-0"
                                aria-controls="panel2d-content"
                                id="panel2d-header"
                              >
                                <Typography fontWeight="600">
                                  Digital Engineering Jobs
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="p-0 ">
                                <Typography
                                  fontWeight="600"
                                  className="pt-2 border-top"
                                >
                                  {digitalEngineering.map((e, index) => {
                                    return (
                                      <li className="pb-3" key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "600",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </a>
                        </li>
                        <li className="nav-item jobmobilemenu ">
                          <a
                            style={{ color: "black" }}
                            className="nav-link p-0"
                          >
                            <Accordion
                              expanded={expanded === "panel3"}
                              onChange={handleChange("panel3")}
                            >
                              <AccordionSummary
                                className="p-0"
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                              >
                                <Typography fontWeight="600">
                                  Management Jobs
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="p-0 ">
                                <Typography
                                  fontWeight="600"
                                  className="pt-2 border-top"
                                >
                                  {management.map((e, index) => {
                                    return (
                                      <li className="pb-3" key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "600",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </a>
                        </li>
                        <li className="nav-item jobmobilemenu ">
                          <a
                            style={{ color: "black" }}
                            className="nav-link p-0"
                          >
                            <Accordion
                              expanded={expanded === "panel4"}
                              onChange={handleChange("panel4")}
                            >
                              <AccordionSummary
                                className="p-0"
                                aria-controls="panel4d-content"
                                id="panel4d-header"
                              >
                                <Typography fontWeight="600">
                                  Non-Tech Jobs
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="p-0 ">
                                <Typography
                                  fontWeight="600"
                                  className="pt-2 border-top"
                                >
                                  {nonTech.map((e, index) => {
                                    return (
                                      <li className="pb-3" key={index}>
                                        <Link
                                          to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "unset",
                                            fontWeight: "600",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          {e}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </a>
                        </li>
                      </>
                    )}

                    {/* end mobile menu */}

                    <li className="nav-item">
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to="/about-us"
                      >
                        About
                      </Link>
                    </li>
                    <li className="nav-item" style={{padding:'8px'}}>
                      <Link
                        style={{ color: "black", textDecoration:'none'}}
                        className=".nav-link nav-blogs"
                        to="/blogs"
                      >
                        Blogs
                      </Link>
                    </li>
                    <li className="nav-item me-3">
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to="/contact-us"
                      >
                        Contact
                      </Link>
                    </li>
                    {cookies._secure_ARJ_ || cookies._secure_ref ? (
                      <li>
                        <Tooltip title="Notifications" arrow>
                          <IconButton onClick={handleClick}>
                            <Badge
                              color="error"
                              badgeContent={notificationCount}
                            >
                              <NotificationsNoneOutlined
                                sx={{ color: "black" }}
                              />
                            </Badge>
                          </IconButton>
                        </Tooltip>

                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          sx={{ mt: 1 }}
                        >
                          <UserNotifications
                            handleClose={handleClose}
                            notifications={notifications}
                            setNotifications={setNotifications}
                            setNotificationCount={setNotificationCount}
                          />
                        </Popover>
                      </li>
                    ) : (
                      <></>
                    )}

                    <li className="nav-item">
                      {cookies._secure_ARJ_ || cookies._secure_ref ? (
                        <>
                          <div className="profile-dropdown profilemenudisplay">
                            <div className="dropdown fx">
                              <div
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className="align-items-center d-flex justify-content-lg-end "
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                <div
                                  className="pe-0"
                                  style={{ width: "45px", height: "45px" }}
                                >
                                  {imageProfile ? (
                                    <img
                                      style={{ width: "100%", height: "100%" }}
                                      className="img-fluid rounded-circle"
                                      src={imageProfile}
                                      alt=""
                                    />
                                  ) : (
                                    <Avatar
                                      className="text-capitalize"
                                      sx={{ bgcolor: "#006CB7" }}
                                    >
                                      {
                                        localStorage
                                          .getItem("name")
                                          ?.split("")[0]
                                      }
                                    </Avatar>
                                  )}
                                </div>
                                <div className="px-2">
                                  <img
                                    className="img-fluid"
                                    src="/dropdown.png"
                                    alt="Dropdown menu for job search options"
                                    title="Explore Job Search Options"
                                  />
                                </div>
                              </div>
                              <ul
                                className="dropdown-menu showRight shadow-lg ul-dropdown"
                                style={{ zIndex: "2" }}
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    onClick={handleprofile}
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "600",
                                    }}
                                  >
                                    View Profile
                                  </a>
                                </li>
                                <li>
                                  <hr className="dropdown-divider" />
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item logouthover"
                                    onClick={handleLogout}
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Logout
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="py-2 mobileprofilemenudisplay">
                            <a
                              className="dropdown-item"
                              onClick={handleprofile}
                              style={{ cursor: "pointer", fontWeight: "600" }}
                            >
                              View Profile
                            </a>
                          </div>
                          <div className="py-2 col-4 mobileprofilemenudisplay">
                            <Button
                              variant="contained"
                              className="dropdown-item logouthover"
                              onClick={handleLogout}
                              style={{
                                cursor: "pointer",
                                fontWeight: "600",
                                color: "white",
                              }}
                            >
                              Logout
                            </Button>
                          </div>
                        </>
                      ) : (
                        <span className="">
                          <Button
                            className="col-lg me-2 btm1 fw-bold px-3"
                            onClick={handleLogin}
                            variant="outlined"
                          >
                            LOGIN
                          </Button>
                          <Button
                            className="col-lg btm2 fw-bold px-3 "
                            onClick={handleSignup}
                            variant="contained"
                          >
                            SIGN UP
                          </Button>
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </>
      )}
    </div>
  );
}

export default Homeheader;
