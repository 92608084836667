import React, { useEffect, useRef, useState } from "react";
import moment from "moment/moment.js";
import Button from "@mui/material/Button";
import axios from "axios";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { useNavigate } from "react-router-dom";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import FormPopup from "./formPopup";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "@mui/material";
import Html from "../js/Html";
import ResendBackdrop from "../ResendBackdrop";
import Shareable from "./Shareable";
import LaunchIcon from "@mui/icons-material/Launch";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Checkbox from "@mui/material/Checkbox";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useCookies } from "react-cookie";
import {
  getCredits,
  isResumeVerified,
  setToken,
} from "../redux/modules/userSlice";
import { useAppDispatch } from "../redux/hooks";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import GenerateCVPopup from "./generateCVPopup";
import { useSelector } from "react-redux";
import QuestionPopup from "./questionPopup";
import ShareIcon from "@mui/icons-material/Share";
// import MenuItem from '@mui/material/MenuItem';
// import Menu from '@mui/material/Menu';
// import Box from '@mui/material/Box';
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import "./jobApply.css";

function JobApply(props) {
  // console.log("42", props);
  const ScrollRef = useRef();
  const dispatch = useAppDispatch();
  const isResume = useSelector((state) => state.user.isResumeVerified);
  const isVerifiedUser = useSelector((state) => state.user.isVerified);
  const nav = useNavigate();
  // const [applyJob, setApplyJob] = useState(true);
  // const [getDate, setgetDate] = useState('');
  const [openForm, setOpenForm] = useState(false); //Apply as guest open form
  const [files, setFiles] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [emailexceed, setEmailExceeded] = useState(false);
  const [applyLoading, setapplyLoading] = useState(false);
  const [save, setsave] = useState(false);
  const [open, setOpen] = useState(false);
  const [shareurl, setShareurl] = useState("");

  const [isShortlistStatus, setIsShortlistStatus] = useState(true);

  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const [cookies, setCookie] = useCookies([]);
  const [quesAnsArray, setQuesAnsArray] = useState([]);

  const isCLRequired = props?.data?.cover_letter_required;
  const isQuestionnaire = props?.data?.questionnaire_required;

  useEffect(() => {
    setsave(props?.data?.isSaved);
  }, [props?.data?.isSaved]);

  const handleOpenForm = () => {
    // if (openForm) {
    //   console.log("openForm", openForm);
    //   setOpenForm(false);
    // } else {
    //   setOpenForm(true);
    // }
    setOpenForm(!openForm);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleQuickApply = async (e) => {
    // let response;
    if (isResume === 1) {
      try {
        if (props.data.redirect_link) {
          window.open(props.data.redirect_link, "_blank");
          return;
        }
        const newJobid =
          props.data.joborder_id > 0
            ? props.data.joborder_id
            : -props.data.joborder_id;

        const QuestArr = quesAnsArray.map((quesAns) => {
          delete quesAns.ques_ans;
          delete quesAns.error;
          delete quesAns.question_types;
          return quesAns;
        });

        // console.log(QuestArr);
        const response = await axios.post(`user/applied-quick/job`, {
          token: document.cookie,
          job_id: newJobid,
          cover_letter:
            HtmlContent === "<p><br></p>" || HtmlContent === ""
              ? null
              : HtmlContent,
          additional_info: additionInfoData ? additionInfoData : null,
          quesAnsArray: isQuestionnaire === 1 ? [...QuestArr] : [],
          original_date_modified: props.data.date_modified,
        });

        // if(response.data.)

        if (response.data.status === 200) {
          props.applied(props?.data?.joborder_id);
          setOpenCV(false);
          setOpenQuestionpopup(false);
          toast.success("Job Applied Successfully", {
            hideProgressBar: true,
            closeOnClick: true,
          });
          setHtmlContent("");
          setadditionInfoData("");
          setIsShortlistStatus(false);
        }
      } catch (err) {
        console.log(err, "errorrr");
        if (err?.response?.data?.error === "Please upload resume") {
          setOpenCV(false);
          nav("/upload-page");
        }
        toast.error(err.response.data.error, {
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } else {
      nav("/upload-page");
      toast.error("Please upload resume", {
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
    // };
  };

  //Apply for job as guest function
  const handleSubmit = async (e) => {
    setapplyLoading(true);
    if (!name) {
      e.preventDefault();
      setErrorName(true);
      return;
    }
    if (!regex.test(email)) {
      e.preventDefault();
      setOpenForm(true);
      setErrorEmail(true);
      return;
    }
    if (email.length > 60) {
      e.preventDefault();
      setOpenForm(true);
      setEmailExceeded(true);
      return;
    }
    if (!files[0]) {
      e.preventDefault();
      setErrorFile(true);
      return;
    } else {
      e.preventDefault();
      setErrorName(false);
      setErrorEmail(false);
      setEmailExceeded(false);
      setErrorFile(false);
      let formData = new FormData();

      // if (files[0]) {
      formData.append("resume", files[0]);
      // }
      formData.append("email", email.toLowerCase());
      formData.append("name", name);
      formData.append("jobOrder_id", props.data.joborder_id);
      formData.append(
        "isExternalString",
        props.data.redirect_link ? true : false
      );
      formData.append("quesAnsArray", JSON.stringify([]));
      formData.append("original_date_modified", props.data.date_modified);
      if (
        files[0]?.type === "text/plain" ||
        files[0]?.type === "application/pdf" ||
        // files[0]?.type === "application/msword" ||
        files[0]?.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const { data } = await axios.post(`applyjoborder`, formData, {
          withCredentials: true,
        });
        if (data) {
          setapplyLoading(false);
          setOpenForm(false);
        }
        if (data.message.alert === "New Candidate") {
          localStorage.setItem("id", data.message.id);
          dispatch(setToken(data.message.token));
          dispatch(isResumeVerified(1));
          props.setVerified(true);
          const d = new Date();
          d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
          // d.setTime(d.getTime() + 1 * 60 * 60 * 1000);
          let expires = "expires=" + d.toUTCString();
          document.cookie =
            "_secure_ARJ_" +
            "=" +
            data.message.token +
            ";" +
            expires +
            ";path=/";

          if (props?.data?.redirect_link) {
            window.open(props?.data?.redirect_link, "_blank");
            // window.location.href = `/jobsearch/allsearchdata?${props?.searchParams}`;
            nav("/dashboard");
            toast.success("Login Successfully");
            return;
          } else {
            nav("/dashboard");
            props.applied(props?.data?.joborder_id);
            toast.success("Applied Successfully");
          }
        } else if (data.message.alert === "old candidate") {
          if (data.message.verify === true) {
            setFiles([]);
            toast.error("User Already Exist!", {
              hideProgressBar: true,
              closeOnClick: true,
            });
            nav("/login");
          }
          if (data.message.verify === false) {
            setFiles([]);
            toast.error("Please Verify Your Account!", {
              hideProgressBar: true,
              closeOnClick: true,
            });
            props.setVerified(false);
            nav("/login");
          }
        } else if (data?.status === 400) {
          toast.error("Internal Server Error", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      } else {
        setOpenForm(true);
        toast.error("Please upload resume in Pdf, Txt or Docx format only", {
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    }
  };

  const handleName = (e) => {
    const numericRegex = /^[a-zA-Z\s]*$/;
    if (numericRegex.test(e.target.value)) {
      if (e.target.value.length < 60) {
        setName(e.target.value);
      }
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value;
    // if (value.length <= 60) {
    setEmail(value);
    // }
  };

  const { getRootProps, getInputProps } = useDropzone({
    acceptedFiles: [
      "application/pdf",
      "text/plain",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    rejectedFiles: ["image/*", "video/*"],
    accept:
      "application/pdf, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    maxFiles: 1,
    maxSize: 6000000,
    onDrop: (acceptedFiles, rejectedFiles, maxSize) => {
      if (
        acceptedFiles[0]?.size <= 6000000 &&
        (acceptedFiles[0]?.type === "application/pdf" ||
          acceptedFiles[0]?.type === "text/plain" ||
          acceptedFiles[0]?.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      ) {
        toast.success(" Uploaded Successfully ", {
          hideProgressBar: true,
          closeOnClick: true,
        });
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        dispatch(isResumeVerified(1));
      } else if (rejectedFiles.length > 0) {
        toast.error("File size exceeds the limit of 6mb", {
          hideProgressBar: true,
          closeOnClick: true,
        });
        // return;
      }
      // if (acceptedFiles[0]?.type === "application/pdf" || acceptedFiles[0]?.type === "text/plain" || acceptedFiles[0]?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      //   setFiles(
      //     acceptedFiles.map((file) =>
      //       Object.assign(file, {
      //         preview: URL.createObjectURL(file),
      //       })
      //     )
      //   );
      //
      // }
      else {
        toast.error("Please upload resume in .pdf, .txt or .docx format only", {
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    },
  });

  const handleOnResumeChange = (e) => {
    setFiles(e.target.files);
  };

  const images = files.map((file) => (
    <div key={file.name}>
      <div>
        <img src={file.preview} style={{ width: "200px" }} alt="preview" />
      </div>
    </div>
  ));

  const beforeDate = new Date(props?.data?.date_created);
  const today = new Date();
  const diffTime = Math.abs(today - beforeDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // var postedMessages = "";
  // if (diffDays === 1) {
  //   postedMessages = "Posted 1 day ago";
  // }
  // else if (diffDays > 1 && diffDays <= 30) {
  //   postedMessages = "Posted " + diffDays + " days ago";
  // }
  // else if (diffDays > 30) {
  //   postedMessages = "Re-posted " + (diffDays % 30) + (diffDays % 30 === 1 ? " day ago" : " days ago");
  // }
  // else {
  //   postedMessages = "Posted " + diffDays + " days ago";
  // }

  var postedMessages = "";
  if (diffDays === 1) {
    postedMessages = "Posted 1 day ago";
  } else if (diffDays === 0) {
    postedMessages = "Posted today";
  } else if (diffDays > 1 && diffDays <= 30) {
    postedMessages = "Posted " + diffDays + " days ago";
  } else if (diffDays > 30) {
    if (diffDays % 30 === 0) {
      postedMessages = "Posted today";
    } else {
      postedMessages =
        "Re-posted " +
        (diffDays % 30) +
        (diffDays % 30 === 1 ? " day ago" : " days ago");
    }
  } else {
    postedMessages = "Posted " + diffDays + " days ago";
  }

  const handleViewFullpage = (e) => {
    const shareUrl = process.env.REACT_APP_FRONTEND_URL;
    const url = `${shareUrl}/jobDetail/${props.data.joborder_id}`;
    navigator.clipboard.writeText(url);
    toast.success("Link is copied successfully", {
      hideProgressBar: true,
      closeOnClick: true,
    });

    setOpen(false);
    setShareurl(url);
    // setAnchorEl(e.currentTarget);
    // window.open(url, "_blank");
  };
  function SharableUrl() {
    const shareUrl = process.env.REACT_APP_FRONTEND_URL;
    const url = `${shareUrl}/jobDetail/${props.data.joborder_id}`;
    setShareurl(url);
  }

  switch (props?.data?.type) {
    case "C":
      props.data.text = "Contract";
      break;
    case "H":
      props.data.text = "Hire";
      break;
    case "C2H":
      props.data.text = "Contract to Hire";
      break;
    case "F":
      props.data.text = "Freelance";
      break;
    default:
      break;
  }
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const isMediumScreen = useMediaQuery("(max-width: 1200px)");

  const handleBack = () => {
    // nav("/jobsearch/allsearchdata");
    nav(-1);
    window.scrollTo(0, 0);
  };

  const handleSave = async () => {
    if (!(cookies._secure_ARJ_ || cookies._secure_ref)) {
      setsave(false);
      toast.warning("Please login to save", {
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    } else if (cookies._secure_ARJ_ || cookies._secure_ref) {
      setsave(!save);

      if (save === false) {
        const response = await axios.post(`user/save-job`, {
          job_id: props.data.joborder_id,
          token: document.cookie,
        });

        toast.success("Job saved Successfully", {
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
      if (save === true) {
        const response = await axios.post(`user/unsave-job`, {
          job_id: props.data.joborder_id,
          token: document.cookie,
        });
        toast.warning("Job unsaved successfully", {
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    }
  };

  useEffect(() => {
    if (ScrollRef.current) {
      ScrollRef.current.scrollTop = 0;
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [props?.data]);

  // generate cv popup functions here
  const [openCV, setOpenCV] = useState(false);
  const [credits, setCredits] = useState(
    useSelector((state) => state.user.credits)
  );
  const [loading, setLoading] = useState(false);
  const [HtmlContent, setHtmlContent] = useState("");
  const [additionInfoData, setadditionInfoData] = useState("");
  const [exceededLimitAdditionalInfo, setExceededLimitAdditionalInfo] =
    useState(false);

  const handleCVPopupOpen = () => {
    if (isVerifiedUser === 1) {
      if (isResume === 1) {
        if (isQuestionnaire === 1) {
          setOpenQuestionpopup(true);
        }
        // else if(isCLRequired === 1){
        //   setOpenCV(true);
        // }
        else {
          setOpenCV(true);
        }
      } else {
        nav("/upload-page");
        toast.error("Please upload your resume");
      }
    } else {
      toast.error("Please verify your email");
    }
  };

  // quesAnsArray.map((quesAns) => {
  //     delete quesAns.ques_ans
  // })

  const handleCVPopupClose = () => {
    setHtmlContent("");
    setadditionInfoData("");
    setExceededLimitAdditionalInfo(false);
    setOpenCV(false);
    setOpenQuestionpopup(false);
    setTextFields({});
    setRadioValues({});
    const a = quesAnsArray.map((question) => {
      return {
        question_types: question.question_types,
        question_id: question.question_id,
        ques_ans: question.ques_ans,
        answer_text: "",
        // answer_id:null,
        error: null,
      };
    });
    setQuesAnsArray(a);
  };

  const handleCVHtmlChange = (value) => {
    setHtmlContent(value);
  };

  const handleAdditionInfoChange = (e) => {
    setadditionInfoData(e.target.value);
  };

  const handleGenerateCV = async () => {
    setLoading(true);
    // Simulate an asynchronous operation (e.g., API call)
    try {
      // Deduct credits
      const response = await axios.post("generate-cover-letter", {
        token: document.cookie,
        jdid: props.data.joborder_id,
      });
      setHtmlContent(response.data.message.data);
      setCredits(response.data.message.credits);
      dispatch(getCredits(response.data.message.credits));
      // toast.success("Cover Letter Generated Successfully");
      await someAsyncFunction();
    } catch (error) {
      console.error("Error generating CV:", error);
      toast.error("Error generating Cover Letter");
    } finally {
      setLoading(false);
    }
  };

  const someAsyncFunction = () => {
    // Replace this with your actual asynchronous logic
    return new Promise((resolve) => {
      setTimeout(resolve, 2000); // Simulating a 2-second delay
    });
  };

  // Open Question Popup function here
  const [openQuestionpopup, setOpenQuestionpopup] = useState(false);
  const [question, setQuestion] = useState([]);

  const [textFields, setTextFields] = useState({});
  const [radioValues, setRadioValues] = useState({});

  const [quesErrorHandler, setQuesErrorHandler] = useState({});
  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleClickOpenQuestionPopup = () => {
    setOpenQuestionpopup(true);
  };
  const handleClickCloseQuestionPopup = () => {
    setTextFields({});
    setRadioValues({});
    setOpenQuestionpopup(false);
    setOpenForm(false);
    setName();
    setEmail();
    setFiles([]);
    const a = quesAnsArray.map((question) => {
      return {
        question_types: question.question_types,
        question_id: question.question_id,
        ques_ans: question.ques_ans,
        answer_text: "",
        // answer_id:null,
        error: null,
      };
    });
    setQuesAnsArray(a);
  };

  const handleRadioChange = (e, id, index) => {
    let tempArr = [...quesAnsArray];
    //console.log(e)
    const { value } = e.target;
    setRadioValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    let i = quesAnsArray[index].ques_ans.findIndex((e) => e.value === value);

    tempArr[index].answer_text = value;
    tempArr[index].answer_id = tempArr[index].ques_ans[i].answer_id;
    tempArr[index].error = null;
    setQuesAnsArray(tempArr);

    // console.log("5222225 radiovalues value", radioValues);
  };
  const temp = (value, type) => {
    if (type === "tel") {
      if (value.trim().length === 0) {
        return "This field is required";
      } else if (value.length >= 13) {
        return "Maximum 12 digits allowed";
      } else {
        return null;
      }
    } else if (type === "textarea") {
      if (value.trim().length === 0) {
        return "This field is required";
      } else if (value.length >= 501) {
        return "Maximum 500 characters allowed";
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const handleTextFieldChange = (e, id, index) => {
    const { value, type } = e.target;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    let tempArr = [...quesAnsArray];
    tempArr[index].answer_text = capitalizedValue;
    tempArr[index].answer_id = tempArr[index].ques_ans[0].answer_id;
    tempArr[index].error = temp(capitalizedValue, type);
    setQuesAnsArray(tempArr);
    setTextFields((prevState) => ({
      ...prevState,
      [id]: capitalizedValue,
    }));
  };

  const validate = () => {
    let isValid = true;
    let updateQuesArr = [...quesAnsArray];
    updateQuesArr.forEach((item, index) => {
      if (item.question_types === 1) {
        if (item.answer_text.trim() === "") {
          item.error = "This field is required";
          isValid = false;
        } else if (item.answer_text.length >= 501) {
          item.error = "Maximum 500 characters allowed";
          isValid = false;
        } else {
          item.error = null;
        }
      } else if (item.question_types === 2) {
        if (item.answer_text === "") {
          item.error = "This field is required";
          isValid = false;
        } else if (item.answer_text.length >= 13) {
          item.error = "Maximum 12 digits allowed";
          isValid = false;
        } else {
          item.error = null;
        }
      } else if (item.question_types === 3) {
        if (item.answer_text === "") {
          item.error = "This field is required";
          isValid = false;
        } else {
          item.error = null;
        }
      }
    });
    setQuesAnsArray(updateQuesArr);
    return isValid;
  };

  const handleNext = () => {
    setOpenCV(validate());
  };

  const handlePopUpBack = () => {
    setOpenCV(false);
  };

  const JobQData = props.data;

  return (
    <>
      {isSmallScreen && (
        <>
          <div className="fx" style={{ backgroundColor: "#FAFAFA" }}>
            <div className="col-12 bg-white d-flex py-2 border-bottom px-3">
              <div className="my-auto">
                <KeyboardBackspaceIcon onClick={handleBack} />
              </div>
              <h6 className="px-3 my-auto fw-bold ">{props.data.title}</h6>
            </div>
            <div className="col-12 py-2 pt-3 px-3">
              <div className="row">
                <div className="col-10">
                  <div className="">
                    <img
                      width="48px"
                      height="48px"
                      src={props.data.logo}
                      className="img-fluid border-0 fw-bold"
                      alt={props.data.title}
                    />
                  </div>
                  <div className="fw-bold py-2">{props.data.title}</div>
                  <div className="fw-bold text-muted">{props.data.name}</div>
                </div>
                <div className="col-2">
                  <Box className="small-icon">
                    <Checkbox
                      checked={save}
                      onClick={handleSave}
                      icon={<BookmarkBorderIcon />}
                      checkedIcon={<BookmarkIcon />}
                    />
                    <ShareIcon
                      className="share-icon"
                      onClick={() => {
                        setOpen(true);
                        SharableUrl();
                      }}
                    />
                  </Box>
                </div>
              </div>
            </div>
            <Shareable
              isOpen={open}
              setOpen={setOpen}
              url={shareurl}
              handleViewFullpage={handleViewFullpage}
            />
            <div className="col-12 py-2 px-3 ">
              <div
                className="rounded-3 fx py-3 px-3 mb-2"
                style={{ background: "#E6EFF7", boxSizing: "border-box" }}
              >
                <div className="h5 fw-bold pb-2">Highlights</div>
                <div
                  className="row overflow-hidden"
                  style={{ boxSizing: "border-box", color: "#424242" }}
                >
                  <div className="py-2 d-flex ">
                    <span>
                      <PlaceOutlinedIcon fontSize="small" className="mx-1" />
                    </span>
                    <div
                      className="px-1 fw-bold"
                      title={props.data.city + ", " + props.data.state}
                    >
                      {props.data.city}, {props.data.state}
                    </div>
                  </div>
                  <div className="py-2 d-flex">
                    <span>
                      <WorkOutlineOutlinedIcon
                        className="mx-1"
                        sx={{ marginBottom: "0.5ch" }}
                        fontSize="small"
                      />
                    </span>
                    <span className="px-1 fw-bold">
                      {/* {props?.data?.experience &&
                        props.data.experience.trim() !== ""
                        ? props.data.experience
                        : "-"} */}
                      {props.data?.min_experience < 1 &&
                      !props.data?.max_experience
                        ? "Entry-Level"
                        : props.data?.min_experience?.toString()
                        ? `${props.data.min_experience}${
                            !props.data.max_experience ? "+ " : ""
                          }`
                        : ""}
                      {props.data?.max_experience ? " - " : ""}
                      {props.data?.max_experience
                        ? props.data?.max_experience
                        : ""}
                      {props.data?.min_experience == 1 &&
                      props.data?.max_experience == ""
                        ? " years"
                        : props.data?.min_experience ||
                          props.data?.max_experience
                        ? " years"
                        : ""}
                    </span>
                  </div>
                  <div className="py-2 d-flex">
                    <span>
                      <ScheduleOutlinedIcon
                        fontSize="small"
                        className="mx-1"
                        sx={{ marginBottom: "0.5ch" }}
                      />
                    </span>
                    <div className="px-1 fw-bold">{postedMessages}</div>
                  </div>
                  {props.data.redirect_link ? (
                    <></>
                  ) : (
                    <>
                      <div className="py-2 d-flex">
                        <span className="material-symbols-rounded mx-1 ">
                          send
                        </span>
                        <div className="fw-bold">
                          {props.data.total_recruited
                            ? props.data.total_recruited
                            : 0}{" "}
                          Applied
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 py-2 px-3 ">
              <h6 className="fx fw-bold py-2">About the Job</h6>
              <div
                id="htmlServer"
                className="fz"
                dangerouslySetInnerHTML={{
                  __html: Html(props.data.description),
                }}
              ></div>
            </div>
            <div className="col-12 py-2 px-3">
              <h6 className="fx fw-bold py-2">Skills</h6>
              <Stack spacing={1}>
                <Stack direction="row" flexWrap={"wrap"} gap={1.5}>
                  {props?.data?.skills?.length > 0 ? (
                    props?.data?.skills.map((data, i) => {
                      return (
                        <Chip
                          label={data}
                          key={i}
                          className="fw-bolder text-capitalize"
                          sx={{ padding: "11px 5px" }}
                        />
                      );
                    })
                  ) : (
                    <Chip
                      label="Not Available"
                      className="fw-bolder text-capitalize"
                      sx={{ padding: "11px 5px" }}
                    />
                  )}
                </Stack>
              </Stack>
            </div>
            <div className="col-12 py-2 px-3">
              <h6 className="fx fw-bold py-2">More Info</h6>
              <div className="d-flex">
                <div className="col">
                  <div className="fw-bold">Job Type</div>
                  <div className="text-muted fw-bold">{props.data.text}</div>
                </div>
                <div className="col">
                  <div className="fw-bold">Role</div>
                  <div
                    className="text-muted fw-bold"
                    style={{ letterSpacing: "0.5px" }}
                  >
                    {props.data.role}
                  </div>
                </div>
              </div>
            </div>
            {openForm && (
              <FormPopup
                applyLoadingJoborder={applyLoading}
                applied={props.applied}
                CVJobData={props.data}
                setOpenForm={setOpenForm}
                errorFile={errorFile}
                errorEmail={errorEmail}
                emailexceed={emailexceed}
                setEmailExceeded={setEmailExceeded}
                setErrorEmail={setErrorEmail}
                errorName={errorName}
                file={files}
                setFiles={setFiles}
                handleOnResumeChange={handleOnResumeChange}
                name={name}
                email={email}
                setName={setName}
                setEmail={setEmail}
                handleSubmit={handleSubmit}
                handleName={handleName}
                handleEmail={handleEmail}
                images={images}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isVerified={props.isVerified}
                setVerified={props.setVerified}
                //Question Popup Props
                jobDetails={JobQData}
                openQuestionpopup={openQuestionpopup}
                setOpenQuestionpopup={setOpenQuestionpopup}
                handleClickOpenQuestionPopup={handleClickOpenQuestionPopup}
                handleClickCloseQuestionPopup={handleClickCloseQuestionPopup}
                question={question}
                setQuestion={setQuestion}
                textFields={textFields}
                setTextFields={setTextFields}
                radioValues={radioValues}
                setRadioValues={setRadioValues}
                handleRadioChange={handleRadioChange}
                handleTextFieldChange={handleTextFieldChange}
                openCV={openCV}
                setOpenCV={setOpenCV}
                handleCVPopupOpen={handleCVPopupOpen}
                handleCVPopupClose={handleCVPopupClose}
                handleNext={handleNext}
                handlePopUpBack={handlePopUpBack}
                setQuesAnsArray={setQuesAnsArray}
                quesAnsArray={quesAnsArray}
                validateQuesError={validate}
                setQuesErrorHandler={setQuesErrorHandler}
              />
            )}
          </div>
          <GenerateCVPopup
            openCV={openCV}
            handleCVHtmlChange={handleCVHtmlChange}
            additionInfoData={additionInfoData}
            setadditionInfoData={setadditionInfoData}
            handleAdditionInfoChange={handleAdditionInfoChange}
            exceededLimitAdditionalInfo={exceededLimitAdditionalInfo}
            setExceededLimitAdditionalInfo={setExceededLimitAdditionalInfo}
            HtmlContent={HtmlContent}
            setHtmlContent={setHtmlContent}
            setOpenCV={setOpenCV}
            handleCVPopupOpen={handleCVPopupOpen}
            handleCVPopupClose={handleCVPopupClose}
            jobData={props.data}
            credits={credits}
            setCredits={setCredits}
            loading={loading}
            setLoading={setLoading}
            handleGenerateCV={handleGenerateCV}
            someAsyncFunction={someAsyncFunction}
            handleQuickApply={handleQuickApply}
            handlePopUpBack={handlePopUpBack}
          />
          <QuestionPopup
            jobDetails={JobQData}
            openQuestionpopup={openQuestionpopup}
            setOpenQuestionpopup={setOpenQuestionpopup}
            handleClickOpenQuestionPopup={handleClickOpenQuestionPopup}
            handleClickCloseQuestionPopup={handleClickCloseQuestionPopup}
            question={question}
            setQuestion={setQuestion}
            textFields={textFields}
            setTextFields={setTextFields}
            radioValues={radioValues}
            setRadioValues={setRadioValues}
            handleRadioChange={handleRadioChange}
            handleTextFieldChange={handleTextFieldChange}
            openCV={openCV}
            setOpenCV={setOpenCV}
            handleCVPopupOpen={handleCVPopupOpen}
            handleCVPopupClose={handleCVPopupClose}
            handleNext={handleNext}
            handlePopUpBack={handlePopUpBack}
            setQuesAnsArray={setQuesAnsArray}
            quesAnsArray={quesAnsArray}
            quesErrorHandler={quesErrorHandler}
            setQuesErrorHandler={setQuesErrorHandler}
          />
          <div
            className="sticky-bottom bg-white px-5 py-3"
            style={{ zIndex: "1" }}
          >
            {cookies._secure_ARJ_ || cookies._secure_ref ? (
              (props.data.job_applied && props.data.status === 2900) ||
              !props.data.job_applied ? (
                <>
                  {isCLRequired === 1 || isQuestionnaire === 1 ? (
                    <div className="d-flex align-items-center align-content-end justify-content-end gap-2">
                      <Button
                        className="px-4 fw-bolder"
                        onClick={(e) => handleCVPopupOpen(e)}
                        sx={{ letterSpacing: "1px" }}
                        variant="contained"
                      >
                        Apply
                      </Button>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      {props.data.redirect_link ? (
                        <Button
                          className="fw-bolder overflow-hidden"
                          onClick={(e) => handleQuickApply(e)}
                          sx={{ letterSpacing: "1px" }}
                          variant="contained"
                          color="primary"
                          endIcon={<LaunchIcon />}
                        >
                          Apply
                        </Button>
                      ) : (
                        <>
                          <Button
                            className="fw-bolder overflow-hidden"
                            onClick={(e) => handleQuickApply(e)}
                            sx={{ letterSpacing: "1px" }}
                            variant="outlined"
                            color="primary"
                            endIcon={<TouchAppIcon />}
                          >
                            Quick Apply
                          </Button>
                          <Button
                            className="px-4 fw-bolder"
                            onClick={(e) => handleCVPopupOpen(e)}
                            sx={{ letterSpacing: "1px" }}
                            variant="contained"
                          >
                            Apply
                          </Button>
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="fx fw-bold d-flex gap-2 align-items-center justify-content-center rounded py-2"
                  style={{ backgroundColor: "#DCDCDC" }}
                >
                  <div className="">
                    <CheckCircleIcon
                      fontSize="small"
                      sx={{ color: "rgba(0, 0, 0, 0.33)" }}
                    />
                  </div>
                  <div style={{ letterSpacing: "1px" }} className="">
                    Applied on{" "}
                    {moment(props.data.job_applied).format("MM/DD/YYYY")}
                  </div>
                </div>
              )
            ) : (
              <Button
                fullWidth
                className=" fw-bold border-0 position-relative"
                onClick={handleOpenForm}
                sx={{ letterSpacing: "1px" }}
                variant="contained"
              >
                {props.data.redirect_link ? (
                  <>
                    Apply as Guest
                    <LaunchIcon
                      className="ps-1"
                      fontSize="small"
                      fontWeight="bold"
                    />
                  </>
                ) : (
                  "Apply as Guest"
                )}
              </Button>
            )}
          </div>
        </>
      )}

      {isMediumScreen && !isSmallScreen && (
        <>
          <div>
            <div
              className={`vl bg-white ${
                window.location.pathname ===
                `/jobDetail/${props.data.joborder_id}`
                  ? "pb-5"
                  : "jobApplyCard"
              }  `}
            >
              <div className="col-12 pt-4">
                <div className="row px-4 m-0">
                  <div className="row row-cols-3 pb-3 p-0">
                    <div
                      className="col-xl-3 col-lg-2 m-auto"
                      style={{ width: "5em" }}
                    >
                      <img
                        src={props.data.logo}
                        style={{ width: "4rem" }}
                        className="img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="card-body m-auto fw-bold m-0 px-3 p-0 fx">
                      <div
                        className="card-title fw-bold d-flex align-items-center justify-content-start"
                        style={{ color: "#2D65BC" }}
                      >
                        {props.data.title}
                        {window.location.pathname ===
                        "/jobsearch/allsearchdata" ? (
                          <></>
                        ) : (
                          <div className="">
                            <Checkbox
                              checked={save}
                              onClick={handleSave}
                              icon={<BookmarkBorderIcon />}
                              checkedIcon={<BookmarkIcon />}
                            />
                          </div>
                        )}
                      </div>
                      <div className="card-subtitle fw-bold">
                        {props.data.name}
                      </div>
                    </div>
                    <div className="" style={{ boxSizing: "border-box" }}>
                      {window.location.pathname ===
                      `/jobDetail/${props.data.joborder_id}` ? (
                        <div className={`pb-1 fx text-end`}>
                          <Box className="box-container">
                            <div className="share-text">Share this Job</div>

                            <ShareIcon
                              className="share-icon"
                              onClick={() => {
                                setOpen(true);
                                SharableUrl();
                              }}
                            />
                          </Box>

                          {/*  */}

                          {/*  */}
                          <Shareable
                            isOpen={open}
                            setOpen={setOpen}
                            url={shareurl}
                            handleViewFullpage={handleViewFullpage}
                            mobileview={false}
                          />
                        </div>
                      ) : (
                        <div
                          className={`pb-1 fx ${
                            cookies._secure_ARJ_ || cookies._secure_ref
                              ? "text-end"
                              : "text-end"
                          }`}
                        >
                          {/* <a
                            className="fw-bold viewfullpagehover"
                            style={{ fontSize: "12px", cursor: "pointer" }}
                            target="_blank"
                            onClick={handleViewFullpage}
                          >
                            {" "}
                            <NorthEastIcon
                              fontSize=""
                              sx={{ marginRight: "0.1px" }}
                            />{" "}
                            View full page
                          </a> */}
                          <Box className="box-container">
                            <div className="share-text">Share this Job</div>

                            <ShareIcon
                              className="share-icon"
                              onClick={() => {
                                setOpen(true);
                                SharableUrl();
                              }}
                            />
                          </Box>

                          {/*  */}

                          {/*  */}
                          <Shareable
                            isOpen={open}
                            setOpen={setOpen}
                            url={shareurl}
                            handleViewFullpage={handleViewFullpage}
                            mobileview={false}
                          />
                        </div>
                      )}
                      <GenerateCVPopup
                        openCV={openCV}
                        handleCVHtmlChange={handleCVHtmlChange}
                        additionInfoData={additionInfoData}
                        setadditionInfoData={setadditionInfoData}
                        handleAdditionInfoChange={handleAdditionInfoChange}
                        exceededLimitAdditionalInfo={
                          exceededLimitAdditionalInfo
                        }
                        setExceededLimitAdditionalInfo={
                          setExceededLimitAdditionalInfo
                        }
                        HtmlContent={HtmlContent}
                        setHtmlContent={setHtmlContent}
                        setOpenCV={setOpenCV}
                        handleCVPopupOpen={handleCVPopupOpen}
                        handleCVPopupClose={handleCVPopupClose}
                        jobData={props.data}
                        credits={credits}
                        setCredits={setCredits}
                        loading={loading}
                        setLoading={setLoading}
                        handleGenerateCV={handleGenerateCV}
                        someAsyncFunction={someAsyncFunction}
                        handleQuickApply={handleQuickApply}
                        handlePopUpBack={handlePopUpBack}
                      />
                      <QuestionPopup
                        jobDetails={JobQData}
                        openQuestionpopup={openQuestionpopup}
                        setOpenQuestionpopup={setOpenQuestionpopup}
                        handleClickOpenQuestionPopup={
                          handleClickOpenQuestionPopup
                        }
                        handleClickCloseQuestionPopup={
                          handleClickCloseQuestionPopup
                        }
                        question={question}
                        setQuestion={setQuestion}
                        textFields={textFields}
                        setTextFields={setTextFields}
                        radioValues={radioValues}
                        setRadioValues={setRadioValues}
                        handleRadioChange={handleRadioChange}
                        handleTextFieldChange={handleTextFieldChange}
                        openCV={openCV}
                        setOpenCV={setOpenCV}
                        handleCVPopupOpen={handleCVPopupOpen}
                        handleCVPopupClose={handleCVPopupClose}
                        handleNext={handleNext}
                        handlePopUpBack={handlePopUpBack}
                        setQuesAnsArray={setQuesAnsArray}
                        quesAnsArray={quesAnsArray}
                        quesErrorHandler={quesErrorHandler}
                        setQuesErrorHandler={setQuesErrorHandler}
                      />
                      {cookies._secure_ARJ_ || cookies._secure_ref ? (
                        (props.data.job_applied &&
                          props.data.status === 2900) ||
                        !props.data.job_applied ? (
                          <>
                            {isCLRequired === 1 || isQuestionnaire === 1 ? (
                              <div className="d-flex align-items-center align-content-end justify-content-end gap-2">
                                <Button
                                  className="px-4 fw-bolder"
                                  onClick={(e) => handleCVPopupOpen(e)}
                                  sx={{ letterSpacing: "1px" }}
                                  variant="contained"
                                >
                                  Quick Apply
                                </Button>
                              </div>
                            ) : (
                              <div className="d-flex align-items-center align-content-end justify-content-end gap-2">
                                {props.data.redirect_link ? (
                                  <Button
                                    className="fw-bolder overflow-hidden"
                                    onClick={(e) => handleQuickApply(e)}
                                    sx={{ letterSpacing: "1px" }}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<LaunchIcon />}
                                  >
                                    Apply
                                  </Button>
                                ) : (
                                  <>
                                    <button
                                      className="fw-bolder btn btn-outline-primary btn-sm d-flex"
                                      onClick={(e) => handleQuickApply(e)}
                                      variant="outlined"
                                      color="primary"
                                    >
                                      QuickApply
                                      <TouchAppIcon />
                                    </button>
                                    <Button
                                      className="px-4 fw-bolder"
                                      onClick={(e) => handleCVPopupOpen(e)}
                                      sx={{ letterSpacing: "1px" }}
                                      variant="contained"
                                      size="small"
                                    >
                                      Apply
                                    </Button>
                                  </>
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="d-flex gap-3 text-end fx fw-bold">
                            <img
                              src="/rightick.png"
                              alt="Confirm job applications with Jobeze"
                              title="Confirm Job Applications Easily on Jobeze"
                              style={{ width: "15px", height: "15px" }}
                              className="img-fluid"
                            />
                            <span style={{ fontSize: "12.5px" }}>
                              Applied on{" "}
                              {moment(props.data.job_applied).format(
                                "MM/DD/YYYY"
                              )}
                            </span>
                          </div>
                        )
                      ) : (
                        <div className="">
                          <Button
                            className="px-4 fw-bolder border-0 position-relative"
                            onClick={handleOpenForm}
                            sx={{ letterSpacing: "1px" }}
                            variant="contained"
                          >
                            {props.data.redirect_link ? (
                              <>
                                Apply as Guest
                                <LaunchIcon
                                  className="ps-1"
                                  fontSize="small"
                                  fontWeight="bold"
                                />
                              </>
                            ) : (
                              "Apply as Guest"
                            )}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  {openForm && (
                    <FormPopup
                      applied={props.applied}
                      CVJobData={props.data}
                      setOpenForm={setOpenForm}
                      errorFile={errorFile}
                      errorEmail={errorEmail}
                      emailexceed={emailexceed}
                      setEmailExceeded={setEmailExceeded}
                      setErrorEmail={setErrorEmail}
                      errorName={errorName}
                      file={files}
                      setFiles={setFiles}
                      handleOnResumeChange={handleOnResumeChange}
                      name={name}
                      email={email}
                      setName={setName}
                      setEmail={setEmail}
                      handleSubmit={handleSubmit}
                      handleName={handleName}
                      handleEmail={handleEmail}
                      images={images}
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      isVerified={props.isVerified}
                      setVerified={props.setVerified}
                      //Question Popup Props
                      jobDetails={JobQData}
                      openQuestionpopup={openQuestionpopup}
                      setOpenQuestionpopup={setOpenQuestionpopup}
                      handleClickOpenQuestionPopup={
                        handleClickOpenQuestionPopup
                      }
                      handleClickCloseQuestionPopup={
                        handleClickCloseQuestionPopup
                      }
                      question={question}
                      setQuestion={setQuestion}
                      textFields={textFields}
                      setTextFields={setTextFields}
                      radioValues={radioValues}
                      setRadioValues={setRadioValues}
                      handleRadioChange={handleRadioChange}
                      handleTextFieldChange={handleTextFieldChange}
                      openCV={openCV}
                      setOpenCV={setOpenCV}
                      handleCVPopupOpen={handleCVPopupOpen}
                      handleCVPopupClose={handleCVPopupClose}
                      handleNext={handleNext}
                      handlePopUpBack={handlePopUpBack}
                      setQuesAnsArray={setQuesAnsArray}
                      quesAnsArray={quesAnsArray}
                      validateQuesError={validate}
                      setQuesErrorHandler={setQuesErrorHandler}
                    />
                  )}
                  <hr />
                </div>
                {applyLoading ? <ResendBackdrop /> : <></>}

                <div
                  className="ps-4 pt-2 jbscroll"
                  ref={ScrollRef}
                  style={{
                    height:
                      window.location.pathname ===
                      `/jobDetail/${props.data.joborder_id}`
                        ? "unset"
                        : "60vh",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  <div
                    className="m-0 pe-4 fw-bold"
                    style={{ fontSize: "13.5px" }}
                  >
                    <div
                      className="rounded-3 fx py-3 px-3 mb-2"
                      style={{ background: "#E6EFF7", boxSizing: "border-box" }}
                    >
                      <div className="h5 fw-bold pb-2">Highlights</div>
                      <div
                        className="row m-0"
                        style={{ boxSizing: "border-box" }}
                      >
                        <div
                          className="col-lg p-lg-0 py-1 d-flex "
                          //  style={{ textOverflow : "ellipsis" , whiteSpace: "nowrap", overflow: "hidden"}}
                        >
                          <span>
                            {" "}
                            <PlaceOutlinedIcon
                              fontSize="small"
                              sx={{ marginBottom: "0.1ch" }}
                            />{" "}
                          </span>
                          <div
                            className="px-1"
                            title={props.data.city + ", " + props.data.state}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {props.data.city}, {props.data.state}
                          </div>
                        </div>
                        <div className="col-lg py-1 text-lg-center">
                          <span>
                            <WorkOutlineOutlinedIcon
                              className="mx-1"
                              sx={{ marginBottom: "0.5ch" }}
                              fontSize="small"
                            />
                          </span>
                          {/* {props?.data?.experience &&
                            props.data.experience.trim() !== ""
                            ? props.data.experience
                            : "-"} */}
                          {props.data?.min_experience < 1 &&
                          !props.data?.max_experience
                            ? "Entry-Level"
                            : props.data?.min_experience?.toString()
                            ? `${props.data.min_experience}${
                                !props.data.max_experience ? "+ " : ""
                              }`
                            : ""}
                          {props.data?.max_experience ? " - " : ""}
                          {props.data?.max_experience
                            ? props.data?.max_experience
                            : ""}
                          {props.data?.min_experience == 1 &&
                          props.data?.max_experience == ""
                            ? " years"
                            : props.data?.min_experience ||
                              props.data?.max_experience
                            ? " years"
                            : ""}
                        </div>
                        <div className="col-lg py-1 d-flex justify-content-lg-end">
                          <span>
                            {" "}
                            <ScheduleOutlinedIcon
                              fontSize="small"
                              sx={{ marginBottom: "0.5ch" }}
                            />{" "}
                          </span>
                          <div className="px-1">{postedMessages}</div>
                        </div>
                        {props.data.redirect_link ? (
                          <></>
                        ) : (
                          <>
                            <div className="col-lg py-1 d-flex justify-content-lg-center">
                              <span className="material-symbols-rounded">
                                send
                              </span>
                              <div
                                className="px-1"
                                style={{ marginTop: "2px" }}
                              >
                                {props.data.total_recruited
                                  ? props.data.total_recruited
                                  : 0}{" "}
                                Applied
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <h5 className="fx fw-bold py-2 px-0">About the Job</h5>
                  </div>
                  <div
                    id="htmlServer"
                    className="text-wrap text-break pb-2"
                    dangerouslySetInnerHTML={{
                      __html: Html(props.data.description),
                    }}
                  ></div>
                  <div className="fx">
                    <h5 className="fw-bolder text-capitalize ">Skills</h5>
                    <Stack spacing={1}>
                      <Stack direction="row" flexWrap={"wrap"} gap={1.5}>
                        {props?.data?.skills?.length > 0 ? (
                          props?.data?.skills.map((data, i) => {
                            return (
                              <Chip
                                label={data}
                                key={i}
                                className="fw-bolder text-capitalize"
                                sx={{ padding: "11px 5px" }}
                              />
                            );
                          })
                        ) : (
                          <Chip
                            label="Not Available"
                            className="fw-bolder text-capitalize"
                            sx={{ padding: "11px 5px" }}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </div>
                  <div className="row m-0 py-3 fx">
                    <div className="col-6 pb-3">
                      <div className="fw-bold">Job Type</div>
                      <div className="text-muted fw-bold">
                        {props.data.text}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="fw-bold">Role</div>
                      <div
                        className="text-muted fw-bold"
                        style={{ letterSpacing: "0.5px" }}
                      >
                        {props.data.role}
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="fw-bold">Work Mode</div>
                      <div className="text-muted fw-bold text-capitalize ">
                        {props.data.workmode}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!isSmallScreen && !isMediumScreen && (
        <div style={{ width: isMediumScreen ? "100%" : "94%" }}>
          <div
            className={`vl bg-white ${
              window.location.pathname ===
              `/jobDetail/${props.data.joborder_id}`
                ? "pb-5"
                : "jobApplyCard"
            }  `}
          >
            {props.data.status === 2900 && isShortlistStatus && (
              <div className="p-3 pb-2">
                <Chip
                  label="Shortlisted"
                  className="fw-bold"
                  sx={{
                    backgroundColor: "#EAF6FF",
                    color: "#006CB7",
                    border: "1px solid #006CB7",
                    letterSpacing: "0.5px",
                  }}
                />
              </div>
            )}
            <div
              className={`col-12 ${
                props.data.status === 2900 && isShortlistStatus ? "" : "pt-4"
              }`}
            >
              <div className="row px-4 m-0">
                <div className="row row-cols-3 pb-3 p-0">
                  <div
                    className="col-xl-3 col-lg-2 m-auto"
                    style={{ width: "6em" }}
                  >
                    <img
                      src={props.data.logo}
                      style={{ width: "5rem" }}
                      className="img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="card-body m-auto fw-bold m-0 px-3 p-0 fx">
                    <div
                      className="card-title fw-bold d-flex align-items-center justify-content-start"
                      style={{ color: "#2D65BC" }}
                    >
                      {props.data.title}
                      {window.location.pathname ===
                      "/jobsearch/allsearchdata" ? (
                        <></>
                      ) : (
                        <div className="">
                          <Checkbox
                            checked={save}
                            onClick={handleSave}
                            icon={<BookmarkBorderIcon />}
                            checkedIcon={<BookmarkIcon />}
                          />
                        </div>
                      )}
                    </div>
                    <div className="card-subtitle fw-bold">
                      {props.data.name}
                    </div>
                  </div>
                  <div
                    className="col-lg-4 p-0 text-end d-flex flex-column align-items-end justify-content-center ms-auto"
                    style={{ boxSizing: "border-box" }}
                  >
                    {window.location.pathname ===
                    `/jobDetail/${props.data.joborder_id}` ? (
                      <div className={`pb-1 fx text-end `}>
                        <Box className="box-container">
                          <div className="share-text">Share this Job</div>
                          <ShareIcon
                            className="share-icon"
                            onClick={() => {
                              setOpen(true);
                              SharableUrl();
                            }}
                          />
                        </Box>
                        {/* </a> */}

                        <Shareable
                          isOpen={open}
                          setOpen={setOpen}
                          url={shareurl}
                          handleViewFullpage={handleViewFullpage}
                          mobileview={false}
                        />
                      </div>
                    ) : (
                      <div
                        className={`pb-1 fx ${
                          cookies._secure_ARJ_ || cookies._secure_ref
                            ? "text-end"
                            : "text-end"
                        }`}
                      >
                        {/* <a
                          className="fw-bold viewfullpagehover"
                          style={{
                            fontSize: "12px",
                            cursor: "pointer",
                            color: "unset",
                          }}
                          target="_blank"
                          onClick={handleViewFullpage}
                          href=" #"
                        > */}{" "}
                        {/* <NorthEastIcon
                            fontSize=""
                            sx={{ marginRight: "0.1px" }}
                          />{" "} */}
                        <Box className="box-container">
                          <div className="share-text">Share this Job</div>
                          <ShareIcon
                            className="share-icon"
                            onClick={() => {
                              setOpen(true);
                              SharableUrl();
                            }}
                          />
                        </Box>
                        {/* </a> */}
                        <Shareable
                          isOpen={open}
                          setOpen={setOpen}
                          url={shareurl}
                          handleViewFullpage={handleViewFullpage}
                          mobileview={false}
                        />
                      </div>
                    )}
                    <GenerateCVPopup
                      openCV={openCV}
                      handleCVHtmlChange={handleCVHtmlChange}
                      additionInfoData={additionInfoData}
                      setadditionInfoData={setadditionInfoData}
                      handleAdditionInfoChange={handleAdditionInfoChange}
                      exceededLimitAdditionalInfo={exceededLimitAdditionalInfo}
                      setExceededLimitAdditionalInfo={
                        setExceededLimitAdditionalInfo
                      }
                      HtmlContent={HtmlContent}
                      setHtmlContent={setHtmlContent}
                      setOpenCV={setOpenCV}
                      handleCVPopupOpen={handleCVPopupOpen}
                      handleCVPopupClose={handleCVPopupClose}
                      jobData={props.data}
                      credits={credits}
                      setCredits={setCredits}
                      loading={loading}
                      setLoading={setLoading}
                      handleGenerateCV={handleGenerateCV}
                      someAsyncFunction={someAsyncFunction}
                      handleQuickApply={handleQuickApply}
                      handlePopUpBack={handlePopUpBack}
                    />
                    <QuestionPopup
                      jobDetails={JobQData}
                      openQuestionpopup={openQuestionpopup}
                      setOpenQuestionpopup={setOpenQuestionpopup}
                      handleClickOpenQuestionPopup={
                        handleClickOpenQuestionPopup
                      }
                      handleClickCloseQuestionPopup={
                        handleClickCloseQuestionPopup
                      }
                      question={question}
                      setQuestion={setQuestion}
                      textFields={textFields}
                      setTextFields={setTextFields}
                      radioValues={radioValues}
                      setRadioValues={setRadioValues}
                      handleRadioChange={handleRadioChange}
                      handleTextFieldChange={handleTextFieldChange}
                      openCV={openCV}
                      setOpenCV={setOpenCV}
                      handleCVPopupOpen={handleCVPopupOpen}
                      handleCVPopupClose={handleCVPopupClose}
                      handleNext={handleNext}
                      handlePopUpBack={handlePopUpBack}
                      setQuesAnsArray={setQuesAnsArray}
                      quesAnsArray={quesAnsArray}
                      quesErrorHandler={quesErrorHandler}
                      setQuesErrorHandler={setQuesErrorHandler}
                    />

                    {cookies._secure_ARJ_ || cookies._secure_ref ? (
                      (props.data.job_applied &&
                        props.data.status === 2900 &&
                        isShortlistStatus) ||
                      !props.data.job_applied ? (
                        <>
                          {isCLRequired === 1 || isQuestionnaire === 1 ? (
                            <div className="d-flex align-items-center align-content-end justify-content-end gap-2">
                              <Button
                                className="px-4 fw-bolder"
                                onClick={(e) => handleCVPopupOpen(e)}
                                sx={{ letterSpacing: "1px" }}
                                variant="contained"
                              >
                                Apply
                              </Button>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center align-content-end justify-content-end gap-2">
                              {props.data.redirect_link ? (
                                <Button
                                  className="fw-bolder overflow-hidden"
                                  onClick={(e) => handleQuickApply(e)}
                                  sx={{ letterSpacing: "1px" }}
                                  variant="contained"
                                  color="primary"
                                  endIcon={<LaunchIcon />}
                                >
                                  Apply
                                </Button>
                              ) : (
                                <>
                                  <Button
                                    className="fw-bolder overflow-hidden"
                                    onClick={(e) => handleQuickApply(e)}
                                    sx={{ letterSpacing: "1px" }}
                                    variant="outlined"
                                    color="primary"
                                    endIcon={<TouchAppIcon />}
                                  >
                                    Quick Apply
                                  </Button>
                                  <Button
                                    className="px-4 fw-bolder"
                                    onClick={(e) => handleCVPopupOpen(e)}
                                    // onClick={(e) => handleClickOpenQuestionPopup(e)}
                                    sx={{ letterSpacing: "1px" }}
                                    variant="contained"
                                  >
                                    Apply
                                  </Button>
                                </>
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="fx fw-bold col-lg-12 d-flex align-items-center justify-content-end gap-2">
                          <div className="" style={{ boxSizing: "border-box" }}>
                            <img
                              src="/rightick.png"
                              alt="Confirm job applications with Jobeze"
                              title="Confirm Job Applications Easily on Jobeze"
                              style={{ width: "20px", height: "20px" }}
                              className="img-fluid"
                            />
                          </div>
                          <div style={{ fontSize: "14px" }} className="">
                            Applied on{" "}
                            {moment(props.data.job_applied).format(
                              "MM/DD/YYYY"
                            )}
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="">
                        <Button
                          className="px-4 fw-bolder border-0 position-relative"
                          onClick={handleOpenForm}
                          sx={{ letterSpacing: "1px" }}
                          variant="contained"
                        >
                          {props.data.redirect_link ? (
                            <>
                              Apply as Guest
                              <LaunchIcon
                                className="ps-1"
                                fontSize="small"
                                fontWeight="bold"
                              />
                            </>
                          ) : (
                            "Apply as Guest"
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {openForm && (
                  <FormPopup
                    applied={props.applied}
                    CVJobData={props.data}
                    setOpenForm={setOpenForm}
                    errorFile={errorFile}
                    errorEmail={errorEmail}
                    emailexceed={emailexceed}
                    setEmailExceeded={setEmailExceeded}
                    setErrorEmail={setErrorEmail}
                    errorName={errorName}
                    file={files}
                    setFiles={setFiles}
                    handleOnResumeChange={handleOnResumeChange}
                    name={name}
                    email={email}
                    setName={setName}
                    setEmail={setEmail}
                    handleSubmit={handleSubmit}
                    handleName={handleName}
                    handleEmail={handleEmail}
                    images={images}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    isVerified={props.isVerified}
                    setVerified={props.setVerified}
                    //Question Popup Props
                    jobDetails={JobQData}
                    openQuestionpopup={openQuestionpopup}
                    setOpenQuestionpopup={setOpenQuestionpopup}
                    handleClickOpenQuestionPopup={handleClickOpenQuestionPopup}
                    handleClickCloseQuestionPopup={
                      handleClickCloseQuestionPopup
                    }
                    question={question}
                    setQuestion={setQuestion}
                    textFields={textFields}
                    setTextFields={setTextFields}
                    radioValues={radioValues}
                    setRadioValues={setRadioValues}
                    handleRadioChange={handleRadioChange}
                    handleTextFieldChange={handleTextFieldChange}
                    openCV={openCV}
                    setOpenCV={setOpenCV}
                    handleCVPopupOpen={handleCVPopupOpen}
                    handleCVPopupClose={handleCVPopupClose}
                    handleNext={handleNext}
                    handlePopUpBack={handlePopUpBack}
                    setQuesAnsArray={setQuesAnsArray}
                    quesAnsArray={quesAnsArray}
                    validateQuesError={validate}
                    setQuesErrorHandler={setQuesErrorHandler}
                  />
                )}
                <hr />
              </div>
              {applyLoading ? <ResendBackdrop /> : <></>}

              <div
                className="ps-4 pt-2 jbscroll"
                ref={ScrollRef}
                style={{
                  height:
                    window.location.pathname ===
                    `/jobDetail/${props.data.joborder_id}`
                      ? "unset"
                      : "60vh",
                  overflowX: "hidden",
                  overflowY: "scroll",
                }}
              >
                <div
                  className="m-0 pe-4 fw-bold"
                  style={{ fontSize: "13.5px" }}
                >
                  <div
                    className="rounded-3 fx py-3 px-3 mb-2"
                    style={{ background: "#E6EFF7", boxSizing: "border-box" }}
                  >
                    <div className="h5 fw-bold pb-2">Highlights</div>
                    <div
                      className="row m-0"
                      style={{ boxSizing: "border-box" }}
                    >
                      <div
                        className="col-lg p-lg-0 py-1 d-flex align-items-center "
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        <span>
                          {" "}
                          <PlaceOutlinedIcon
                            fontSize="small"
                            sx={{ marginBottom: "0.1ch" }}
                          />{" "}
                        </span>
                        <div
                          className="px-1"
                          title={props.data.city + ", " + props.data.state}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {props.data.city}, {props.data.state}
                        </div>
                      </div>
                      <div
                        className="col-lg py-1 text-lg-center"
                        style={{ letterSpacing: "0.5px" }}
                      >
                        <span>
                          <WorkOutlineOutlinedIcon
                            className="mx-1"
                            sx={{ marginBottom: "0.5ch" }}
                            fontSize="small"
                          />
                        </span>
                        {props.data?.min_experience < 1 &&
                        !props.data?.max_experience
                          ? "Entry-Level"
                          : props.data?.min_experience?.toString()
                          ? `${props.data.min_experience}${
                              !props.data.max_experience ? "+ " : ""
                            }`
                          : ""}
                        {props.data?.max_experience ? " - " : ""}
                        {props.data?.max_experience
                          ? props.data?.max_experience
                          : ""}
                        {props.data?.min_experience == 1 &&
                        props.data?.max_experience == ""
                          ? " years"
                          : props.data?.min_experience ||
                            props.data?.max_experience
                          ? " years"
                          : ""}
                      </div>
                      <div className="col-lg py-1 d-flex justify-content-lg-center">
                        <span>
                          {" "}
                          <ScheduleOutlinedIcon
                            fontSize="small"
                            sx={{ marginBottom: "0.5ch" }}
                          />{" "}
                        </span>
                        <div className="px-1">{postedMessages}</div>
                      </div>
                      {props.data.redirect_link ? (
                        <></>
                      ) : (
                        <>
                          <div className="col-lg py-1 d-flex justify-content-lg-center">
                            <span className="material-symbols-rounded">
                              send
                            </span>
                            <div className="px-1" style={{ marginTop: "2px" }}>
                              {props.data.total_recruited
                                ? props.data.total_recruited
                                : 0}{" "}
                              Applied
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <h5 className="fx fw-bold py-2 px-0">About the Job</h5>
                </div>
                <div
                  id="htmlServer"
                  className="text-wrap text-break pb-2"
                  dangerouslySetInnerHTML={{
                    __html: Html(props.data.description),
                  }}
                ></div>
                <div className="fx">
                  <h5 className="fw-bolder text-capitalize ">Skills</h5>
                  <Stack spacing={1}>
                    <Stack direction="row" flexWrap={"wrap"} gap={1.5}>
                      {props?.data?.skills?.length > 0 ? (
                        props?.data?.skills.map((data, i) => {
                          return (
                            <Chip
                              label={data}
                              key={i}
                              className="fw-bolder text-capitalize"
                              sx={{ padding: "11px 5px" }}
                            />
                          );
                        })
                      ) : (
                        <Chip
                          label="Not Available"
                          className="fw-bolder text-capitalize"
                          sx={{ padding: "11px 5px" }}
                        />
                      )}
                    </Stack>
                  </Stack>
                </div>
                <div className="row py-4 fx">
                  <div className="col-lg">
                    <div className="fw-bold">Job Type</div>
                    <div className="text-muted fw-bold">{props.data.text}</div>
                  </div>
                  <div className="col-lg">
                    <div className="fw-bold">Role</div>
                    <div
                      className="text-muted fw-bold"
                      style={{ letterSpacing: "0.5px" }}
                    >
                      {props.data.role}
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="fw-bold">Work Mode</div>
                    <div className="text-muted fw-bold text-capitalize ">
                      {props.data.workmode}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default JobApply;
