import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Pagination,
  Chip,
  Divider,
  Stack,
  IconButton,
} from "@mui/material";
import Homeheader from "../components/homeheader";
import FooterZ from "../components/FooterZ";
import FooterN from "../components/FooterN";
import { Link } from "react-router-dom";
import axios from "axios";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ArrowForward } from "@mui/icons-material";

const trendingTags = ["Lorem ipsum", "Ruim", "Lorem"];
// const trendingJobs = [
//   "Job lorem sit amet",
//   "Job lorem sit amet",
//   "Job lorem sit amet",
// ];

const Blogs = () => {
  const token = document.cookie.split("_secure_ARJ_=")[1];
  const [blogs, setBlogs] = useState([]);
  const [trendingJobs, setTrendingJobs] = useState([]);
  const [trendingTags, setTrendingTags] = useState([]);
  const [page, setPage] = useState(1);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const blogsPerPage = 7;

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setPage(value);
  };

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`/blogs/${page}?popular=false`);
      setBlogs(response.data.message.blog_result);
      setTotalBlogs(response.data.message.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchtrendingJobs = async () => {
    try {
      const response = await axios.get(`/trending-jobs`);
      setTrendingJobs(response.data.message);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchtrendingTags = async () => {
    try {
      const response = await axios.get(`/trending-tags`);
      setTrendingTags(response.data.message);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTagClick = async (tag) => {
    try {
      const response = await axios.get(`/trending-blogs/${page}?tag=${tag}`);
      console.log(response.data.message.blog_result);
      setBlogs(response.data.message.blog_result);
      setTotalBlogs(response.data.message.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchtrendingJobs();
    fetchtrendingTags();
  }, [page]);

  const totalPages = Math.ceil(totalBlogs / blogsPerPage);

  return (
    <div className="fx">
      <div className="sticky-top bg-white">
        <Homeheader />
      </div>
      <main>
        {/* Header Section */}
        <Box
          sx={{
            padding: { xs: " 16px", md: "80px 100px" },
            alignItems: "center",
            backgroundColor: "#f9f9f9",
            backgroundImage: "url(blogs-static-img.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginBottom: "40px",
          }}
        >
          <Grid
            maxWidth={"700px"}
            xs={12}
            md={6}
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: { xs: "32px", sm: "40px" },
            }}
          >
            <Box
              sx={{
                width: "fit-content",
              }}
            >
              <Typography
                variant="h5"
                fontSize={{ xs: "16px", md: "18px" }}
                mb={"20px"}
                padding={"8px 16px"}
                color="primary"
                textAlign="center"
                border={"1px solid #006CB7"}
                borderRadius={"24px"}
                width="100%"
              >
                Blog
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: { xs: "36px", md: "48px", lg: "48px" },
                textTransform: "capitalize",
                fontWeight: "600",
                lineHeight: { xs: "36px", sm: "50px" },
              }}
              gutterBottom
            >
              Jobeze Blog
            </Typography>
            <Box sx={{ mr: 2 }} />
            <Typography
              paragraph
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.5px",
                color: "#666",
              }}
            >
              Career Insights: Your Guide to Professional Growth and Success.
              Discover expert advice, job market trends, and career tips to help
              you thrive in today’s competitive world.
            </Typography>
          </Grid>
        </Box>

        <Grid
          container
          spacing={4}
          sx={{
            padding: { xs: "44px 16px 0px 16px", sm: "40px 120px" },
            gap: "64px",
            flexWrap: { xs: "wrap", sm: "nowrap" },
            width: { xs: "100%", sm: "auto" },
            marginLeft: "0px",
          }}
        >
          {/* Blog Posts Section */}

          {blogs && blogs.length > 0 ? (
            <Grid
              item
              xs={12}
              md={8}
              sx={{ padding: { xs: "0px !important" } }}
            >
              {blogs.map((blog, index) => (
                <Link
                  to={`/blogs/${blog.blog_id}`}
                  key={index}
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      mb: 2,
                      boxShadow: "none",
                      alignItems: {
                        xs: "start",
                        md: "center",
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: {
                          xs: "100%",
                          md: "200px",
                        },
                        borderRadius: { xs: "10px", sm: "22px" },
                        height: 200,
                      }}
                      image={blog.featured_image}
                      alt="Blog image"
                    />
                    <CardContent
                      sx={{
                        padding: { xs: "0px", sm: "16px 16px 16px 36px" },
                        gap: "8px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#7E7F81",
                          fontSize: "18px",
                          fontWeight: "400",
                          gap: "8px",
                          marginTop: "8px",
                        }}
                      >
                        <CalendarMonthIcon />
                        {new Date(blog.created_at).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </Typography>

                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "700", fontSize: "24px" }}
                      >
                        {blog.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{
                          color: "7E7F81",
                          fontSize: "16px",
                          fontWeight: "400",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          "-webkit-line-clamp": "2",
                          "-webkit-box-orient": "vertical",
                        }}
                      >
                        {blog.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              ))}

              {/* Pagination */}
              <Box display="flex" justifyContent="center" mt={"64px"}>
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages} // Total number of pages based on total blogs and blogs per page
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    sx={{
                      "& .MuiPaginationItem-root": {
                        background: "white",
                        color: "black",
                        borderRadius: "4px",
                        "&:hover": {
                          background: "lightblue",
                        },
                      },
                      "& .MuiPaginationItem-root.Mui-selected": {
                        background: "var(--custom-primary, #006CB7)",
                        color: "white",
                        boxShadow:
                          "0px 5px 5px 0px rgba(0, 0, 0, 0.20), 0px 9px 10px 0px rgba(0, 0, 0, 0.14), 0px 5px 14px 0px rgba(0, 0, 0, 0.12)", // Active shadow
                      },
                    }}
                  />
                </Stack>
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              md={8}
              sx={{ padding: { xs: "0px !important" } }}
            >
              <Typography
                variant="h6"
                gutterBottom
                fontWeight={600}
                fontSize={"22px"}
                textAlign={"center"}
              >
                No Blogs Posted
              </Typography>
            </Grid>
          )}

          {/* Sidebar Section */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            {/* Trending Tags */}
            <Box
              mb={4}
              sx={{
                padding: "25px 30px 30px 30px",
                boxShadow: "0px 8px 10px 0px rgba(105, 93, 93, 0.15)",
                borderRadius: "10px",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                fontWeight={600}
                fontSize={"22px"}
              >
                Trending Tags
              </Typography>
              <Divider sx={{ marginBottom: "24px", backgroundColor: "#000" }} />{" "}
              {trendingTags.map((tag, index) => (
                <Chip
                  key={index}
                  onClick={() => handleTagClick(tag.tag)}
                  label={tag?.tag}
                  variant="outlined"
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>

            {/* Trending Jobs */}
            <Box
              mb={4}
              sx={{
                padding: " 25px 30px 30px 30px",
                boxShadow: "0px 8px 10px 0px rgba(105, 93, 93, 0.15)",
                borderRadius: "10px",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                fontWeight={600}
                fontSize={"22px"}
              >
                Trending Jobs
              </Typography>
              <Divider sx={{ marginBottom: "24px", backgroundColor: "#000" }} />{" "}
              {trendingJobs?.map((job, index) => (
                <Link
                  to={`/jobDetail/${job.joborder_id}`}
                  key={index}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px 0",
                  }}
                >
                  {console.log()}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ flexGrow: 1 }}
                  >
                    {job?.title}
                  </Typography>

                  {/* Redirect icon */}
                  <ArrowForward
                    style={{
                      color: "#0077cc",
                      fontSize: "18px",
                      marginLeft: "10px",
                    }}
                  />
                </Link>
              ))}
            </Box>

            {/* Follow Us Section */}
            <Box
              mb={4}
              sx={{
                padding: "25px 30px 30px 30px",
                boxShadow: "0px 8px 10px 0px rgba(105, 93, 93, 0.15)",
                borderRadius: "10px",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                fontWeight={600}
                fontSize={"22px"}
              >
                Follow us on
              </Typography>
              <Divider sx={{ marginBottom: "24px", backgroundColor: "#000" }} />{" "}
              <Box display="flex" alignItems="center">
                <a
                  href="https://twitter.com/jobeze_jobs"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon
                    alt="Follow Jobeze on Twitter for job search tips"
                    title="Jobeze Twitter - AI-Powered Job Search Updates"
                    sx={{
                      color: "white",
                      mr: 1,
                      backgroundColor: "#0473BD",
                      borderRadius: "8px",
                      padding: "10px",
                      fontSize: "38px",
                    }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/jobeze_job/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon
                    alt="Follow Jobeze on Instagram for job openings"
                    title="Jobeze Instagram - Find Jobs Near You"
                    sx={{
                      color: "white",
                      mr: 1,
                      backgroundColor: "#0473BD",
                      borderRadius: "8px",
                      padding: "10px",
                      fontSize: "38px",
                    }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/people/Jobeze/100092156896675/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon
                    alt="Follow Jobeze on Facebook for job updates"
                    title="Jobeze Facebook - Stay Updated on Jobs"
                    sx={{
                      color: "white",
                      mr: 1,
                      backgroundColor: "#0473BD",
                      borderRadius: "8px",
                      padding: "10px",
                      fontSize: "38px",
                    }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/jobeze/?isFollowingPage=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon
                    alt="Connect with Jobeze on LinkedIn for job opportunities"
                    title="Jobeze LinkedIn - Professional Job Listings"
                    sx={{
                      color: "white",
                      mr: 1,
                      backgroundColor: "#0473BD",
                      borderRadius: "8px",
                      padding: "10px",
                      fontSize: "38px",
                    }}
                  />
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </main>
      {token ? <FooterZ /> : <FooterN />}
    </div>
  );
};

export default Blogs;
