import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Homeheader from "../components/homeheader";
import FooterN from "../components/FooterN";
import FooterZ from "../components/FooterZ";
import Descriptionshimmer from "./Descriptionshimmer";
import { Box } from "@mui/material";
import JobApply from "../components/jobApply";

function JobDetails() {
  const nav = useNavigate();
  const { id } = useParams();
  const [job, setJob] = useState({});
  const [loader, setLoader] = useState(false);
  const [verified, setVerified] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [skill, setSkill] = useState(searchParams.get("search") || "");
  const [location, setLocation] = useState(
    searchParams.get("location") || "World Wide"
  );
  const [effectCount, setEffectCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    getJobCards();
  }, []);


  async function getJobCards() {
    try {
      const token = document.cookie.split("_secure_ARJ_=")[1];
      const cardUrl = token
        ? `user/dashboard/page-no/${1}`
        : `search/page-no/${1}`;
      const response = await axios.post(cardUrl, {
        jdid: id > 0 ? id : -id,
        token: document.cookie,
      });
      setJob(response?.data?.message?.dashboard?.data?.[0]);
      setLoading(false);
      if (response?.data?.message?.dashboard?.data?.[0].length < 1) {
        nav("/page404");
      }
    } catch (error) {
      nav("/page404");
      console.error("Error fetching data:", error);
    }
  }

  const handleSearch = () => {
    const params = new URLSearchParams();
    if (skill) params.append("search", skill);
    params.append("location", location || "World Wide");
    setSearchParams(params);
    nav(`/jobsearch/allsearchdata${params ? "?" + params : ""}`);
  };

  const handleApply = () => {
    setJob((prevJob) => ({ ...prevJob, job_applied: new Date() }));
  };

  return (
    <div>
      <Homeheader
        skill={skill}
        setSkill={setSkill}
        location={location}
        setLocation={setLocation}
        handleSearch={handleSearch}
      />
      <div className="" style={{ background: "rgb(246, 246, 246)" }}>
        <Box
          display={{ xs: "block", md: "flex", lg: "flex" }}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
          py={{ xs: 0, md: 4, lg: 4 }}
          px={{ xs: 0, md: 0, lg: 9 }}
        >
          <Box sx={{ width: { xs: "100%", lg: 945 }, m: "auto" }}>
            {loading ? (
              <Descriptionshimmer />
            ) : (
              <JobApply
                data={job}
                loader={loader}
                setLoader={setLoader}
                applied={handleApply}
                verified={verified}
                setVerified={setVerified}
              />
            )}
          </Box>
        </Box>
      </div>
      {document.cookie.split("_secure_ARJ_")[1] ? <FooterZ /> : <FooterN />}
    </div>
  );
}

export default JobDetails;
