import * as React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import GenerateCLPopup from "./generateCLPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { isResumeVerified, setToken } from "../redux/modules/userSlice";
import { useAppDispatch } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ResendBackdrop from "../ResendBackdrop";
import QuestionPopup from "./questionPopup";
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function FormPopup(props) {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const [open, setOpen] = useState(true);
  const [applyLoading, setapplyLoading] = useState(false);

  const handleCloseForm = () => {
    setOpen(false);
    props.setName('');
    props.setEmail('');
    props.setErrorEmail(false);
    props.setFiles([]);
    props.setOpenForm(false);
    // props.setEmailExceeded(false);
  };

  const [openCLPopup, setOpenCLPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [HtmlContent, setHtmlContent] = useState("");
  const [additionInfoData, setadditionInfoData] = useState("");
  const [exceededLimitAdditionalInfo, setExceededLimitAdditionalInfo] = useState(false);
  const isQuestionnaire = props?.CVJobData?.questionnaire_required;
  const isCV = props?.CVJobData?.cover_letter_required;
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const handleCLPopup = (e) => {
    if (!props.name) {
      e.preventDefault();
      props.setErrorName(true);
      return;
    }
    if (!regex.test(props.email)) {
      e.preventDefault();
      props.setErrorEmail(true);
      return;
    }
    if (props.email.length > 60) {
      e.preventDefault();
      props.setEmailExceeded(true);
      return;
    }
    if (props.CVJobData.questionnaire_required === 1) {
      props.setOpenQuestionpopup(true);
    }
    else {
      setOpenCLPopup(true);
    }
    // setOpenCLPopup(true);
  };
  const handleCloseCLPopup = () => {
    setExceededLimitAdditionalInfo(false);
    props.setName("");
    props.handleClickCloseQuestionPopup();
    setOpenCLPopup(false);
    props.setFiles([]);
    props.setOpenForm(false);
    const a = props.quesAnsArray.map((question) => {
      return {
        question_types: question.question_types,
        question_id: question.question_id,
        ques_ans: question.ques_ans,
        answer_text: "",
        // answer_id:null,
        error: null,
      };
    });
    props.setQuesAnsArray(a);
  };

  const handleBackCL = () => {
    setOpenCLPopup(false);
  }
  console.log(openCLPopup, "openCLPopup");
  const handleCVHtmlChange = (value) => {
    setHtmlContent(value);
  };

  const handleAdditionInfoChange = (e) => {
    setadditionInfoData(e.target.value);
  };

  const handleSubmitCL = async () => {

    if (!props.validateQuesError()) {
      return;
    }

    const QuestArr = props.quesAnsArray.map((quesAns) => {
      delete quesAns.ques_ans
      delete quesAns.error
      delete quesAns.question_types
      return quesAns;
    })

    let formData = new FormData();
    formData.append("resume", props.file[0]);
    formData.append("email", props.email.toLowerCase());
    formData.append("name", props.name);
    formData.append("jobOrder_id", props.CVJobData.joborder_id);
    formData.append(
      "isExternalString",
      props.CVJobData.redirect_link ? true : false
    );
    formData.append("cover_letter", HtmlContent ? (HtmlContent === "<p><br></p>") ? null : HtmlContent : null);
    formData.append("additional_info", additionInfoData ? additionInfoData : "");

    formData.append("quesAnsArray", isQuestionnaire === 1 ? JSON.stringify(QuestArr) : JSON.stringify([]));
    formData.append("original_date_modified", props.CVJobData.date_modified);

    // setCVLoader(true);
    // setOpenCLPopup(false);
    // props.setOpenForm(false);
    props.setOpenQuestionpopup(false);
    setapplyLoading(true);



    try {
      const { data } = await axios.post(`applyjoborder`, formData, {
        withCredentials: true,
      });

      if (data.message.alert === "New Candidate") {
        localStorage.setItem("id", data.message.id);
        dispatch(setToken(data.message.token));
        dispatch(isResumeVerified(1));
        props.setVerified(true);
        const d = new Date();
        d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
        // d.setTime(d.getTime() + 1 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie =
          "_secure_ARJ_" + "=" + data.message.token + ";" + expires + ";path=/";
        setTimeout(() => {
          setapplyLoading(false);
        }, 1000);
        if (props?.CVJobData?.redirect_link) {
          window.open(props?.CVJobData?.redirect_link, "_blank");
          // window.location.href = `/jobsearch/allsearchdata?${props?.searchParams}`;
          nav("/dashboard");
          toast.success("Login Successfully");
          return;
        } else {
          nav("/dashboard");
          props.applied(props?.CVJobData?.joborder_id);
          toast.success("Applied Successfully");
        }

        // set
      } else if (data.message.alert === "old candidate") {
        if (data.message.verify === true) {
          props.setFiles([]);
          toast.error("User Already Exist!", {
            hideProgressBar: true,
            closeOnClick: true,
          });
          nav("/login");
        }
        if (data.message.verify === false) {
          props.setFiles([]);
          toast.error("Please Verify Your Account!", {
            hideProgressBar: true,
            closeOnClick: true,
          });
          props.setVerified(false);
          nav("/login");
        }
        console.log(data, "23300000000")
      }
      else if (data?.status === 400) {
        toast.error("Internal Server Error", {
          hideProgressBar: true,
          closeOnClick: true,
        });
        setOpen(false);
        setapplyLoading(false);

      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Internal Server Error", {
        hideProgressBar: true,
        closeOnClick: true,
      });
      setOpen(false);
      setapplyLoading(false);
    }
  };
  const tokenExists = useSelector((state) => state.user.token);

  const handleNext2 = () => {
    if (!props.validateQuesError()) {
      return
    }
    setOpenCLPopup(true);
  }
  return (
    <div>
      {applyLoading ? (
        <ResendBackdrop />
      ) : (
        <>
          <BootstrapDialog
            // onClose={handleCloseForm}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
            maxWidth={"sm"}
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "8px",
              },
            }}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleCloseForm}
              className="fw-bold text-truncate"
              style={{ width: "90%" }}
            >
              Apply to {props.CVJobData.title}
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <form onSubmit={props.handleSubmit}>
                <div className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    value={props.name}
                    onChange={props.handleName}
                    fullWidth
                  />
                  {props.errorName && (
                    <span className="text-danger fx">Enter Name</span>
                  )}
                </div>
                <div className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    onChange={props.handleEmail}
                    onFocus={() => props.setErrorEmail(false)}
                    fullWidth
                  />
                  {props.errorEmail && (
                    <span
                      className="text-danger fx"
                      style={{ fontSize: "14px" }}
                    >
                      Enter valid email{" "}
                    </span>
                  )}
                  <br />
                  {props.emailexceed && (
                    <span
                      className="text-danger fx"
                      style={{ fontSize: "14px" }}
                    >
                      Email should be less than 60 characters
                    </span>
                  )}
                </div>
                <div
                  className="border-primary rounded-3 p-3"
                  style={{ borderStyle: "dashed" }}
                >
                  {props.file[0] ? (
                    <div className="d-flex justify-content-between align-items-center ">
                      <div className="fw-bold w-100 text-truncate ">
                        {props.file[0].path}
                      </div>
                      <IconButton
                        aria-label="delete"
                        onClick={() => props.setFiles([])}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </div>
                  ) : (
                    <div
                      className="d-flex justify-content-between align-items-center cursor-pointer"
                      onClick={() => {
                        const uploadButton =
                          document.getElementById("upload-button");
                        if (uploadButton) {
                          uploadButton.click();
                        }
                      }}
                    >
                      <div>
                        <div
                          className="text-primary fw-bold"
                          style={{ fontSize: "16px" }}
                        >
                          {" "}
                          Upload resume
                        </div>
                        <div
                          className="text-muted fw-bold"
                          style={{ fontSize: "14px" }}
                        >
                          (Max file size : 6 MB)
                        </div>
                      </div>
                      <Button
                        component="label"
                        variant="text"
                        className="text-end rounded d-none"

                      >
                        <label htmlFor="upload-button">
                          <FileUploadIcon sx={{ cursor: "pointer" }} />
                        </label>
                        <input
                          type="file"
                          name="resume"
                          id="upload-button"
                          accept=".pdf, .docx, .txt"
                          // style={{ display: "none" }}
                          {...props.getInputProps()}
                        />
                      </Button>
                      <Button variant="text" className="text-end rounded">
                        <FileUploadIcon sx={{ cursor: "pointer" }} />
                      </Button>
                    </div>
                  )}
                </div>
                {/* {props.file[0] ? (
                <div style={{ textAlignLast: "center" }}>
                  {props.file[0].path}
                </div>
              ) : (
                ""
              )}
              {props.errorFile && (
                <span className="text-danger">Please Upload Resume</span>
              )} */}
                <div className="fw-bold fx pt-3">
                  <p style={{ fontSize: "15px" }}>
                    Please upload resume in Pdf, Txt or Docx format only
                  </p>
                  <span>
                    I accept Jobeze's{" "}
                    <a className="text-decoration-none" href="/terms-condition">
                      T&Cs
                    </a>
                    ,{" "}
                    <a className="text-decoration-none" href="/privacy-policy">
                      Privacy Policy
                    </a>
                    , and communication settings.
                  </span>
                </div>

                <DialogActions className="pt-4">
                  {props.CVJobData.cover_letter_required === 1 || props.CVJobData.questionnaire_required === 1 ? (
                    <div className="">
                      <Button
                        variant="contained"
                        sx={{ height: "40px" }}
                        className="text-capitalize fw-bold px-4"
                        disabled={
                          props.name === "" ||
                            props.email === "" ||
                            props.file[0]?.name === undefined
                            ? true
                            : false
                        }
                        // value="submit"
                        // type="submit"
                        onClick={handleCLPopup}
                      >
                        Next
                      </Button>
                    </div>
                  ) : (
                    <div className="">
                      <Button
                        variant="contained"
                        sx={{ height: "40px" }}
                        className="text-capitalize fw-bold px-4"
                        disabled={
                          props.name === "" ||
                          props.email === "" || props.applyLoadingJoborder ||
                            props.file[0]?.name === undefined
                            ? true
                            : false
                        }
                        value="submit"
                        type="submit"
                      >
                        Apply for Job
                      </Button>
                    </div>
                  )}
                </DialogActions>


                <QuestionPopup
                  jobDetails={props.jobDetails}
                  openQuestionpopup={props.openQuestionpopup}
                  setOpenQuestionpopup={props.setOpenQuestionpopup}
                  handleClickOpenQuestionPopup={props.handleClickOpenQuestionPopup}
                  handleClickCloseQuestionPopup={props.handleClickCloseQuestionPopup}
                  question={props.question}
                  setQuestion={props.setQuestion}
                  textFields={props.textFields}
                  setTextFields={props.setTextFields}
                  textFieldError={props.textFieldError}
                  setTextFieldError={props.setTextFieldError}
                  radioValues={props.radioValues}
                  setRadioValues={props.setRadioValues}
                  radioErrors={props.radioErrors}
                  setRadioErrors={props.setRadioErrors}
                  handleRadioChange={props.handleRadioChange}
                  handleTextFieldChange={props.handleTextFieldChange}
                  openCV={openCLPopup}
                  setOpenCV={setOpenCLPopup}
                  handleCVPopupOpen={setOpenCLPopup}
                  handleCVPopupClose={handleCloseCLPopup}
                  handleNext={handleNext2}
                  handlePopUpBack={props.handlePopUpBack}
                  setQuesAnsArray={props.setQuesAnsArray}
                  quesAnsArray={props.quesAnsArray}
                  handleSubmit2={handleSubmitCL}
                  setQuesErrorHandler={props.setQuesErrorHandler}


                />

                <GenerateCLPopup
                  handleSubmit2={handleSubmitCL}
                  openCLPopup={openCLPopup}
                  data={props.CVJobData}
                  handleCloseCLPopup={handleCloseCLPopup}
                  additionInfoData={additionInfoData}
                  setHtmlContent={setHtmlContent}
                  handleCVHtmlChange={handleCVHtmlChange}
                  setadditionInfoData={setadditionInfoData}
                  handleAdditionInfoChange={handleAdditionInfoChange}
                  exceededLimitAdditionalInfo={exceededLimitAdditionalInfo}
                  setExceededLimitAdditionalInfo={setExceededLimitAdditionalInfo}
                  loading={loading}
                  setLoading={setLoading}
                  HtmlContent={HtmlContent}
                  setOpenCLPopup={setOpenCLPopup}
                  handleBackCL={handleBackCL}
                />
              </form>
            </DialogContent>

          </BootstrapDialog>
        </>
      )}
    </div>
  );
}
