import { Checkbox, Chip, IconButton, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { toast } from "react-toastify";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.grey,
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 15,
    fontWeight: 500,
  },
}));

const ShortListedStatus = (props) => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");

  // console.log(props.data,"data");

  const beforeRecommendDate = new Date(`${props.data.date_created}`);
  const Remmonedtoday = new Date();
  const RecommendedDiffTime = Math.abs(Remmonedtoday - beforeRecommendDate);
  const RecommendedDiffDays = Math.ceil(
    RecommendedDiffTime / (1000 * 60 * 60 * 24)
  );

  // var postedMessages = "";
  // if (RecommendedDiffDays === 1) {
  //     postedMessages = "Posted 1 day ago";
  // }
  // else if (RecommendedDiffDays > 1 && RecommendedDiffDays <= 30) {
  //     postedMessages = "Posted " + RecommendedDiffDays + " days ago";
  // }
  // else if (RecommendedDiffDays > 30) {
  //     postedMessages = "Re-posted " + (RecommendedDiffDays % 30) + (RecommendedDiffDays % 30 === 1 ? " day ago" : " days ago");
  // }
  // else {
  //     postedMessages = "Posted " + RecommendedDiffDays + " days ago";
  // }

  var postedMessages = "";
  if (RecommendedDiffDays === 1) {
    postedMessages = "Posted 1 day ago";
  } else if (RecommendedDiffDays === 0) {
    postedMessages = "Posted today";
  } else if (RecommendedDiffDays > 1 && RecommendedDiffDays <= 30) {
    postedMessages = "Posted " + RecommendedDiffDays + " days ago";
  } else if (RecommendedDiffDays > 30) {
    if (RecommendedDiffDays % 30 === 0) {
      postedMessages = "Posted today";
    } else {
      postedMessages =
        "Re-posted " +
        (RecommendedDiffDays % 30) +
        (RecommendedDiffDays % 30 === 1 ? " day ago" : " days ago");
    }
  } else {
    postedMessages = "Posted " + RecommendedDiffDays + " days ago";
  }

  const [check, setCheck] = useState(false);

  const handleDirect = () => {
    if (props.data.status === "Closed" || props.data.is_deleted === 1) {
      return;
    } else {
      const url = `/jobDetail/${props.data.joborder_id}`;
      window.open(url, "_blank");
    }
  };
  useEffect(() => {
    const isSelected = props.checkjobid.includes(props.data.joborder_id);
    setCheck(isSelected);
  }, [props.data.joborder_id, props.checkjobid]);

  const handleCheck = () => {
    // console.log(props.checkjobid, "checkjobid");
    const checkedCheckboxes = props.checkjobid.length;
    if (!check) {
      if (checkedCheckboxes >= 20) {
        toast.error("You can select up to 20 jobs to apply", {
          hideProgressBar: true,
          closeOnClick: true,
        });
        return;
      }

      props.setcountjobs(props.countjobs + 1);
    } else {
      props.setcountjobs(props.countjobs - 1);
    }

    setCheck(!check);

    let jobOrderDest = [...props.checkjobid];
    if (check) {
      const joborderIndex = jobOrderDest.findIndex(
        (e) => e === props.data.joborder_id
      );
      if (joborderIndex > -1) {
        jobOrderDest.splice(joborderIndex, 1);
        props.setcheckjobid(jobOrderDest);
      }
    } else {
      jobOrderDest.push(props.data.joborder_id);
      props.setcheckjobid(jobOrderDest);
    }
  };

  const getJobStatusProps = (data) => {
    if (data.is_deleted == 1) {
      return {
        tooltipClasses: "",
        chipLabel: "This Job is no longer available",
        chipColor: "error",
        border: "4px",
        variant: "filled",
        marginfordelete: "20px",
      };
    }

    if (data.status === "Closed") {
      return {
        tooltipTitle: "This job is no longer accepting applications",
        chipLabel: "Closed",
        chipColor: "error",
        marginfordelete: "20px",
        variant: "outlined",
      };
    } else if (data.job_applied) {
      return {
        tooltipTitle: "Job Applied",
        chipLabel: "Applied",
        chipColor: "primary",
        variant: "outlined",
        marginfordelete: "20px",
      };
    }

    return null;
  };

  const jobStatusProps = getJobStatusProps(props.data);

  return (
    <div className="shadow bg-white rounded-3 p-lg-3 p-md-3 p-2 my-lg-2 my-md-2 mb-2">
      <div className="row justify-content-lg-between align-items-lg-start">
        <div className={` col-lg-1 ${isSmallScreen ? "col-1 p-0" : ""} `}>
          <Tooltip
            title={
              props.data.cover_letter_required === 1 ||
              props?.data.questionnaire_required === 1 ||
              props?.is_scraped === 1
                ? "Apply for the job from the job detail page"
                : props.data.is_deleted === 1
                ? "This job has been deleted"
                : ""
            }
            placement="top"
            arrow
          >
            <IconButton>
              <Checkbox
                checked={check}
                disabled={
                  props.data.cover_letter_required === 1 ||
                  props.data.questionnaire_required === 1 ||
                  props.data.is_deleted === 1 ||
                  props?.is_scraped === 1 ||
                  props.data.status === "Closed"
                    ? true
                    : false
                }
                onClick={handleCheck}
              />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={`pt-2 ${isSmallScreen ? "col-9 ps-3 p-0" : "col-lg-8"} `}
          style={{ opacity: props.data.is_deleted === 1 ? 0.6 : 1 }}
        >
          <div
            className={props.isShortlisted ? "staticSavedJobs" : ""}
            style={{ fontSize: isSmallScreen ? "13px" : "unset" }}
          >
            <div className="d-lg-flex">
              <div
                className={`fw-bold saved-title ${
                  props.data.status === "Closed" ? "disabled" : ""
                }`}
                onClick={handleDirect}
                style={{
                  cursor:
                    props.data.status === "Closed" ? "default" : "pointer",
                }}
              >
                {props.data.title}
              </div>
              <div className="mx-2 d-none d-lg-block ">
                {jobStatusProps && (
                  <LightTooltip
                    title={jobStatusProps.tooltipTitle}
                    placement="top"
                  >
                    <Chip
                      variant={jobStatusProps.variant}
                      color={jobStatusProps.chipColor}
                      className="fw-bold fs-6"
                      label={jobStatusProps.chipLabel}
                      style={{
                        borderRadius: jobStatusProps.border,
                        marginLeft: jobStatusProps.marginfordelete,
                      }}
                    />
                  </LightTooltip>
                )}
              </div>
            </div>
            <div className="fw-bold">{props.data.name}</div>
            <div className="text-muted fw-bold pt-1">
              {" "}
              {props.data.city}, {props.data.state}
            </div>
            <div className="d-flex align-items-center pt-2">
              <AccountCircleIcon fontSize="small" />
              <span className="text-muted fw-bold px-1">
                {props.data.total_recruited} Candidates applied recently
              </span>
            </div>
            <div className="text-muted fw-bold py-2">{postedMessages}</div>
          </div>
        </div>
        <div
          className={` col-lg-3 text-end ${isSmallScreen ? "col-1 p-0" : ""}`}
        >
          <Checkbox
            // {...Label}
            checked={props.save}
            icon={<BookmarkBorderIcon />}
            checkedIcon={<BookmarkIcon />}
            onClick={() => props.handleSave(props.data.joborder_id)}
          />
        </div>
        <div
          className="col-sm ms-auto d-lg-none d-md-none d-sm-none d-block "
          style={{ marginBottom: "20px" }}
        >
          {jobStatusProps && (
            <LightTooltip title={jobStatusProps.tooltipTitle} placement="top">
              <Chip
                variant={"outlined"}
                color={jobStatusProps.chipColor}
                className="fw-bold text-end fs-6"
                label={jobStatusProps.chipLabel}
                sx={{
                  marginLeft: "30px",
                }}
              />
            </LightTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShortListedStatus;
